import ui from 'lib/popup/components/ui';
import { useControllerState } from 'lib/BaseController';
import { Button } from 'react-aria-components';
import { CaretUpIcon, CaretDownIcon, TrashIcon } from '@radix-ui/react-icons';


export default function Popup({ popup }) {
  const
    state = useControllerState(popup),
    errored = state.get('errored'),
    root = state.get('root');

  return (
    <ui.Window popup={popup} title="Remote Files">
      { errored && (
        <div>
          Load Error
        </div>
      ) }
      <div className="text-sm flex flex-col gap-4">
        <div>
          <Title>Host</Title>
          <div className="font-mono">{ popup.args.machine.state.get('machineInfo').hostname }</div>
        </div>

        <div>
          <Title>Folder</Title>
          <div className="font-mono">
          { popup.args.machine.state.get('machineInfo').cwd }/{ popup.args.docPath }</div>
        </div>

        <div>
          <Title>Contents</Title>
          { !errored && root && (
            <div className="h-[14rem] overflow-auto border border-rim p-2 font-mono mb-2">
              <Items items={root.get('children')} popup={popup} path={[]} />
            </div>
          ) }
        </div>
      </div>        
    </ui.Window>
  )
}

function Items({ items, popup, path=[] }) {
  if (!items)
    return '';

  const list = items.valueSeq();
  if (list.size === 0)
    return (<div className="text-rim2">Empty</div>)

  return list.map((item, i) => {
    let props = { item, popup, path: [...path, item.get('name')] };
    return item.get('isDirectory') ? <Directory key={i} {...props} /> : <File key={i} {...props} />;
  });
}


function Directory({ item, popup, path }) {
  return (
    <div className="">
      <Button
        className="text-marker font-medium outline-none"
        onPress={() => {
          if (!item.get('expanded'))
            popup.expandFolder(path);
          else
            popup.collapseFolder(path);
        }}>
        {item.get('name')}

        { item.get('expanded') ? <CaretUpIcon className="inline" /> : <CaretDownIcon className="inline" />}
      </Button>

      { item.get('loading') && (<div className="text-rim2">...</div>) }

      { item.get('children') && item.get('expanded') && (
        <div className="pl-2">
          <Items items={item.get('children')} popup={popup} path={path} />
        </div>
      ) }
    </div>
  );
}

function File({ item, popup, path }) {
  return (
    <div className="items-center flex gap-2 group/machine-file">
      <div>{item.get('name')}</div>
      <Button
        onPress={() => popup.promptDeleteRemoteFile(path)}
        className="hidden group-hover/machine-file:block">
        <TrashIcon />
      </Button>
    </div>
  );
}

function Title(props) {
  return (<div className="font-bold mb-1" {...props} />);
}