import BaseController from '../BaseController';
import { Map, List } from 'immutable';
import { getKeyDispatcher } from 'lib/KeyDispatcher';
import Prompt from './popups/Prompt/Controller';


class PopupManager extends BaseController {
  constructor() {
    super();
    this.state = new Map({ popups: new List([]) });
  }

  get popups() { return this.state.get('popups'); }

  findType = type =>  this.popups.find(p => p.type === type);
  toggle = (type) => (this.findType(type) ? this.closeByType(type) : this.open(type));
  open = (type, args, sessionId) => this.openNew(type, args, sessionId);
  closeByType = (type) => this.close(this.findType(type));
  closeById = (id) => this.close(this.popups.find(p => p.id === id));

  openNew = (PopupController, args, sessionId) => {
    const popup = new PopupController(args, this);
    popup.initializePosition();
    if (sessionId)
      popup.sessionId = sessionId;
    this.setState({ popups: this.popups.push(popup) });
    return popup;
  }
  
  close = (popup) => {
    popup.unload();
    this.setState({
      popups: this.popups.filter(p => p.id !== popup.id)
    });
  }

  closeSession = (sessionId) => {
    this.state.get('popups').forEach(popup => {
      if ((!sessionId && !popup.sessionId) || popup.sessionId === sessionId)
        this.close(popup);
    });
  }

  bringToFront = (popup) => {
    if (this.popups.last() === popup)
      return;
    
    console.log('bring to front');
    this.setState({ popups: this.popups.sortBy(p => p.id === popup.id) });
    getKeyDispatcher().bringToFront(popup.id);
  }

  subscribeKey(id, callback) {
    return getKeyDispatcher().subscribe(id, callback);
  }

  prompt = (opts) => {
    this.open(Prompt, opts);
  }
}

export default PopupManager;