import p from 'lib/popup/components/ui';
import { Group } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';
import { useControllerState } from 'lib/BaseController';
import PathSelector from 'lib/inputs/PathSelector';

import { slugify } from 'lib/slugify';
import SlugCheckInput from 'lib/inputs/SlugCheckInput';
import { TextInput } from 'lib/inputs/basic';

import './style.css';


function Popup({ popup }) {
  let state = useControllerState(popup);

  return (
    <p.Popup popup={popup} className="clone-article-popup">
      <p.Dialog>
        <p.TitleBar>
          <p.Title>
            Clone Article
          </p.Title>
          <p.CloseButton />
        </p.TitleBar>
        <p.Body>
          {state.get('done') && (<>
            <div className="">
              <div className="font-medium m-4">The article</div>

              <p.Item
                itemType="Article"
                title={state.get('title')}
                slug={state.get('slug')} />

              <div className="font-medium m-4">Has been cloned to</div>

              <p.Item
                itemType="Article"
                title={popup.state.get('title')}
                slug={[...state.get('folderPath'), state.get('slug')].join('/')} />

              <Buttons className="text-right">
                <Button onPress={popup.close}>
                  Ok
                </Button>
              </Buttons>
            </div>
          </>)}
          {!state.get('done') && (
            <Form popup={popup} />
          )}
        </p.Body>
      </p.Dialog>
    </p.Popup>
  )
}

function Form({ popup }) {
  const
    { state, args } = popup,
    loading = state.get('loading'),
    domains = state.get('domains');

  return (
    <form onSubmit={e => { e.preventDefault(); popup.clone(); }}>
      <Group>
        {!args.hideInfo && (
          <p.Info>
            <strong>Note: </strong> Cloning will <strong>NOT</strong> copy over the
            article's <strong>uploads</strong> or its <strong>versions</strong>.
          </p.Info>
        )}

        <div className="font-medium text-sm mb-2">Article</div>
        <p.Item
          itemType="Article"
          title={state.get('title')}
          slug={state.get('slug')} />

        <div className="flex gap-4 items-start">
          <div className="my-3 grow basis-0">
            <div className="font-medium text-sm">Clone To</div>
            <select
              className="border border-rim2 px-2 p-1 w-full"
              value={state.get('selectedDomain')}
              onChange={e => popup.setState({ folderPath: [e.currentTarget.value] })}>
              <option value="">Select Workspace</option>
              <option disabled={true} value="">---</option>
              <option value="local">Offline Workspace</option>
              <option disabled={true} value="">---</option>
              { !domains && (
                <option disabled={true} value="">loading ...</option>
              ) }
              { domains && domains.map((domain, i) => (
                <option key={i} value={domain.slug}>{domain.title}</option>
              )) }
            </select>
          </div>

          <div className="grow basis-0">
            { state.get('folderPath')[0] && (
                <PathSelector
                  label="Folder"
                  value={state.get('folderPath')}
                  omitIds={[]}
                  isDisabled={loading}
                  fetchPath={popup.currentProvider.fetchPath}
                  fetchChildrenFolders={popup.currentProvider.fetchChildrenFolders}
                  onChange={v => popup.setState({ folderPath: v })} />
            )}
          </div>
        </div>

      { state.get('folderPath')[0] && (
        <div className="flex gap-4">
          <div className="grow basis-0">
            <TextInput
              label="Article Title"
              value={state.get('title')}
              autoFocus={true}
              isDisabled={loading}
              onChange={v => {
                popup.setState({ title: v });
                if (state.get('autoSlug')) {
                  popup.setState({ slug: slugify(v) });
                }
              }} />
          </div>
          <div className="grow basis-0">
            <SlugCheckInput
              label="Slug"
              value={state.get('slug')}
              onChange={v => popup.setState({ slug: v })}
              onValidChange={e => popup.setState({ slugValid: e })}
              checkFn={(val) => popup.checkAvailable([...state.get('folderPath'), val].join('\\'))} />
          </div>
        </div>
      ) }
      </Group>

      <p.Alert />

      <Buttons className="text-right mt-2">
        <Button type="submit" isDisabled={loading || !(state.get('folderPath')[0])}>
          Clone
        </Button>
        <Button isDisabled={loading} onClick={_ => popup.close()}>
          Cancel
        </Button>
      </Buttons>
    </form>
  )
}

export default Popup;