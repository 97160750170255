import { useEffect } from 'react';
import { useControllerState } from 'lib/BaseController';

import Editors from './Editors';
import Viewers from './Viewers';


export default function Users({ controller }) {
  const { access } = controller;
  useEffect(() => { access.fetchList() }, [access]);
  useControllerState(access);

  return (
    <div className="flex flex-col">
      <Editors access={access} />
      <hr />
      <Viewers controller={controller} />
    </div>
  )
}