import { Link } from 'react-router-dom';
import Featured from './Featured';
import Icon from 'lib/Icon';
import { Frame } from '../misc/components';
import { faServer, faTowerBroadcast } from '@fortawesome/free-solid-svg-icons';

import './style.css';


export default function Explore() {
  return (
    <Frame className="max-w-[60rem] p-8 flex flex-col">
      <h2 className="text-2xl font-medium text-pencil">Explore</h2>
      <Search />
      <div className="mt-6 flex gap-10 flex-grow h-max overflow-auto max-md:flex-col">
        <Featured />
        <Tools />
      </div>
    </Frame>
  )
}

function Search() {
  return (
    <input
      className="p-2.5 w-full bg-wax border border-rim3 outline-none opacity-60"
      placeholder="Search the public domain (currently unavailable)" disabled />
  )
}


function Tools() {
  return (
    <section className="quick-links flex-1">
      <h4 className="text-2xl font-medium text-pencil">
        Core Tools
      </h4>
      
      <div className="links">
        <QuickLink
          to="/references/intro-to-baklava"
          title="Balkava"
          faIcon={faServer}>
          Baklava is an app that runs on any OS. It lets you securely connect 
          your notebook to a remote OS over the internet,
          sync your files, and execute your files on the machine.
          Follow the guide to be up and running in 5 minutes.
        </QuickLink>

        <QuickLink
          to="/references/intro-to-vani"
          title="Vani"
          faIcon={faTowerBroadcast}>
          Learn how to use Vani, the Notebook X's communication channel that enables cross-language,
          cross-process communication between your programs. Explore the amazing potential use cases.
        </QuickLink>
      </div>
    </section>
  )
}

function QuickLink({ to, title, children, faIcon }) {
  return (
    <div className="my-4">
      <Link to={to}  className="text-lg font-medium">
        <Icon faIcon={faIcon} />&nbsp;&nbsp;&nbsp;{title}
      </Link>
      <div className="text-sm text-pencil2">
        {children}
      </div>
    </div>
  )
}