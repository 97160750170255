import ElementNew from '../popups/ElementNew/Controller';
import UploadNew from '../popups/UploadNew/Controller';
import UploadDelete from '../popups/UploadDelete/Controller';
import UploadFiles from '../popups/UploadFiles/Controller';
import ManageVersions from '../popups/ManageVersions/Controller';
import VersionCreate from '../popups/VersionCreate/Controller';
import VersionExpress from '../popups/VersionExpress/Controller';
import ArticleAccess from '../popups/ArticleAccess/Controller';
import RichText from 'lib/popup/popups/RichText/Controller';
import Katex from 'lib/popup/popups/Katex/Controller';
import Prompt from 'lib/popup/popups/Prompt/Controller';
import Code from 'lib/popup/popups/Code/Controller';
import LinkSelect from 'lib/popup/popups/LinkSelect/Controller';
import ImageSelect from 'lib/popup/popups/ImageSelect/Controller';
import CacheManager from 'modules/app/popups/CacheManager/Controller';

import { validateFilename } from 'lib/tools.ts';


export default class Popups {
  constructor(article) {
    this.article = article;
    this.popupManager = this.article.app.popupManager;
  }

  get sessionId() { return `article-${this.article.id}` }

  closeSession() {
    console.log('closing', this.sessionId)
    this.popupManager.closeSession(this.sessionId);
  }

  newElementPopup = (successCallback, closeCallback, insertAt) => {
    if (typeof insertAt !== 'number') {
      const { selectedElement } = this.article.selection;
      if (selectedElement)
        insertAt = this.article.layout.getElementIndex(selectedElement.id) + 1;
      else
        insertAt = this.article.layout.length;
    }

    var createdElementId;

    return this.popupManager.open(ElementNew, {
      createNewElement: async ({ type }) => {
        createdElementId = await this.article.createElement({ type, insertAt });
        if (successCallback) successCallback(createdElementId);
      },

      onClose: () => {
        if (createdElementId) {
          setTimeout(_ => {
            const el = this.article.elements[createdElementId];
            if (el && el.focus) {
              el.focus();
              el.scrollIntoView();
            }
          }, 100);
        }
        if (closeCallback)
          closeCallback(createdElementId);
      }
    }, this.sessionId);
  }

  deleteElementPopup = (elementId) => {
    const nextIndex = this.article.layout.getElementIndex(elementId) + 1;
    if (elementId === 'titlebar')
      return;

    this.openPopup('prompt', {
      title: 'Delete Element',
      question: 'Are you sure you want to delete this element?',
      inputs: [],
      submitLabel: 'Delete',
      onSubmit: () => {
        this.article.deleteElement(elementId)
          .then(() => this.article.layout.deleteElementId(elementId))
          .then(() => {
            const nextId = this.article.layout.getElementIdAt(
              Math.min(nextIndex, this.article.layout.order.size - 1)
            );
            if (nextId) {
              this.article.selection.setSelectedElementId(nextId)
              this.article.selection.focusPage();
            }
          })
      }
    });
  };
  
  uploadFilePopup = ({ accept, title, onDone }) => {
    const
      articleId = this.article.id,
      domainId = this.article.state.getIn(['article', 'domainId']),
      user = this.article.app.auth.user,
      userId = user && user.uid;

    const uploadArticleFile = ({ filename, file }, progress, error, done) => {
      const exists = (
        this.article.state.get('uploads')
          .find(file => file.get('filename') === filename)
      );

      if (exists) {
        return error(new Error('A file with that filename already exists.'));
      }
      
      return (
        this.article.provider.uploadArticleFile(
          { domainId, articleId, userId, filename, file},
          progress,
          error,
          () => {
            done();
            if (onDone) { onDone(filename) };
          }
        )
      );
    }

    return this.popupManager.open(
      UploadNew,
      {
        uploadArticleFile,
        accept,
        title,
        fetchDomainPlans: () =>  this.article.provider.fetchDomainPlans(),
        fetchTally: () => this.article.provider.fetchTally({ domainId })
      },
      this.sessionId
    );
  }

  uploadFilesPopup = (args) => {
    return this.popupManager.open(UploadFiles, {
      articleController: this.article, ...args
    }, this.sessionId);
  }

  deleteUploadPopup = ({ upload, hardDelete }) => {
    const
      { provider } = this.article,
      articleId = this.article.id,
      domainId = this.article.domainId,
      uploadId = upload.get('id');

    return this.popupManager.open(UploadDelete, {
      upload,
      hardDelete,
      getUploadUsedBy: _ => provider.getUploadUsedBy({ domainId, articleId, uploadId }),
      deleteUpload: hardDelete => provider.deleteUpload({ domainId, articleId, uploadId, hardDelete })
    }, this.sessionId);
  }

  publishVersionPopup = () => this.manageVersionsPopup('Publish a Version')

  manageVersionsPopup = (title) => this.popupManager.open(
    ManageVersions,
    { articleController: this.article, title },
    this.sessionId
  );

  createVersionPopup = () => this.popupManager.open(
    VersionCreate,
    { articleController: this.article },
    this.sessionId
  );

  expressVersionPopup = () => this.popupManager.open(
    VersionExpress,
    { articleController: this.article },
    this.sessionId
  );

  openUserAccessPopup = () => this.popupManager.open(
    ArticleAccess,
    { articleController: this.article },
    this.sessionId
  );

  openPopup = (type, args) => {
    var popup;

    if (type === 'upload-file') {
      popup = this.uploadFilePopup(args);
    }
    else if (type === 'upload-files') {
      const { title, onSelect, onUnload, accept, allowPreview } = args;
      popup = this.uploadFilesPopup({ title, onSelect, onUnload, allowPreview, accept })
    }
    else if (type === 'rich-text-editor') {
      const { title, text, onSave, format } = args;
      popup = this.popupManager.open(RichText, { title, text, onSave, format }, this.sessionId);
    }
    else if (type === 'code-editor') {
      const
        { title, value, onSave, language } = args,
        colorScheme = this.article.app.colorScheme.getSchemeValue();

      popup = this.popupManager.open(Code, { title, value, onSave, language, colorScheme }, this.sessionId);
    }
    else if (type === 'katex-editor') {
      const { value, onSave, onClose } = args;
      popup = this.popupManager.open(Katex, { value, onSave, onClose }, this.sessionId);
    }
    else if (type === 'link-select') {
      const { value, onSave, onClose } = args;
      popup = this.popupManager.open(LinkSelect, {
        openPopup: this.openPopup,
        allowUploads: true,
        value, onSave, onClose
      }, this.sessionId);
    }
    else if (type === 'image-select') {
      const
        { location, getURL, onSave, onClose } = args,
        localFilenames = (
          Object.values(this.article.localFiles.getAllFiles())
            .filter(file => file.contentType.startsWith('image'))
            .map(file => file.filename)
        );
      console.log(localFilenames);

      popup = this.popupManager.open(ImageSelect, {
        location, getURL, onSave, onClose, localFilenames
      }, this.sessionId);
    }    
    else if (type === 'prompt') {
      const {
        onSubmit, onUnload, question, content, height, width,
        title, inputs, submitLabel, cancelLabel
      } = args;
      popup = this.popupManager.open(Prompt, {
        onSubmit, onUnload, question, content,
        height, width, title, inputs, submitLabel, cancelLabel
      }, this.sessionId);
    } else if (type === 'cache-manager') {
      popup = this.popupManager.open(CacheManager, {
        caching: this.article.app.caching
      }, this.sessionId);
    }
    else if (type === 'my key manager') {
      popup = this.myKeyManagerPopup()
    }

    return { close: popup.close };
  }

  addChildFile = ({ parentId, onCreate, isPulled, extension }) => new Promise((res, rej) => {
    this.openPopup('prompt', {
      title: 'Add File',
      inputs: [{
        label: 'Filename',
        type: 'text',
        defaultValue: '',
        autoFocus: true,
        unit: extension ? `.${extension}` : ''
      }],
      submitLabel: 'Save',
      onSubmit: async ([filename]) => {
        if (extension)
          filename = `${filename}.${extension}`;
        
        const invalid = validateFilename(filename);
        if (invalid)
          throw new Error(invalid);

        if (this.article.localFiles.getFilenames().has(filename))
          throw new Error('A file with the name already exists.');

        const fileData = await this.article.createChildFile({ parentId, filename, isPulled });
        res(fileData);
        if (onCreate)
          return onCreate(fileData);
      },
      onUnload: () => res()
    });
  });

  myKeyManagerPopup = () => {
    let myKeys;
    try {
      myKeys = JSON.parse(localStorage.getItem('myKeys') || '{}')
    } catch (e) {
      myKeys = {};
    }

    return this.popupManager.open(Prompt, {
      title: 'My Key Manager',
      inputs: [{
        label: 'Keys stored in this browser:',
        type: 'key-value',
        defaultValue: myKeys,
      }],

      submitLabel: 'Save',

      onSubmit: ([myKeys]) => {
        localStorage.setItem('myKeys', JSON.stringify(myKeys));
        const event = new CustomEvent('my keys update', { detail: myKeys });
        window.dispatchEvent(event);
      }
    });
  }
}