import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import Prompt from 'lib/popup/popups/Prompt/Controller';
import View from './View';


class Controller extends BasePopupController {
  static View = View;
  position = new Map({ width: 480, height: 480 });

  constructor(args, manager) {
    super(args, manager);

    this.state = this.state.merge({
      list: null,
      sortBy: 'createdAt',
      countDiff: 0,
      isAdmin: null,
      commentLikes: [],
    });
    this.fetch();
  }

  fetch = () => {
    this.fetchComments();
    this.fetchCommentLikes();    
  }

  fetchComments = (startCursor) => {
    const { provider, articleId, domainId } = this.args;
    this.setState(startCursor ?  { loadingMore: true } : { loading: true });
    return (
      provider
        .fetchComments({ articleId, domainId, sortBy: this.state.get('sortBy'), startCursor })
        .then(({ data, nextCursor }) => {
          var prevList = startCursor ? this.state.get('list') : [];
          this.setState({ list: prevList.concat(data), nextCursor, });
        })
        .catch(e => {
          console.error(e);
          this.setState({ error: 'error' });
        })
        .finally(() => this.setState({ loading: false, loadingMore: false }))
    );
  }
  fetchNext =() => { this.fetchComments(this.state.get('nextCursor')); }

  fetchCommentLikes = () => {
    const { provider, articleId, domainId } = this.args;
    provider
      .fetchCommentLikes({ articleId, domainId })
      .then(commentLikes => this.setState({ commentLikes }))
      .catch(e => console.error(e))
  }


  checkIsAdmin = async () => {
    const
      { provider, domainId } = this.args,
      userId = provider.getUserId(),
      roleIsAdmin = async () => {
        const
          snap = await provider.getDomainUserAccess({ domainId, userId }),
          role = snap.exists() && snap.data().role;
        return role === 'admin' || role === 'write';
      };

    if (this.checkedDomainId === domainId) {
      return;
    }

    this.checkedDomainId = domainId;
    this.setState({ isAdmin: userId === domainId || (await roleIsAdmin())});
  }

  getUserId = () => this.args.provider.getUserId();

  getUserName = () => this.args.provider.getUserName();

  postComment = async ({ comment, commentId }) => {
    const { articleId, domainId, provider } = this.args;
    await provider.postComment({ articleId, domainId, comment, commentId });
    this.setState({ countDiff: this.state.get('countDiff') + 1 });
  }

  likeComment = (commentId, like) => {
    const { articleId, domainId, provider } = this.args;
    return provider.likeComment({ domainId, articleId, commentId, like });
  }

  prompt = (args) => {
    return this.args.app.popupManager.open(Prompt, args);
  }

  openDeletePopup = (commentId) => {
    const { domainId, provider } = this.args;
    this.prompt({
      title: 'Delete Comment',
      question: 'Are you sure you want to delete this comment?',
      submitLabel: 'Yes',
      onSubmit: async () => {
        await provider.deleteComment({ domainId, commentId });
        this.fetchComments();
        this.setState({ countDiff: this.state.get('countDiff') - 1 });
      }
    });
  }

  reportComment = (commentId) => {
    const { domainId, provider } = this.args;
    this.prompt({
      title: 'Report Comment',
      inputs: [{
        label: 'You are about to report this comment. Please state your reason.',
        type: 'textarea',
        defaultValue: '',
        autoFocus: true
      }],
      submitLabel: 'Submit',
      onSubmit: async ([ cause ]) => {
        await provider.reportComment({ domainId, commentId, cause });
        this.prompt({
          title: 'Reported',
          content: <div className="font-sm text-center pt-4">The comment has been reported.</div>,
          submitLabel: 'Ok',
          hasCancel: false
        });
      }
    });
  }
}

export default Controller;