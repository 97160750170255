import { TextInput, Checkbox, Selection } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';
import Icon from 'lib/Icon';
import { Link2Icon, OpenInNewWindowIcon } from '@radix-ui/react-icons';
import { Link } from 'react-aria-components';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import { baklavaUrl } from 'config';


function Auth({ popup }) {
  const
    { machine } = popup,
    status = machine.state.get('status'),
    errorMessage = machine.state.get('errorMessage'),
    errorName = machine.state.get('errorName'),
    connecting = (status === 'connecting' || status === 'reconnecting');

  var statusMessage, errored;
  switch (status) {
    case 'failed':
      errored = true;
      if (errorMessage === 'incorrect password') {
        statusMessage = 'Incorrect username or password.';
      } else if (errorMessage === 'authentication error') {
        statusMessage = 'Cannot Authenticate User.';
      } else if (errorMessage === 'xhr poll error') {
        statusMessage = 'Cannot reach remote.';
      } else if (errorMessage === 'Failed to fetch' && errorName === 'TypeError') {
        statusMessage = 'Failed to connect. Please verify the address and if the server is running.';
        if (popup.state.get('protocol') === 'https') {
          statusMessage += ' If you are using manually created certs on Baklava, ';
          statusMessage += `navigate to the URL above and allow insecure content.`;
        } else {
          if (popup.state.get('domain') !== 'localhost') {
            statusMessage += ' If your browser doesn\'t allow you to ';
            statusMessage += 'connect to Baklava over HTTP if its not "localhost". ';
            statusMessage += 'Refer to documentation on how run baklava on HTTPS.';            
          }
        }
      } else {
        statusMessage = 'Error establishing connection.';
      }
      break;
    case 'connecting': statusMessage = 'Connecting...'; break;
    case 'reconnecting': statusMessage = 'Reconnecting...'; break;
    case 'disconnected': statusMessage = 'Disconnected'; break;
    case 'authenticating': statusMessage = 'Authenticating'; break;
    default: statusMessage = '';
  }

  return (
    <div>
      <div className="flex gap-4">
        <div className="w-1/3">
          <div className="text-center py-2 mb-4">
            <Icon faIcon={faLaptopCode} className="text-7xl" />
          </div>

          <div className="text-sm bg-wax p-4">
            <p>
              <span className="font-bold">Install Baklava</span> on your computer to establish 
              a remote connection and launch backend applications.
            </p>
            <p>            
              <Link href={baklavaUrl} target="_blank" className="underline font-medium">
                See Instructions <OpenInNewWindowIcon className="inline" />
              </Link>
            </p>
          </div>

        </div>
        <div className="w-2/3">
          <AuthForm popup={popup} connecting={connecting} />          
        </div>
      </div>

      { statusMessage && (
        <div className={'p-2 border border-rim font-mono text-xs mb-2' + (errored ? ' text-error' : '')}>
          { statusMessage }
        </div>
      ) }
    </div>
  )
}

function AuthForm({ popup, connecting }) {
  const { state } = popup;

  return (
    <form className="flex-grow" onSubmit={e => { e.preventDefault(); popup.connect(); }}>
      <div className="flex gap-2">
        <div className="w-20">
          <Selection
            label="Protocol"
            value={state.get('protocol')}
            onChange={v => popup.setState({ protocol: v })}
            options={[
              { key: 'http', caption: 'http' },
              { key: 'https', caption: 'https' }
            ]}
            isDisabled={connecting} />
        </div>
        <div className="flex-grow">
          <TextInput
            label="Domain or IP"
            placeholder="0.0.0.0 or localhost"
            value={state.get('domain')}
            onChange={v => popup.setState({ domain: v })}
            isDisabled={connecting} />
        </div>

        <div className="w-16">
          <TextInput
            label="Port"
            value={state.get('port')}
            onChange={v => popup.setState({ port: v })}
            isDisabled={connecting} />
        </div>
      </div>
      <div className="">
        <div className="font-mono text-xs py-2">{ `${popup.getURL()}` }</div>        
      </div>

      <hr />

      <TextInput
        label="Username"
        value={state.get('username')}
        onChange={v => {
          if (state.get('fromSavedAuth'))
            popup.forget();
          popup.setState({ username: v })
        }}
        isDisabled={connecting} />

      <TextInput
        label="Password"
        type="password"
        autoComplete="off"
        value={state.get('password')}
        onChange={v => {
          if (state.get('fromSavedAuth'))
            popup.forget();
          popup.setState({ password: v })
        }}
        isDisabled={connecting} />

       { state.get('fromSavedAuth') && (
         <div className="text-right">
           <span className="link-button" onClick={_ => { popup.forget(); popup.clear();} }>Forget</span>
         </div> 
       ) }

      <Checkbox
        label="Remember Credentials?"
        value={state.get('rememberAuth')}
        onChange={v => {
          if (!v)
            popup.forget();
          popup.setState({ rememberAuth: v })
        }}
        isDisabled={connecting} />

      <Buttons className="text-right">
        {connecting ? (
          <Button
            onPress={e => { e.preventDefault(); popup.closeConnection(); }}>
            Cancel Connection
          </Button>
        ) : (
          <Button type="submit">
            <Link2Icon className="inline" />&nbsp; Connect
          </Button>
        )}
      </Buttons>
    </form>
  );
}

export default Auth;