import BasePopupController from 'lib/popup/BasePopupController';
import { fromJS } from 'immutable';
import { toHuman } from 'lib/firebaseError';
import View from './View';

/*
  Doc Files
  code.py       (local, text/js, content)
  settings.json (lcoal, text/json, content)
  recorded.wav  (lcoal, application/octate, content, encoded)
  ash.png       (local, image/png, content, encoded)
  code.png      (upload, URL)
  
  Uploaded Files
  generated.png
  backend_generated.txt
  wasm_helper.js
  webassembly.wasm

  'status' states:
    start
    initializing
    uninitialized
    loading
    loaded
    errored
*/

class Controller extends BasePopupController {
  position = fromJS({ width: 480 });
  static View = View;

  constructor(args, manager) {
    super(args, manager);
    this.machine = args.machine;
    this.docId = args.docId;
    this.docPath = args.docPath;
    this.localFiles = args.getLocalFiles();
    this.state = this.state.merge({
      fileStatus: fromJS({}),
      fileErrors: fromJS({}),
      isSynced: false,
      status: 'start'
    });

    // Close Callback
    if (args.onClose)
      this.unloadFns.push(() => args.onClose(this.state.get('isSynced')));

    // If machine connects later
    this.unloadFns.push(args.machine.emitter.on('change', () => {
      const machineStatus = args.machine.state.get('status');
      if (this.state.get('machineStatus') !== machineStatus) {
        this.setState({ machineStatus });
        if (machineStatus === 'connected')
          this.begin();
      }
    }));

    this.begin();
  }

  async begin() {
    if (this.machine.state.get('status') !== 'connected')
      return;
    await this.tryLoad();

    if (this.args.autoSyncAndClose && this.state.get('status') === 'loaded') {
      await this.syncAll();
      if (this.state.get('isSynced'))
          this.close();
    }
  }

  fetchRemoteIndex = () => this.machine.diskSync.fetchRemoteIndex({ docId: this.docId });
  initializeRemote = () => this.machine.diskSync.initialize({ docId: this.docId, docPath: this.docPath });

  tryInitializeRemote = async () => {
    try {
      this.setState({ status: 'initializing' });
      await this.initializeRemote();
      await this.tryLoad();
    } catch (e) {
      this.setState({ status: 'errored', error: `Error Initializing Folder. ${toHuman(e)}` });
      return;
    }
  }

  tryLoad = async () => {
    this.setState({ status: 'loading' });
    let remoteIndex;
    try {
      remoteIndex = await this.fetchRemoteIndex();
      if (remoteIndex.docPath !== this.docPath) {
        this.setState({ status: 'uninitialized' });
        return;
      }
    } catch (e) {
      this.setState({ status: 'errored', error: `Error Loading. ${toHuman(e)}` });
    }

    let
      files = this.localFiles.getAllFiles(),
      fileStatus = fromJS({}),
      isSynced = true;

    for (let filename in files) {
      const
        file = files[filename],
        remoteFile = remoteIndex.files && remoteIndex.files[file.filename],
        versionSync = remoteFile && remoteFile.version === file.version && remoteFile.exists,
        status = file.isPulled ? 'pulled' : !versionSync ? 'not-synced' : 'synced';

      isSynced = isSynced && status !== 'not-synced';
      fileStatus = fileStatus.set(file.filename, status);
    }

    this.setState({
      remoteIndex, files,
      fileStatus, isSynced, status: 'loaded'
    });
  }

  syncAll = async () => {
    if (this.state.get('status') !== 'loaded')
      return;

    this.setState({ isSyncing: true });

    let
      isSynced = true,
      { docId } =  this,
      files = this.state.get('files');

    // add unsynced files
    for (let filename in files)  {
      const
        file = files[filename],
        status = this.state.getIn(['fileStatus', filename]),
        setStatus = s => this.setInState(['fileStatus', filename], s),
        setError = s => this.setInState(['fileErrors', filename], s);

      if (status === 'not-synced') {
        setStatus('syncing');
        try {
          // await new Promise((res) => setTimeout(res, 1000));
          await this.machine.diskSync.addFile({
            docId,
            file: (await file.toObject(true))
          });
          setStatus('synced');
        } catch (e) {
          isSynced = false;
          console.error(e);
          setStatus('error');
          setError(e.message);
        }
      }
    };
    
    // await this.tryLoad();
    this.setState({ isSynced, isSyncing: false });
  }

  browseFiles = () => {
    this.machine.browseFilesPopup({
      docId: this.docId,
      docPath: this.docPath,
      onClose: () => this.tryLoad()
    });
  }
}

export default Controller;