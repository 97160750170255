import { Link, Button } from 'react-aria-components';
import { DotFilledIcon, DotIcon, Cross2Icon } from '@radix-ui/react-icons';
import clsx from 'clsx';


export default function Folder({ viewer }) {
  const { sections } = viewer.workspace;

  return (
    <div>
      { sections.map((section, i) => section.items.length > 0 && (
        <div className="mb-3 avoid-column-break" key={i}>
          { section.title && (
            <div className="text-pencil2 py-2 text-sm">
              {section.title}
            </div>
          ) }

          <div className="">
            {section.items.map(item => (
              <FolderItem key={item.id} item={item} section={section} viewer={viewer} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}


export function FolderItem({ item, viewer }) {
  const
    { publishedVersionTitle, path, id, type } = item,
    isFolder = type === 'folder',
    isOpen = !!viewer.articles[id],
    isActive = id === viewer.state.get('articleId') || id === viewer.state.get('folderId');

  return (
    <div className="flex items-top text-sm">
      <div className="pt-2">
        {
          viewer.state.get('loadingIds').has(id) ? (
            <div class="animate-ping">
              <DotFilledIcon />
            </div>
          ) :
          isOpen ? (
          <Button
            className={({ isDisabled }) => clsx(
              isDisabled && 'disabled',
              'group close-button outline-none'
            )}
            isDisabled={isActive}
            onPress={(e) => viewer.closeArticle(id)}>
            <div className={clsx(!isActive && 'group-hover:hidden')}><DotFilledIcon /></div>
            <div className={clsx('hidden ', !isActive && 'group-hover:block')}><Cross2Icon /></div>
          </Button>
        ) : (
          <DotIcon />
        ) }
      </div>

      <Link
        className={clsx(
          'flex-grow py-1 px-2 outline-none',
          isActive ? 'bg-wax font-medium' : 'hover:bg-wax2',
          isActive ? '' : isOpen ? 'text-pencil' : '',
          isFolder && 'text-marker2'
        )}
        href={'/' + path.replace('\\', '/')}>
        <div>
          { publishedVersionTitle }
        </div>
      </Link>
    </div>
  );
}