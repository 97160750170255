import BaseController from 'lib/BaseController';
import Workspace from './Workspace';
import Article from './Article';
import { Set } from 'immutable';
import Comments from '../popups/Comments/Controller';
import CloneArticle from 'modules/folder/popups/CloneArticle/Controller';


export default class Viewer extends BaseController {
  constructor({ app }) {
    super();
    this.app = app;
    // this.provider = app.remoteProvider;
    this.articles = {};
    this.workspace = new Workspace();
    this.state = this.state.merge({
      openIds: new Set(),
      loadingIds: new Set(),
      minimized: false,
      persistPage: true,
      showMinimize: false,
      showMenu: true,
      autoHideMenu: this.getAutoHide(),
      panelLeft: this.getPanelLocal('left'),
      panelRight: null,
      keepArticleActive: true
    });
  }
  
  loadPath(path) {
    console.log('loading path', path);
    const domainSlug = path.split('/')[0];
    this.provider = domainSlug === 'local' ? this.app.localProvider : this.app.remoteProvider;

    this.setState({ error: null, loading: true, path: path, showMenu: true });
    this.tryLoadPath(path)
      .catch(e => { console.error(e); this.setState({ error: e }); })
      .then(() => { this.setState({ loading: false }); });
  }

  async tryLoadPath(path) {
    const
      { provider } = this,
      pathData = await provider.fetchPath(path.replaceAll('/', '\\')) || {},
      { type, domainId, articleId } = pathData;

    let folderId;

    if (type === 'version' || type === 'article') {
      if (!this.articles[articleId]) {
        this.articles[articleId] = await this.loadArticle(pathData)
        this.setState({ openIds: this.state.get('openIds').add(articleId) });
      }
      folderId = this.articles[articleId].article.parentId;

    } else if (type === 'folder') {
      folderId = pathData.folderId;
      // this.closeAllArticles();

    } else {
      throw new Error('Not Found.');
    }
    
    await this.workspace.load({ folderId, domainId, provider });
    this.setState({ type, articleId, folderId, domainId });
    this.checkBackgroundArticle();
  }

  loadArticle = async (pathData) => {
    let
      { app, provider, workspace } = this,
      { themeData } = workspace,

      { type, domainId, articleId, publishedVersionId } = pathData,
      versionId = type === 'version' ? pathData.versionId : publishedVersionId,
      version, elements;

    if (type === 'article' && !publishedVersionId)
      throw new Error('The article isn\'t published.');

    try {
      this.setState({ loadingIds: this.state.get('loadingIds').add(articleId) });
      [ version, elements ] = await Promise.all([
        provider.fetchVersion({ domainId, articleId, versionId }),
        provider.fetchVersionElements({ domainId, articleId, versionId })
      ]);
    } finally {
      this.setState({ loadingIds: this.state.get('loadingIds').delete(articleId) });
    }

    return new Article({
      app, provider, version,
      elements, themeData,
      expandDetail: this.expandDetail
    });
  }

  closeArticle = (id) => {
    this.setState({ openIds: this.state.get('openIds').delete(id) })
    this.articles[id].unload();
    delete this.articles[id];
  }

  closeAllArticles = () => {
    for (var key in this.articles) {
      this.articles[key].unload();
    }
    this.articles = {};
    this.setState({ openIds: new Set() });
  }

  unload = () => {
    this.workspace.unload();
    this.closeAllArticles();
    this.setState({
      type: null, path: null, openIds: new Set(),
      articleId: null, versionId: null, domainId: null
    });
  }

  setMinimized = (minimized) => {
    this.setState({  minimized });
  }

  getNextPrevPaths = (path) => {
    if (this.state.get('type') === 'version')
      return [null, null];
    
    path = path || (
      this.state.get('type') === 'article' ?
        this.currentArticle.article.path :
        this.workspace.folder.path
    );

    var a1 = null, a2 = null, a3 = this.workspace.folder.path;

    for (var section of this.workspace.sections) {
      for (var item of section.items) {
        if (item.type === 'article') {
          a1 = a2; a2 = a3; a3 = item.path;
        }
        if (a2 === path)
          return [a1, a3];
      }
    }
    return (a3 === path) ? [a2, null] : [null, null];
  }

  onArticleScroll = (position, diff) => {
    if (diff > 0 && this.state.get('showMenu')) {
      this.setState({ showMenu: false });
    } else if (diff < 0 && !this.state.get('showMenu')) {
      this.setState({ showMenu: true });
    }
    // console.log
  }

  getAutoHide() {
    return false;
    // return window.localStorage.getItem('viewer-auothide-menu') === 'yes';
  }

  setAutoHide(b) {
    this.setState({ autoHideMenu: b, showMenu: !b });
    // window.localStorage.setItem('viewer-auothide-menu', b ? 'yes' : 'no');
  }

  get articleId() {
    return this.state.get('type') === 'article' ? this.state.get('articleId') : null
  }

  get currentArticle() {
    return this.articles[this.state.get('articleId')] || null;
  }

  setPanelLeft = (p) => {
    console.log('setting', p)
    this.setState({ panelLeft: p }); this.savePanelLocal('left', p);
  }
  savePanelLocal = (side, p) => window.localStorage.setItem(`viewer-panel-${side}`, p || '');
  getPanelLocal = (side) => window.localStorage.getItem(`viewer-panel-${side}`) || '';
  expandDetail = (id) => this.setState({
    panelRight: id && 'detail',
    detailId: id
  });

  openComments = () => {
    let
      domainId = this.state.get('domainId'),
      domain = this.workspace?.domain,
      { provider, app, articleId } = this,
      title = articleId ? this.currentArticle?.article?.title : domain.title,
      isDisabled = (
        (articleId &&  !domain?.allowComments?.articles) ||
        (!articleId && !domain?.allowComments?.workspace)
      );
    
    app.popupManager.open(Comments, { domain, isDisabled, domainId, articleId, title, provider, app });
  }

  openClonePopup = () => {
    const { elementControllers, article } = this.currentArticle;
    let elements = [];
    for (let id in elementControllers) {
      elements.push({ id, ...elementControllers[id].getStateData() });
    }

    this.app.popupManager.open(CloneArticle, {  
      app: this.app,
      title: article.title,
      slug: article.slug,
      sourceData: {
        article: article,
        elements: elements
      },
      hideInfo: true
    });
  }

  toggleKeepArticleActive = () => {
    this.setState({ keepArticleActive: !this.state.get('keepArticleActive') });
    this.checkBackgroundArticle();
  }

  checkBackgroundArticle = () => {
    if (this.state.get('keepArticleActive'))
      return;

    for (let id in this.articles) {
      console.log(id, this.state.get('articleId'));
      if (id !== this.state.get('articleId')) {
        this.closeArticle(id);
      }
    }
  }
}