import Loader from 'lib/staticPages/Loader';
// import { DotFilledIcon } from '@radix-ui/react-icons'
// import clsx from 'clsx';


export default function ItemTitle({ item, viewer }) {
  const
    // isOpen = viewer.state.get('openIds').has(item.id),
    isLoading = viewer.state.get('loadingIds').has(item.id);

  return (
    <span>
      { item.publishedVersionTitle }
      {/*{ isOpen && <>&nbsp;<DotFilledIcon className="inline" /></> }*/}
      { isLoading && <>&nbsp;<Loader /></> }
    </span>
  )
}