import { useEffect, useState, useMemo } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { EditorView, keymap } from '@codemirror/view';
import { indentUnit } from '@codemirror/language';
import { loadLanguage } from '@uiw/codemirror-extensions-langs';
import allThemes from 'lib/codemirror/themes';


export default function CodeMirrorInstance({
  fileController, onCreateEditor, onFocus,
  isHidden, themeKey, height, readOnly, lineWrapping, hasLineNumbers
}) {
  const
    { state, setState } = fileController,
    mode = fileController.getSettingsValue('mode') || fileController.getSuggestedMode(),
    tabMode = fileController.getSettingsValue('tabMode') || fileController.getSuggestedTabMode();

  const extensions = useMemo(() => {
    const
      extensions = [],
      languageExtension = loadLanguage(mode);

    if (languageExtension)
      extensions.push(languageExtension)

    if (lineWrapping) {
      extensions.push(EditorView.lineWrapping)
    }

    extensions.push(keymap.of([{
      key: 'Mod-s',
      run: (view) => { fileController.save(); return true; },
    }]));

    if (tabMode === '4s') {
      extensions.push(indentUnit.of('    '))
    }

    return extensions;
  }, [tabMode, mode, lineWrapping, fileController]);
  
  // Updater crew
  const [, setCurrentState] = useState(fileController.state);
  useEffect(() => fileController.onStateChange(() => setCurrentState(fileController.state)), [fileController]);

  return (
    <div
      className="codemirror-instance flex-grow"
      style={{display: isHidden ? 'none' : 'block'}}>
      <CodeMirror
        value={state.get('content') || ''}
        onChange={(value, viewUpdate) => setState({ content: value })}
        extensions={extensions}
        theme={allThemes[themeKey]}
        height={height}
        indentWithTab={true}
        basicSetup={{
          lineNumbers: hasLineNumbers,
          highlightActiveLineGutter: false,
          highlightActiveLine: false,
          foldGutter: false,
        }}
        readOnly={readOnly}
        onFocus={onFocus}
        onCreateEditor={onCreateEditor} />
    </div>
  );
}