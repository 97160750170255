import elementLibs from 'modules/elements/all';
import { useControllerState } from 'lib/BaseController';
import clsx from 'clsx';
import { memo } from 'react';

export default function Scroll({ article, panelLeft, panelRight, beforeElement, before }) {
  const { layout } = article;
  
  return (
    <div
      className={clsx(
        'md:px-12 px-4 pt-20 pb-24 transition-[padding]',
        panelLeft && 'md:pl-[22rem]',
        panelRight === 'detail' && 'lg:pr-[42rem]',
        panelLeft && panelRight && 'lg:inline-block'
      )}>
      <div className="page-max-width mx-auto relative lg:min-w-[40rem]">
        { before }
        <div className="my-6 article-title font-header">
          {article.article.title}
        </div>
        <div>
          { layout.order.map((id, index) => article.elementControllers[id] && (
            <div className="my-8 relative" key={id}>
              { beforeElement && beforeElement(id) }
              <Element element={article.elementControllers[id]} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const Element = memo(({ element }) => {
  useControllerState(element);
  const lib = elementLibs[element.type];
  return (<lib.Viewer controller={element.controller} />);
})