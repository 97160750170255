import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import clsx from 'clsx';
import {
  GearIcon, ThickArrowLeftIcon, GlobeIcon,
  ComponentBooleanIcon, BarChartIcon, StarIcon, Cross2Icon
} from '@radix-ui/react-icons';
import { KeyIcon } from '@heroicons/react/24/outline'


import { Frame } from 'modules/app/views/Home/misc/components';
import { Link } from 'react-aria-components';
import { useControllerState } from 'lib/BaseController';
import { ErrorBlock } from 'lib/staticPages';

import Preferences from './Preferences';
import Publication from './Publication';
import Access from './Access';
import Usage from './Usage';
import Upgrade from './Upgrade';
import Loader from 'lib/staticPages/Loader';


const viewMap = {
  preferences: Preferences,
  publication: Publication,
  users: Access,
  usage: Usage,
  upgrade: Upgrade,
}

const titleMap = {
  preferences: 'Preferences',
  publication: 'Publication',
  users: 'Access and Visibility',
  usage: 'Usage and Limits',
  upgrade: 'Upgrade',
}

const fullPages = new Set(['upgrade']);


export default function SettingsPageLoader({ app }) {
  const
    { workspace } = app,
    { domainController } = workspace,
    match = useMatch({ path: '/:page/:slug' }),
    page = match.params['page'],
    slug = match.params['slug'],
    state = useControllerState(workspace);

  useEffect(() => { workspace.loadPath(slug) }, [workspace, slug]);

  if (state.get('error'))
    return (<ErrorBlock className="mt-10" title="Domain Load Error" error={state.get('error')} />);

  return (<Page page={page} domainController={domainController} />);
}

function Page({ page, domainController }) {
  const
    state = useControllerState(domainController),
    domain = state.get('domain'),
    View = viewMap[page];

  if (!domain)
    return (
      <Frame className="max-w-[50rem] text-center pt-10 text-xl">
        <Loader />
      </Frame>
    );
  
  if (fullPages.has(page)) {
    return (<View controller={domainController} />);
  }

  const backHref = '/workspaces/' + domain.get('slug');

  return (
    <Frame className="max-w-[50rem] flex">
      <Link className="absolute top-6 right-6 max-md:hidden" href={backHref}>
        <Cross2Icon />
      </Link>

      <Link className="absolute top-8 left-8 md:hidden" href={backHref}>
        <ThickArrowLeftIcon className="w-4 h-4" />
      </Link>

      <Sidebar page={page} domain={domain} />

      <div className="flex-grow h-full overflow-auto flex flex-col">
        <div className="font-medium mb-2 text-center text-xl px-8 py-4 border-b">
          {titleMap[page]}
        </div>

        <div className="px-8 py-6">
          <View controller={domainController} />
        </div>
      </div>
    </Frame>
  );  
}


function Sidebar({ page, domain }) {
  const
    title = domain.get('title'),
    slug = domain.get('slug');

  return (
    <div className="w-[18rem] border-r border-rim flex-shrink-0 max-md:hidden">
      <div className="py-12 px-4 border-b border-rim text-center text-medium text-3xl font-medium">
        {title}
      </div>

      <div className="flex-grow px-2 py-6">
        <LeftButton Icon={GearIcon} label="Preferences" isActive={page === 'preferences'} href={'/preferences/' + slug} />
        <LeftButton Icon={KeyIcon} iconClassName="size-4" label="Access and Visibility" isActive={page === 'users'} href={'/users/' + slug} />
        <LeftButton Icon={BarChartIcon} label="Usage and Limits" isActive={page === 'usage'} href={'/usage/' + slug} />
        <LeftButton Icon={GlobeIcon} label="Publication" isActive={page === 'publication'} href={'/publication/' + slug} />
        <LeftButton Icon={ComponentBooleanIcon} label="Theme" isActive={page === 'theme'} href={'/theme/' + slug} />
        <LeftButton Icon={StarIcon} label="Upgrade" isActive={page === 'upgrade'} href={'/upgrade/' + slug} />
      </div>
    </div>
  )
}


function LeftButton({ label, isActive, href, Icon, iconClassName }) {
  return (
    <Link
      href={href}
      className={clsx(
        'py-1 px-3 mb-1 text-left flex items-center gap-2 rounded-sm outline-none',
        'hover:bg-wax',
        isActive && 'bg-wax2 font-medium'
      )}>
      <Icon className={iconClassName} />{label}
    </Link>
  )
}