import { useEffect, useState } from 'react';
import { FixedFrame, DynamicFrame, GridFrame } from './frames';
import { Floaters, Resizers, FloatingMenubar } from './floaters';
import { BottomTargetBar } from './bars';


function Editor({ controller, isActive, isGrid }) {
  const
    [dragging, setDragging] = useState(false),    
    { getSettingsValue } = controller,
    isFixed = getSettingsValue('layout') === 'fixed',

    floaters = (
      <>

        {/* Overlay */}
        { dragging && (
          <div className="absolute top-0 left-0 w-full h-full bg-transparent" />
        )}

        {/* Play Button, Missing Target */}
        <Floaters controller={controller} isGrid={isGrid} />

        <Resizers controller={controller} isGrid={isGrid} onDrag={setDragging} />

        {/* Menu */}
        <FloatingMenubar controller={controller} />
      </>
    );

  const
    pre = false,
    post = (
      <>
        <BottomTargetBar controller={controller} />
      </>
    ),
    props = { controller, pre, floaters, post },
    Frame = isGrid ? GridFrame : isFixed ? FixedFrame : DynamicFrame;

  // Autorun
  useEffect(() => controller.checkAutorun(), [controller]);
  return (<Frame {...props} />);
}


export default Editor;