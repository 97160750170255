import { useRef, useEffect } from 'react';
import { Button } from 'react-aria-components';
import { Cross1Icon, EraserIcon, CaretLeftIcon, CaretRightIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';


const formatValue = (value) => {
  if (value === null) return 'null';
  if (Array.isArray(value)) {
    return `[${value.map(formatValue).join(', ')}]`;
  }
  if (typeof value === 'object') {
    if (value.__type === 'Error') {
      return `${value.name}: ${value.message}\n${value.stack}`;
    }
    return JSON.stringify(value, null, 2);
  }
  return String(value);
};



export default function Console({ controller }) {
  const
    inputRef = useRef(),
    outputRef = useRef(),
    logs = controller.state.get('logs');

  useEffect(_ => {
    if (outputRef.current && controller.state.get('inputFocused')) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [logs, controller]);

  return (
    <div
      className={clsx(
        'nb-web-runner-console bg-paper border border-rim text-sm absolute bottom-0 right-0',
        'w-full max-w-[100%] text-left flex flex-col max-h-[calc(100%-2.5rem)]'
      )}>
      {/*<div className="pt-2 pb-1 pr-2 pl-6 border-rim flex items-center"></div>*/}
      <div className="float-right flex gap-2 absolute right-3 top-2">
        <Button
          className={({ isDisabled }) => clsx(isDisabled && 'opacity-30', 'p-1 bg-paper')}
          onPress={_ => controller.clearLogs()}
          isDisabled={logs.size === 0}>
          <EraserIcon />
        </Button>

        <Button
          className="p-1 bg-paper"
          onPress={_ => controller.setState({ showConsole: false })}>
          <Cross1Icon />
        </Button>
      </div>

      <div className="overflow-auto flat-scrollbar min-h-5 py-3 font-mono text-xs" ref={outputRef}>
        <div className="w-20 h-4 float-right"></div>
        { logs.map((l, i) => (
          <div
            key={i}
            className={clsx(
              'flex py-1',
              (l.type === 'eval-out-error' || l.type === 'error') && 'text-red-700'
            )}>
            <div className="w-8 px-1 text-center">
              {
                l.type === 'eval-in' ? 
                  (<CaretRightIcon className="inline" />) :
                l.type === 'eval-out' ? 
                  (<CaretLeftIcon className="inline opacity-30" />) : ''
              }
            </div>

            <span className="whitespace-pre-wrap">
              { l.args.map((a, i) => (
                typeof a === 'number' ?
                  (<span className="number text-marker2" key={i}>{a}&nbsp;</span>) :
                  typeof a === 'undefined' ?
                    (<span className="opacity-30" key={i}>undefined&nbsp;</span>) :
                    a === null ?
                      (<span className="empty" key={i}>null&nbsp;</span>) :
                      (<span className="string" key={i}>{formatValue(a)}&nbsp;</span>)
              )) }
            </span>
          </div>
        ))}

        {/*<BottomRef controller={controller} />*/}
      </div>

      <form
        className="flex font-mono py-1 border-t border-rim items-center"
        onSubmit={e => {
          e.preventDefault();
          controller.submitCommand();
        }}>
        <div className="w-8 text-center">
          <CaretRightIcon className="inline" />
        </div>
        <input
          className="flex-grow outline-none text-xs py-1 bg-paper"
          // autoFocus={true}
          value={ controller.state.get('logInputValue') }
          ref={inputRef}
          onFocus={ _ => controller.setState({ inputFocused: true })}
          onBlur={ _ => controller.setState({ inputFocused: false })}
          onChange={e => {
            controller.setState({ logInputValue: e.currentTarget.value })
          }} />
      </form>
    </div>
  )
}

// function BottomRef({ controller }) {
//   const bottomRef = useRef();
//   useEffect(_ => {
//     if (controller.state.get('inputFocused') && bottomRef.current) {
//       bottomRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, [controller.state.get('logs')]);
//   return (<div className="bottom" ref={bottomRef} />);
// }