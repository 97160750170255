import CommentInput from './CommentInput';
import Entries from './Entries';
import Topbar from './Topbar';
import { useControllerState } from 'lib/BaseController';
import { Link } from 'react-aria-components';


export default function Body({ popup }) {
  const
    state = useControllerState(popup.args.app.auth),
    user = state.get('user');

  return (
    <div className="flex flex-col flex-grow gap-2 h-full">
      <Topbar popup={popup} />
      { user && (
        <div className="px-6">
          <CommentInput popup={popup} commentId={null} comment="" />
        </div>
      ) }
      
      { !user && (
        <div className="p-4 text-center">
          <Link
            href="/auth/login"
            className="font-bold underline"
            onPress={() => { console.log('hey') }}>
            Login
          </Link> to post a comment.
        </div>
      ) }
      
      <Entries popup={popup} />      
    </div>
  )
}