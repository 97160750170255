import Loader from './Loader';
import { toHuman } from 'lib/firebaseError';
import { supportEmail } from 'meta';
import clsx from 'clsx';
import Icon from 'lib/Icon';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';


import './style.css';

export function LoadingBlock() {
  return (
    <div className="nb-loading-block">
      <Loader />
    </div>
  );
}


export function ErrorBlock({ title, error, style, className }) {
  return (
    <div className={clsx('text-center max-w-[30rem] mx-auto pt-16 pb-4', className)} style={style}>
      <div className="text-4xl font-medium text-error2 py-6 border-error">
        <Icon faIcon={faTriangleExclamation} /> &nbsp;
        {title || 'Error'}
      </div>
      <p className="py-6 break-words text-left whitespace-pre-wrap break-all border-t border-b">
        {typeof error === 'string' ? error : toHuman(error)}
      </p>
      <p className="border-rim mt-4">
        Contact our support at <span className="font-mono text-blue-600 text-sm">{supportEmail}</span> <br />
        if this is not an expected behavior.</p>
    </div>
  );
}