import IndieRunner from 'lib/IndieRunner'


export default function Program({ demo, app }) {
  const
    { title } = demo,
    height = Number(demo.height || 400) + 20,
    splits = demo.target.split('/'),
    importMap = demo.importMap ? JSON.parse(demo.importMap) : {};

  const
    target = splits.pop(),
    localPath = splits.join('/');

  return (
    <IndieRunner {...{ app, title, target, localPath, height, importMap }} />
  );
}