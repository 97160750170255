import { Button, Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';
import { HeadingIcon } from '@radix-ui/react-icons';
import { FolderIcon, DocumentIcon } from '@heroicons/react/24/outline';

import clsx from 'clsx';


export default function AddMenu({ controller, className, itemClassName, children }) {
  const
    { popups } = controller,
    menuItems = [
      [<><DocumentIcon className="size-4 inline-block mr-2" /> Add Article</>, () => popups.createArticle()],
      [<><FolderIcon className="size-4 inline-block mr-2" /> Add Folder</>, () => popups.createFolder()],
      [<><HeadingIcon className="inline-block mr-2" /> Add Section</>, () => popups.createSection()],
    ];

  return (
    <MenuTrigger>
      <Button className={clsx('outline-none', className)}>
        { children }
      </Button>
      
      <Popover placement="top">
        <Menu onAction={(i) => menuItems[i][1]()} className="outline-none flex flex-col gap-1">
          {
            menuItems.map(([caption], i) => (
              <MenuItem
                key={i}
                id={i}
                className={clsx(
                  'bg-paper border border-rim hover:bg-wax cursor-pointer ',
                  itemClassName || 'px-3 py-1 text-sm'
                )}>
                { caption }
              </MenuItem>
            )
          )}
        </Menu>
      </Popover>
    </MenuTrigger>
  );
}