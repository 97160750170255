import File from './File';

import { getMimeType } from 'lib/codemirror/languages';


export default class RemoteFiles {
  constructor({ getProvider }) {
    this.getProvider = getProvider;
    this.proxy = {
      getFile: this.getFile,
      fetchPath: async (path) => await getProvider().fetchPath(path)
    }
  }

  get provider() { return this.getProvider(); }
  
  getFile = async (pathname) => {
    // console.log(pathname);
    let
      { articleId, versionId, domainId, filename, docPath } = await this.fetchIds(pathname),
      { location, content, contentType, isBase64 } = await (
        this.fetchJSONFile({ domainId, articleId, versionId, filename })
          .catch(e => { throw new Error(`Error loading path "${pathname}"`); })
      );

    contentType = contentType || getMimeType(filename); // guess content type

    return new File({
      location, filename, contentType, isBase64, docPath,
      getDownloadURL: this.provider.getDownloadURL,
      getContent: () => content
    });
  }

  fetchIds = async (pathname) => {
    pathname = pathname.substr(1);

    const
      split = pathname.split(/[/:]/),
      filename = split.pop(),
      docPath = split.join('/'),
      path = split.join('\\');

    if (!path)
      throw new Error(`Invalid path ${pathname}`)

    const
      pathData = path && await this.provider.fetchPath(path),
      { type, domainId, articleId, versionId, publishedVersionId } = pathData || {};

    // console.log(split, filename, pathData, docPath)
    if (!pathData || (type !== 'version' && type !== 'article'))
      throw new Error(`Article or version not found: "${docPath}"`);
    
    if (type === 'article' && !pathData.publishedVersionId)
      throw new Error(`The article "${docPath}" is not pubished`);

    return ({
      domainId, articleId,      
      versionId: type === 'version' ? versionId : publishedVersionId,
      filename,
      docPath
    });
  }
  
  fetchJSONFile = async ({ domainId, articleId, versionId, filename }) => {
    const
      location = `domain/${domainId}/article/${articleId}/version/${versionId}/file/${filename}.json`,
      url = await this.provider.getDownloadURL(location),
      response = await fetch(url),
      json = await response.json();
    // console.log('json', json)
    return json;
  }
}