import {
  doc, updateDoc, query, collection, orderBy,
  onSnapshot, writeBatch, getDoc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';


export function onUserInfo(userId, cb, ecb) {
  return onSnapshot(doc(db, 'userInfos', userId), {
    next: (doc) => cb(doc.data()),
    error: ecb
  });
}

export async function fetchUserInfo(userId) {
  return (await getDoc(doc(db, 'userInfos', userId))).data();
}

export async function updateUserInfo({ userId, data }) {
  return await updateDoc(doc(db, 'userInfos', userId), data);
}

export function fetchUserNotes(userId, cb, ecb) {
  const q = query(
    collection(db, 'userInfos', userId, 'notes'),
    // where('deleted', '==', false),
    orderBy('createdAt')
  );
  return onSnapshot(q,
    qss => cb(qss.docs.map(doc => ({ id: doc.id, ...doc.data() }))),
    ecb
  );
}

export async function clearUserNotes(userId, noteIds) {
  const batch = writeBatch(db);
  noteIds.forEach(id => {
    batch.delete(doc(db, 'userInfos', userId, 'notes', id));
  });
  return await batch.commit();
}

export async function getBookmark({ userId, itemId }) {
  return (await getDoc(doc(db, 'userInfos', userId, 'bookmarks', itemId))).data();
}

export async function addBookmark({ userId, itemId, title, path, type }) {
  await setDoc(doc(db, 'userInfos', userId, 'bookmarks', itemId), {
    createdAt: serverTimestamp(), title, path, type
  });
}

export async function deleteBookmark({ userId, itemId }) {
  return await deleteDoc(doc(db, 'userInfos', userId, 'bookmarks', itemId));
}

export function onBookmarks({ userId }, cb, ecb) {
  const q = query(collection(db, 'userInfos', userId, 'bookmarks'), orderBy('createdAt', 'desc'));
  return onSnapshot(q, qss => cb(qss.docs.map(doc => ({ id: doc.id, ...doc.data() }))), ecb);
}

export async function moveToTopBookmark({ userId, itemId }) {
  await updateDoc(doc(db, 'userInfos', userId, 'bookmarks', itemId), {
    createdAt: serverTimestamp()
  });
}