import { useState } from 'react';
import { Button } from 'react-aria-components';
import {
  HeartIcon, HeartFilledIcon, DotsVerticalIcon,
  ExclamationTriangleIcon, TrashIcon, Pencil1Icon, QuoteIcon
} from '@radix-ui/react-icons';
import MenuBuilder from 'lib/components/MenuBuilder';
import CommentInput from './CommentInput';
import { formatDistanceToNow } from 'date-fns';
import Loader from 'lib/staticPages/Loader';
import clsx from 'clsx';


export default function Entries({ popup }) {
  const
    { state } = popup,
    list = state.get('list'),
    commentLikes = state.get('commentLikes'),
    likedIds = new Set(commentLikes.map(l => l.commentId));

  return (
    <div className="flex-grow overflow-auto py-4 px-4 flat-scrollbar">
      {
        list.length === 0 ?
          (<Empty />) :
          list.map(entry => (
            <Entry
              key={entry.id}
              entry={entry}
              liked={likedIds.has(entry.id)}
              popup={popup} />
          ))
      }

      <NextButton popup={popup} />
    </div>
  )
}

function NextButton({ popup }) {
  const loadingMore = popup.state.get('loadingMore');

  return popup.state.get('nextCursor') && (
    <div className="text-center">
      {loadingMore && (<Loader />)}
      {!loadingMore && (
        <Button className="text-marker outline-none" onPress={() => popup.fetchNext()}>
          load more
        </Button>
      )}
    </div>
  )
}

function Empty() {
  return (
    <div className="text-center opacity-60 italic">
      There are no comments yet.
    </div>
  )
}

function Entry({ entry, popup, liked }) {
  const [editing, setEditing] = useState(false);

   if (editing)
     return (
       <div className="mb-4">
          <CommentInput
            popup={popup}
            comment={entry.comment}
            commentId={entry.id}
            onCancel={() => setEditing(false)}
            autoFocus={true} />
        </div>
      );

  return (
    <div className="flex mb-4 pl-4 group gap-2">
      <div className="flex flex-col flex-grow">
        <div className="flex gap-2">
          <div className="flex-grow">
            <div ref={el => { if (el) el.innerHTML = entry.comment }} />
          </div>

          <div className="flex flex-col">
            <div className="">
              <QuoteIcon className="w-6 h-6 text-rim" />
            </div>
          </div>
        </div>

        <div className="flex gap-2 items-center">
          <div className="flex-grow"></div>
          <div className="text-sm">
            <span className="font-medium">
              @{entry.email.substr(0, 3)}
            </span>,&nbsp;
            {formatDistanceToNow(entry.createdAt.seconds * 1000)} ago
            { entry.original && (<span className="italic">, edited</span>) }
          </div>
          ,
          <Likes entry={entry} popup={popup} liked={liked} />
        </div>
      </div>

      <div className={clsx('pt-1')}>
        <Menu {...{ popup, entry, setEditing }} />
      </div>
    </div>
  )
}

function Menu({ popup, entry, setEditing }) {
  const
    isOwner = popup.getUserId() === entry.userId,
    isAdmin = popup.state.get('isAdmin');

  return (
    <MenuBuilder
      options={
        isOwner ? [
          [ 'Delete', TrashIcon, () => popup.openDeletePopup(entry.id)],
          [ 'Edit', Pencil1Icon, () => setEditing(true)],
        ] :
        isAdmin ? [
          [ 'Delete', TrashIcon, () => popup.openDeletePopup(entry.id)],
        ] : [
          [ 'Report', ExclamationTriangleIcon, () => popup.reportComment(entry.id) ],
        ]}
      onOpenChange={e => { popup.checkIsAdmin(); }}>
      <DotsVerticalIcon />
    </MenuBuilder>

  )
}


function Likes({ entry, popup, liked }) {
  const
    [countDiff, setCountDiff] = useState(0),
    [override, setOverride] = useState(null),
    value = override == null ? liked : override,
    likesCount = entry.likesCount + countDiff;

  return (
    <div className="flex gap-2 text-pencil3 text-sm">
      <Button
        className="outline-none"
        onPress={() => {
          popup.likeComment(entry.id, !value);
          setOverride(!value);
          setCountDiff(countDiff + (value ? -1 : 1));
        }}>
        { value ? <HeartFilledIcon className="h-3 w-3" /> : <HeartIcon className="h-3 w-3" /> }
      </Button>

      {likesCount > 0 && likesCount}
    </div>
  )
}