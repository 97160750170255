import { HeightResizer, WidthResizer } from 'modules/elements/lib/components/resizers';
import Gallery from './Gallery';
import Menu from './Menu';
import clsx from 'clsx';


function Editor({ controller, isGrid }) {
  return (
    <div
      className={clsx('nb-gallery-editor relative group/images', isGrid && 'h-full')}
      onClick={controller.focus}>
      <HiddenInput controller={controller} />
      <Gallery controller={controller} isGrid={isGrid}>
        <Menu controller={controller} />
        <Resizers {...{ controller, isGrid }} />
      </Gallery>
    </div>
  );
}

function HiddenInput({ controller }) {
  return (
    <input
      className="w-0 h-0 outline-none pointer-events-none absolute opacity-0 top-1/2 left-1/2"
      ref={ref => controller.setHiddenInputRef(ref)}
      onBlur={_ => controller.handleBlur()}
      onFocus={e => controller.handleFocus()}
      onKeyDown={evt => controller.onKeyDown(evt)} />    
  )
}

export function Resizers({ controller, isGrid }) {
  const
    { getSettingsValue, setSettings, isReadOnly } = controller,
    gsv = getSettingsValue,
    onDrag = () => {};

  return !isGrid && !isReadOnly && (
    <>
      <HeightResizer
        onDrag={onDrag}
        onChange={(dy) => {
          let
            h = gsv('maxHeight') + dy,
            range = controller.settingsFields['maxHeight'].range;
          h = Math.max(Math.min(h, range[1]), range[0]);
          // h = h - (h % 8);
          setSettings('maxHeight', h );
        }} />

      <WidthResizer
        onDrag={onDrag}
        onChange={(dx) => {
          let
            w = gsv('maxWidth') + dx,
            range = controller.settingsFields.maxWidth.range;
          w = Math.max(Math.min(w, range[1]), range[0]);
          // w = w - (w % 8);
          setSettings('maxWidth', w);
        }} />
    </>
  )
}

export default Editor;