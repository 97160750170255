const settingsFields = {
  command: {
    caption: 'Command',
    input: 'textarea',
    init: '',
  },


  autoHeight: {
    caption: 'Height', input: 'options', init: true,
    options: [
      { caption: 'Fixed', key: false },
      { caption: 'Max', key: true }
    ]
  },

  height: {
    caption: (<>&nbsp;</>),
    input: 'number',
    init: null,
    valueOnNull: 200,
    unit: 'px',
    range: [50, 1200],
  },

  filename: {
    caption: 'Stdout as file',
    input: 'text',
    init: null,
    valueOnNull: '',
    validate: (
      val => typeof val === 'string' && val.length < 200 ?
        false :
        'Must be less than 200 characters.')
  },

  isCommon: {
    caption: 'Common Instance Across Documents',
    input: 'checkbox',
    init: false
  },

  wordWrap: {
    caption: 'Word Wrap',
    input: 'checkbox',
    init: true
  },  
}

export const settingsFieldsLayout = [
  [
    ['command'],
  ], [
    ['autoHeight', 'height'],
    ['wordWrap']
  ], [
    ['filename'],
    // ['isCommon'],
    ['isVisible'],
  ],
]

export const initialSettings = {
  pullFiles: []
};
for (let k in settingsFields) {
  initialSettings[k] = settingsFields[k].init;
}

export default settingsFields;