import { useRef, useEffect } from 'react';
import { Button } from 'react-aria-components';


export default function CommandInput({ controller }) {
  const
    { state, getSettingsValue, setSettings } = controller,
    processIsActive = state.get('processIsActive'),
    command = getSettingsValue('command');
    
  return (
    <form
      className="flex gap-2 px-4 border-b border-rim"
      onSubmit={e => { e.preventDefault(); controller.run(); }}>
      <div className="py-2 leading-normal font-code">$</div>
      <div className="command flex-grow font-code">
        <TextArea
          value={command}
          onEl={el => controller.setCommandInputEl(el)}
          onFocus={controller.activate}
          onChange={e => setSettings('command', e.currentTarget.value)} />
      </div>

      <div className="actions flex">
        {!processIsActive ?
          (<ProcButton type="submit" isDisabled={!command}>Run</ProcButton>) :
          (<ProcButton type="button" onPress={_ => controller.kill()}>Kill</ProcButton>)
        }
      </div>
    </form>
  );
}

function ProcButton(props) {
  return (<Button {...props} className="uppercase p-2 leading-normal font-code" />);
}

function TextArea({ value, onChange, onFocus, onEl, spellCheck }) {
  const inputRef = useRef();

  function resizeTextbox() {
    if (!inputRef.current)
      return;
    inputRef.current.style.height = 'auto';
    inputRef.current.style.height = inputRef.current.scrollHeight + 1 + 'px';
  }
  useEffect(resizeTextbox, [value]);

  return (
    <textarea
      className="outline-none resize-none bg-transparent w-full p-2 block"
      rows={1}
      ref={el => {
        inputRef.current = el;
        onEl(el);
      }}
      {...{ value, onFocus, onChange }}
      spellCheck={spellCheck || 'false'} />);
}