import BasePopupController from 'lib/popup/BasePopupController';
import View from './View';
import TerminalOutput from 'lib/TerminalOutput/Controller';  
import { Map } from 'immutable';


class Controller extends BasePopupController {
  static View = View;
  position = new Map({ width: 420 });  

  constructor(args, manager) {
    super(args, manager);
    this.machine = args.machine;
    this.docId = args.docId;
    this.docPath = args.docPath;
    this.terminalOutput = new TerminalOutput();
    this.terminalOutput.clear();

    this.state = this.state.merge({
      connected: false
    });

    const mi = this.machineInterface = this.machine.interface.getProxy({
      elementId: 'terminal',
      docId: this.docId,
      docPath: this.docPath
    });

    this.unloadFns.push(
      mi.subscribeDataStream(({ type, data }) => {
        this.terminalOutput.push(data, type === 'error');
      }),
      mi.subscribeProcessEvent(() => this.checkProcessIsActive()),
      mi.subscribeProcessIndex(() => this.checkProcessIsActive()),
      mi.subscribeConnectionStatus((status) => this.setState({ connectionStatus: status }))
    );
    this.checkProcessIsActive();
  }

  checkProcessIsActive() {
    const processIsActive = this.machineInterface.processIsActive();
    console.log('checking', processIsActive)
    if (processIsActive !== this.getState().get('processIsActive'))
      this.setState({ processIsActive });
  }


  handleProcess = ({ process, event, error }) => {
    // if (event === 'start') {
    //   this.clearFetchStatus();
    //   this.terminalOutput.clear();
    // } else if (event === 'error') {
    //   this.terminalOutput.processChunk(error, true);
    // } else if (event === 'close') {
    //   this.fetchFiles();
    // }
    // this.checkProcessIsActive();
  }

  run() {
    this.terminalOutput.clear();
    this.machineInterface.run({ command: 'bash' });
  }
  
  kill() {
    this.machineInterface.kill();
  }

  setTerminalInputEl = (el) => {
    this.terminalInputEl = el;
    if (el)
      el.focus();
  }
}

export default Controller;