import BaseController from 'lib/BaseController';
import {
  generate, refine, fetchGens, fetchGen, fetchGenVersion,
  saveGen, saveGenVersion, deleteGen
} from 'api/remote/calls/genai';
import { fromJS } from 'immutable';
import GenVersion from './GenVersion';
import CloneArticle from 'modules/folder/popups/CloneArticle/Controller';
import Prompt from 'lib/popup/popups/Prompt/Controller';
import { getDayStamp } from 'lib/tools.ts';

export const PROVIDERS = {
  'claude-3-7-sonnet': { caption: 'Anthropic (Claude 3.7 Sonnet)' },
  'gemini-20-flash': { caption: 'Gemini (Gemini-2.0 Flash)' },
  'gpt-4-turbo': { caption: 'OpenAI (GPT-4 Turbo)' },
  'deepseek-reasoner': { caption: 'DeepSeek (DeepSeek-R1)', isDisabled: true }
}


const initState = {
  index: null,
  nextCursor: null,
  feedbacks: {},
  mode: 'normal',
  gen: null,
  version: null,
  detailId: null,
  versionId: null,

  isLoadingIndex: false,
  isLoadingGen: false,
  isRefining: false,
  loadIndexError: false,
  loadGenError: false,
  generationError: false,
  refineError: false,
  provider: 'claude-3-7-sonnet'
}

export default class Page extends BaseController {
  constructor({ app }) {
    super();
    this.app = app;
    this.state = this.state.merge(fromJS(initState));
  }

  get userId() { return this.app.auth.user?.uid };
  get gen() { return this.state.get('gen'); }
  get version() { return this.state.get('version'); }
  get creditsLeft() {
    const userInfo = this.app.auth.state.get('userInfo');
    if (!userInfo)
      return null;
    let
      sameDay = userInfo.gaiDayStamp === getDayStamp(),
      count = (sameDay && userInfo.gaiDayCount) || 0;

    return Math.max((userInfo.gaiDailyLimit || 8) - count, 0);
  }
  get anonHasTried() { return window.localStorage.getItem('anonHasTriedGenai'); }
  setAnonHasTried() { window.localStorage.setItem('anonHasTriedGenai', 'yes'); }

  loadPage = ({ genId, versionId }) => (
    genId ? this.loadGen({ genId, versionId }) : this.loadIndex()
  );

  loadIndex = async () => {
    let { userId } = this;
    if (!userId)
      return;
    this.setState({ ...initState, isLoadingIndex: true, index: this.state.get('index') });

    try {
      const { data, nextCursor } = await fetchGens({ userId });
      this.setState({ index: data, nextCursor, isLoadingIndex: false });
    } catch (e) {
      console.error(e);
      this.setState({ isLoadingIndex: false, loadIndexError: e.message });
    }
  }

  genCache = new Map();
  versionCache = new Map();

  loadGen = async ({ genId, versionId }) => {
    this.setState({ isLoadingGen: true, loadGenError: null, feedbacks: {} });
    try {
      let
        gen = this.genCache.get(genId),
        { userId } = this;
      if (!gen) {
        gen = await fetchGen({ userId, genId });
        this.genCache.set(genId, gen);
      }

      let version = this.versionCache.get(versionId);
      if (!version) {
        let { id, elements, title } = await fetchGenVersion({ userId, genId, versionId });
        version = new GenVersion({
          id, elements,
          title: title || gen.title,
          app: this.app,
          expandDetail: this.expandDetail
        });
        this.versionCache.set(version.id, version);
      }
      console.log(version, versionId);
      
      this.setState({ isLoadingGen: false, gen, version });
    } catch (e) {
      console.error(e);
      this.setState({ isLoadingGen: false, loadGenError: e.message })
    }
  }

  expandDetail = (id) => this.setState({ detailId: id });

  fetchDemoGen = () => fetchGen({ genId: 'demo' });
  deleteDemoGen = () => {
    deleteGen({ genId: 'demo' });
    this.setState({ removed: true });
  }
  
  generate = async ({ prompt }) => {
    let { userId } = this;
    if (!userId)
      this.setAnonHasTried();
    
    this.setState({ isGenerating: true, generationError: false });
    try {
      if (!prompt)
        throw new Error('Please specify what you want.');

      const
        provider = this.state.get('provider'),
        response = (await generate({ prompt, provider })).data,
        text = response?.text;

      if (!text)
        throw new Error('Invalid response. Please try again.');
      
      // eslint-disable-next-line
      const object = Function(`return ${text}`)();
      const { elements, title } = object;

      const genId = await saveGen({ userId, data: { title, provider } });
      await saveGenVersion({ userId, genId, data: { elements, title } });
      this.setState({ isGenerating: false });
      return genId;
    } catch (e) {
      console.error(e);
      this.setState({ generationError: e.message, isGenerating: false });
    }
  }

  refine = async () => {
    let
      { userId, version, gen } = this,
      { rawElements, title } = version,
      feedbacks = this.state.get('feedbacks'),
      prompt = JSON.stringify({
        title: title,
        elements: rawElements,
        feedbacks: feedbacks
      }, null, 2);
    
    // console.log(prompt);
    this.setState({ isRefining: true, refineError: false });

    try {
      let isEmpty = true;
      for (let id in feedbacks) { isEmpty = isEmpty && !feedbacks[id]; }
      if (isEmpty)
        throw new Error('Please provide some feedback.')

      if (!userId)
        throw new Error('You must be logged in for this feature.');

      const
        provider = gen.provider || this.state.get('provider'),
        response = (await refine({ prompt, provider })).data,
        text = response?.text;

      if (!text)
        throw new Error('Invalid response. Please try again.');

      // eslint-disable-next-line
      let object = Function(`return ${text}`)();
      let { title, elements, log } = object;
      
      elements = elements.map(element => {
        if (typeof element === 'string')
          return rawElements.find(e => e.id === element);
        return element;
      });
      
      const versionId = await saveGenVersion({
        userId, genId: gen.id, data: { title, elements, log }
      });
      this.genCache.delete(gen.id);
      this.setState({ isRefining: false, mode: 'normal', versionId });
      return versionId;
    } catch (e) {
      this.setState({ isRefining: false, refineError: e.message });
    }
  }

  deleteGen = async ({ genId }) => new Promise((resolve, reject) => {
    this.app.popupManager.open(Prompt, {
      title: 'Delete Generation',
      question: 'Are you sure you want to delete this generated article?',
      submitLabel: 'Delete',

      onSubmit: async () => {
        const { userId } = this;
        await deleteGen({ userId, genId });
        let index = this.state.get('index').filter(gen => gen.id !== genId);
        this.setState({ index });
        resolve(true);
      },
      onUnload: () => {
        resolve(false);
      }
    });
  });

  clonePrompt = async () => {
    const
      { version, gen } = this,
      { article } = version.article;
    let elements = [];
    for (let id in version.elements) {
      elements.push({ id, ...version.elements[id] });
    }

    this.app.popupManager.open(CloneArticle, {
      app: this.app,
      title: article.title,
      slug: gen.id,
      sourceData: {
        article: article,
        elements: elements
      },
      hideInfo: true
    });
  }

  destroy = () => {
    super.destroy();
    this.genCache.clear();
    this.versionCache.clear();
  }
}