import { useState, useEffect } from 'react';
import { Break, Block, Label } from 'modules/elements/lib/sidebar/inputs/components';
import { Buttons, Button } from 'modules/elements/lib/sidebar/inputs/buttons';
import { TrashIcon } from '@radix-ui/react-icons';
import Filename from 'modules/elements/all/File/components/Filename';


export default function Files({ controller }) {
  const [children, setChildren] = useState(controller.getChildren());
  useEffect(() => controller.onChildren(() => setChildren(controller.getChildren)), [controller]);

  return (
    <>
      <Break />

      <Block>
        <Label>Pull Files on Complete</Label>
        <div className="pt-1 px-2 font-mono text-sm">
          { children.length === 0 && (
            <div className="text-sm opacity-50 py-1">None</div>
          ) }
          { children.map((child) => {
            return (
              <div key={child.elementId} className="flex items-center">
                <Button onPress={() => child.editFilenamePopup({ allowEmpty: false })}>
                  <Filename controller={child} />
                </Button>
                <div>
                  <Button onPress={() => child.deletePrompt(child)}>
                    <TrashIcon />
                  </Button>
                </div>
              </div>
            )
          }) }
        </div>

        <Buttons className="text-right">
          <Button onPress={e => controller.addChildFilePopup({ isPulled: true })}>Add</Button>
        </Buttons>
        {/*<TextInput />*/}
      </Block>
    </>
  )
}