import { useState, useEffect } from 'react';
import RemoteFiles from 'modules/article/controllers/files/RemoteFiles';
import processCode from 'lib/transpiler/processCode';
import processCSS from 'lib/transpiler/processCSS';
import WebFrame from '../webframe/Controller';
import WebFrameView from '../webframe/View';
import { defaultImportMap } from 'modules/elements/all/WebRunner/Policies/settingsFields';


function getWebFrame({ app, localPath }) {
  const
    remoteFiles = (new RemoteFiles({ getProvider: () => app.remoteProvider })).proxy,
    { vani } = app,
    bridge = null;

  const getRuntimeFile = async (pathname, destination) => {
    let docPath, file;

    if (pathname === `/${localPath}/` && destination === '') {
      return { filename: '', content: '', isBase64: false };
    }

    file = await remoteFiles.getFile(pathname);
    docPath = file.docPath;

    if (!file)
      throw new Error(`File not found "${pathname}" for "${destination}"`);

    if (destination === 'script' && file.isDocFile) {
      if (file.isScript) {
        const { fetchPath } = remoteFiles;
        return await processCode({ file, docPath, localPath, fetchPath });
      }

      if (file.isCSS) {
        return await processCSS({ file });
      }
    }
    return await file.toObject();
  }

  return new WebFrame({ vani, getRuntimeFile, bridge });
}
 

export default function View({ app, title='', target, localPath, importMap={}, height, autoHeight=false }) {
  const [ webframe, ] = useState(() => getWebFrame({ app, localPath }));

  useEffect(() => {
    let colorScheme = app.colorScheme.getSchemeValue();

    webframe.clear();
    webframe.setBuild({
      title,
      target,
      importMap: { ...importMap, ...defaultImportMap },
      colorScheme,
      localPath,
      runtime: 'standard',
      renderOption: 'default',
      classToRender: null,
      isEditMode: false,
      documentInstanceId: ''
    });
  }, [app, title, target, localPath, importMap, webframe]);

  return (
    <WebFrameView
      controller={webframe}
      height={height}
      autoHeight={autoHeight} />
  );
}