import { compile, stringify, serialize, middleware, prefixer } from 'stylis';


const processCSS = async ({ file }) => {
  // console.log(content);
  if (file.url) {
    return {
      file: file.filename,
      contentType: 'application/javascript',
      content: `
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = '${file.url}';
        document.head.appendChild(link);
      `
    }
  }
  
  let
    cssString = await file.getContent(),
    processedCSS = serialize(compile(cssString || ''), middleware([prefixer, stringify])),
    b = btoa(processedCSS);
  
  // Check if Base64
  cssString = file.isBase64 ? atob(cssString) : cssString;

  return {
    file: file.filename,
    contentType: 'application/javascript',
    content: `
      const styleTag = document.createElement('style');
      styleTag.innerHTML = atob('${b}');
      document.head.appendChild(styleTag);
    `,
    isBase64: false
  }
}

export default processCSS;