import { useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { Link } from 'react-aria-components';
import { useControllerState } from 'lib/BaseController';
import { ErrorBlock } from 'lib/staticPages';
import Loader from 'lib/staticPages/Loader';
import Article from './Article';
import Cover from './Cover';
import Logo from 'lib/Logo';
// import Container from 'modules/app/views/Home/Container';
import Styler from 'modules/theme/views/Styler';


export default function Page({ app }) {
  const
    { viewer } = app,
    match = useMatch({ path: '*' }),
    path = match.params['*'],
    state = useControllerState(viewer),
    type = state.get('type');

  useEffect(() => viewer.loadPath(path), [path, viewer]);
  useEffect(() => viewer.unload, [viewer]);

  if (state.get('error')) {
    return (
      <div className="px-8 pt-6">
        <Link className="inline-block" href="/"><Logo /></Link>                
        <ErrorBlock title="Loading Error" error={state.get('error')} className="pt-10" />
      </div>
    );
  }
  
  if (!type) {
    return (
      <div className="text-2xl text-center pt-10">
        <Loader />
      </div>
    )
  }

  return (
    <>
      <Styler themeData={viewer.workspace.themeData} baseClass="nb-viewer-theme" />
      <Article viewer={viewer} />
      <Cover viewer={viewer} />
    </>
  )
}