import { breakPath, assemblePath, combinePath, isSameDomain } from './fns';


export const gatherDependenciesPlugin = (babel) => {
  return {
    visitor: {
      ImportDeclaration(path, state) {
        let src = path.node.source.value;
        if (!isSameDomain(src))
          return;
        src = combinePath(state.opts.docPath, src);
        state.opts.dependencies.add(breakPath(src).docPath);
      },
    },
  };
};

const scriptFileRegex = /\.(js|jsx|ts|tsx|mjs|css)$/;
// const scriptFileRegex = /\.(js|jsx|ts|tsx|mjs|cjs|es6|es|mts|cts|json|css|scss|sass|less|vue|svelte)$/i;
const mediaFileRegex = /\.(png|jpe?g|gif|svg|webp|avif|bmp|ico|mp3|wav|ogg|flac|aac|mp4|webm|ogv|mov|avi|mkv|woff2?|ttf|eot|otf)$/;



export const modifyImportsPlugin = function ({ types: t }) {
  return {
    visitor: {
      ImportDeclaration(path, state) {
        let src = path.node.source.value;
        if (!isSameDomain(src))
          return;

        src = combinePath(state.opts.docPath, src);
        let { articlePath, versionSlug, filename } = breakPath(src);
        // console.log({ articlePath, versionSlug, dvMap: state.opts.dvMap })

        versionSlug = versionSlug || state.opts.dvMap[articlePath];
        src = assemblePath({ articlePath, versionSlug, filename });

        if (scriptFileRegex.test(src)) {
          console.log('import source map', path.node.source.value, '=>', src);
          path.node.source = t.stringLiteral(src);
        } else if (mediaFileRegex.test(src)) {
          // todo: maybe check if the file exists?
          path.replaceWith(
            t.variableDeclaration('const', [
              t.variableDeclarator(
                t.identifier(path.node.specifiers[0].local.name),
                t.stringLiteral(src)
              )
            ])
          );
        } else {
          throw new Error(`Invalid import path: "${src}".
Only script files (.js, .jsx, .ts, etc.) and media files (.png, .jpg, etc.) are supported.
For third-party libraries, please configure the runtime import map.`);
        }
      }
    }
  };
};