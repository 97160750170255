import { useRef } from 'react';
import Grid from './Grid';
import Scroll from './Scroll';
import clsx from 'clsx';
import Wrapper from './Wrapper';


export default function Article({ viewer }) {
  const { articles, state } = viewer;

  return (
    <Wrapper viewer={viewer}>
      {Object.keys(articles).map(key => (
        <Doc
          key={key}
          article={articles[key]}
          hidden={key !== state.get('articleId')}
          onScroll={viewer.onArticleScroll}
          panelLeft={state.get('panelLeft')}
          panelRight={state.get('panelRight')} />
      ))}
    </Wrapper>
  )
}

export function Doc({ article, hidden, onScroll, panelLeft, panelRight }) {
  const prevScrollTop = useRef(0);
  
  return (
    <div
      className={clsx(
        'nb-viewer-theme overflow-auto h-full w-full',
        hidden && 'hidden'
      )}
      onScroll={e => {
        const scrollTop = e.currentTarget.scrollTop;
        onScroll(scrollTop, scrollTop - prevScrollTop.current);
        prevScrollTop.current = e.currentTarget.scrollTop;
      }}>
      {
        article.layout.type === 'grid' ?
          (<Grid article={article} panelLeft={panelLeft} panelRight={panelRight} />):
          (<Scroll article={article} panelLeft={panelLeft} panelRight={panelRight} />)
      }
    </div>
  );
}