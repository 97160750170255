export default class BaseElementController {
  defaultSettings = {};

  constructor({
    elementId,
    getState,
    setState,
    onStateChange,
    getVersion,
    docId,
    docPath,
    themeData,
    
    getLocalFiles,
    getRemoteFiles,
    getMachineInterface,

    isReadOnly,
    save,
    activate,
    openPopup,
    vani,

    deleteSelf,
    addChildFilePopup,
    getChildren,
    onChildren,
    commonEmitter,
    
    getColorScheme,
    expandDetail
  }) {
    this.getState = getState;
    this.setState = setState;
    this.elementId = elementId;
    this.getVersion = getVersion;
    this.docId = docId;
    this.docPath = docPath;
    this.themeData = themeData;
    this.getLocalFiles = getLocalFiles;
    this.getRemoteFiles = getRemoteFiles;
    this.onStateChange = onStateChange;
    this.getMachineInterface = getMachineInterface;
    this.save = save;
    this.activate = activate;
    this.openPopup = openPopup;
    this.isReadOnly = isReadOnly;
    this.vani = vani;
    this.deleteSelf = deleteSelf;
    this.addChildFilePopup = addChildFilePopup;
    this.getChildren = getChildren;
    this.onChildren = onChildren;
    this.commonEmitter = commonEmitter;
    this.getColorScheme = getColorScheme;
    this.expandDetail = expandDetail;
  }

  // Settings stuff
  settingsFields = {};
  getInitialSettingsValue = (key) => (
    this.settingsFields[key] &&
    this.settingsFields[key].init
  );
  getThemeSettingsValue = (key) => (
    this.settingsFields[key] &&
    this.settingsFields[key].themeKey &&
    this.themeData.getValue(this.settingsFields[key].themeKey)
  );
  getSettingsValueOnNull = (key) => (
    (this.settingsFields[key] && this.settingsFields[key].valueOnNull) ||
    this.getThemeSettingsValue(key)
  );
  getSettingsData = (key) => this.getState().get('settings').get(key);

  getSettingsValue = (key) => {
    var val = this.getSettingsData(key);
    if (typeof val === 'undefined')
      val = this.getInitialSettingsValue(key);
    if (val === null)
      val = this.getSettingsValueOnNull(key);
    return val;
  }

  setSettings = (key, value) => {
    const settings = this.state.get('settings').set(key, value);
    this.setState({ settings });
  }

  toggleSettings = (key) => {
    this.setSettings(key, !this.getSettingsValue(key));
  }

  get state() { return this.getState() };
  get elementFilenames() { return null; }
  getElementFile(filename) { return null; }

  onChange(prev, next) {}
  
  destroy() {
    if (this.unloadFns)
      this.unloadFns.forEach(fn => fn());
  }
}