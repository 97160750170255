import { Link, Button } from 'react-aria-components';
import {
  KeyIcon, BookOpenIcon, CheckIcon, 
  // XMarkIcon,
  LockClosedIcon,
  // LockOpenIcon
} from '@heroicons/react/24/outline'
import Users from './Users';
import clsx from 'clsx';


export default function Home({ popup }) {
   let
      { state } = popup,
      { domain } = popup.args.articleController,
      domainSlug = domain.state.getIn(['domain', 'slug']),
      domainIsRestricted = true, // domain.state.getIn(['domain', 'isRestricted']),
      articleIsOpen = state.get('articleIsOpen'),
      linkClassName = 'font-medium text-marker3 outline-none',
      linkProps = { target: '_blank', className: linkClassName, href: `/users/${domainSlug}` };

  return (
    <div className="pt-4 pb-8 flex flex-col gap-1">
      <div className="p-2">
        <div className="flex gap-2">
          <div>
            <KeyIcon className="size-8 inline" />
          </div>
          <div className="font-medium">
            Who can edit this article?
          </div>
        </div>

        <div className="pl-4 mt-2">
          <div className="flex gap-2">
            <div><CheckIcon className="size-4 inline" /></div>
            <div>
              People with <Link {...linkProps}>
                edit access to this workspace
              </Link>
            </div>
          </div>
          <div className="flex gap-2">
            <div><CheckIcon className="size-4 inline" /></div>
            <div>
              Users added to edit this article:<br />
              <Users popup={popup} />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="p-2">
        <div className="flex gap-2">
          <div><BookOpenIcon className="size-8 inline" /></div>
          <div>
            <div className="font-medium">Who can view the published article?</div>
            <div className="text-sm">(published version and public versions)</div>
          </div>
        </div>

        <div className="pl-4 mt-4">
          <div className="flex gap-2">
            <div>
              <CheckIcon className="size-4 inline" />
            </div>
            <div className={clsx(domainIsRestricted && articleIsOpen && 'line-through')}>
              People who can <Link {...linkProps}>view published article in this workspace.</Link>&nbsp;
              { !domainIsRestricted ? (<>(currently everyone)</>) : <>
                (<LockClosedIcon className="size-4 inline" /> currently restricted to: listed users, editors and paid users.)
              </>}
            </div>
          </div>

          { domainIsRestricted && !articleIsOpen && (
            <div className="mt-4 pl-6">
              <Button className={linkClassName} onPress={popup.toggleArticleIsOpen}>
                Make this article public.
              </Button>
            </div>
          ) }

          { domainIsRestricted && articleIsOpen && (
            <>
              <div className="flex gap-2">
                <div>
                  <CheckIcon className="size-4 inline" />
                </div>
                Everyone
              </div>
              <div className="mt-4 pl-6">
                <Button className={linkClassName} onPress={popup.toggleArticleIsOpen}>
                  Remove unrestricted access.
                </Button>
              </div>
            </>
          ) }
        </div>
      </div>
    </div>
  )
}