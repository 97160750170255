import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';


export default function SimpleCarousel({ children, size }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);

  // Navigation functions
  const goToSlide = (index) => {
    if (index < 0) {
      index = size - 1;
    } else if (index >= size) {
      index = 0;
    }
    
    setCurrentIndex(index);
    
    if (carouselRef.current) {
      const scrollAmount = index * carouselRef.current.offsetWidth;
      carouselRef.current.scrollTo({
        left: scrollAmount,
        behavior: 'smooth'
      });
    }
  };
  
  // const goToPrevious = () => goToSlide(currentIndex - 1);
  // const goToNext = () => goToSlide(currentIndex + 1);
  
  // Handle manual scrolling
  useEffect(() => {
    if (!carouselRef.current) return;
    
    const handleScroll = () => {
      if (!carouselRef.current) return;
      
      // Debounce the scroll event
      clearTimeout(carouselRef.current.scrollTimeout);
      carouselRef.current.scrollTimeout = setTimeout(() => {
        const scrollPosition = carouselRef.current.scrollLeft;
        const slideWidth = carouselRef.current.offsetWidth;
        const newIndex = Math.round(scrollPosition / slideWidth);
        
        if (newIndex !== currentIndex) {
          setCurrentIndex(newIndex);
        }
      }, 100);
    };
    
    const carousel = carouselRef.current;
    carousel.addEventListener('scroll', handleScroll);
    
    return () => {
      if (carousel && carousel.scrollTimeout) {
        clearTimeout(carousel.scrollTimeout);
      }
      carousel.removeEventListener('scroll', handleScroll);
    };
  }, [currentIndex]);

  return (
    <div className="w-full">
      {/* Carousel container */}
      <div className="relative overflow-hidden">
        {/* Slides container */}
        <div 
          ref={carouselRef}
          className="relative flex w-full snap-x snap-mandatory scroll-smooth overflow-x-auto pb-4 gap-4"
          style={{ scrollbarWidth: 'none', WebkitOverflowScrolling: 'touch' }}>
          {children}
        </div>
        
        {/* Navigation buttons */}
        {/*<button
          onClick={goToPrevious}
          className="absolute left-2 top-1/2 -translate-y-1/2 bg-white bg-opacity-30 w-10 h-10 rounded-full flex items-center justify-center hover:bg-opacity-50 focus:outline-none focus:ring-2 focus:ring-white"
          aria-label="Previous slide"
        >←</button>
        <button 
          onClick={goToNext}
          className="absolute right-2 top-1/2 -translate-y-1/2 bg-white bg-opacity-30 w-10 h-10 rounded-full flex items-center justify-center hover:bg-opacity-50 focus:outline-none focus:ring-2 focus:ring-white"
          aria-label="Next slide"
        >→</button>*/}
      </div>
      
      {/* Indicators */}
      { size > 1 && (
        <div className="flex justify-center gap-2">
          {Array(size).fill(0).map((_, index) => (
            <button
              key={index}
              onClick={() => goToSlide(index)}
              className={clsx(
                'w-2 h-2 rounded-full transition-colors',
                index === currentIndex ? 'bg-marker' : 'bg-rim2'
              )}
              aria-label={`Go to slide ${index + 1}`} />
          ))}
        </div>
      ) }
    </div>
  );
};