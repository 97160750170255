import Logo from 'lib/Logo';
// import StorageImage from 'lib/StorageImage';

export default function DomainLogo({ viewer, className }) {
  // const
  //   { domain } = viewer.workspace,
  //   imageUrl = domain && domain.logo && domain.logo['320x320'];

  // if (imageUrl) {
  //   return (
  //     <div className="w-8 h-8">
  //       <StorageImage
  //         path={imageUrl}
  //         backgroundColor="none"
  //         getDownloadURL={viewer.provider.getDownloadURL} />
  //     </div>
  //   );
  // }

  return (<Logo className={className || 'w-6 h-6'} />)
}