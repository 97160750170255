import Caption from 'modules/elements/lib/components/Caption';
import Controls from './Controls';
import Entry from './Entry';
import clsx from 'clsx';


export function Gallery({ controller, children, isGrid }) {
  const
    { state, getSettingsValue } = controller,
    entries = state.get('entries'),
    at = state.get('at'),
    entry = entries.get(at);

  return (
    <div className={clsx('nb-gallery', isGrid && 'h-full flex flex-col' )}>
      <div className={clsx('image-container', isGrid && 'flex-grow')}>
        <Constrain controller={controller} isGrid={isGrid}>
          {entries.map((entry, i) => (
            <Entry
              key={i}
              entry={entry}
              controller={controller}
              show={(i === at)} />
          ))}
          <Controls controller={controller} />
          { children }
        </Constrain>
      </div>
      {entry && (
        <Caption
          caption={state.getIn(['entries', at, 'caption' ])}
          className="text-sm mt-2 mx-auto"
          controller={controller}
          width={getSettingsValue('percentWidth') + '%'}
          maxWidth={getSettingsValue('maxWidth') + 'px'}
          onPress={controller.isReadOnly ? undefined : (() => controller.editCaption(state.get('at')))}
          key={entry} /> )}
    </div>
  );
}

function Constrain({ children, controller, isGrid }) {
  const
    { getSettingsValue } = controller,
    aspectRatio = 100 * getSettingsValue('maxHeight') / getSettingsValue('maxWidth'),
    hasBorder = getSettingsValue('hasBorder'),
    styleW = { margin: 'auto' },
    styleH = { position: 'relative' };

    if (isGrid) {
      styleW.height = '100%';
      styleH.height = '100%';
    } else {
      styleW.maxWidth = getSettingsValue('maxWidth') + 'px';
      styleW.width = getSettingsValue('percentWidth') + '%';
      styleH.paddingTop = aspectRatio + '%'; // (100 / getSettingsValue('aspectRatio')) + '%',
    }

  return (
    <div className={clsx('constrain-w', hasBorder && 'border border-rim p-1 bg-paper')} style={styleW}>
      <div className={clsx('constrain-h', hasBorder && '')} style={styleH}>
        {children}
      </div>
    </div>
  );
}

export default Gallery;