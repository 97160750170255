import BaseController from 'lib/BaseController';
import { List, Set } from 'immutable';


class Controller extends BaseController {
  constructor({ vani, getRuntimeFile, bridge }) {
    super();
    this.vani = vani;
    this.getRuntimeFile = getRuntimeFile;
    this.bridge = bridge;

    this.state = this.state.merge({
      build: null,
      buildVersion: 0,
      errored: false,
      errorTitle: '',
      errorMessage: '',
      loading: false,
      logInputValue: '',
      logs: List([]),
      
      showConsole: false,
      consoleShownOnBuild: null,
      hasNewLogs: false,
      hasNewErrors: false,
      clientHeight: 100,
      loadingFiles: Set()
    });

    if (vani) {
      this.stopVaniListener = vani.on('for webframe', this.handleVaniMessage);
    }
  }

  setPostMessageFn(fn) {
    this._postMessageFn = fn;
    this.postMessage('ready');
  }

  postMessage(subject, payload) {
    if (this._postMessageFn) {
      this._postMessageFn({ from: 'doc', subject, payload });
    }
  }

  isReady() {
    return !!this._postMessageFn;
  }

  handleMessage(message) {
    // console.log('incoming doc message', message);
    const { subject, payload } = message;

    if (subject === 'get build') {
      this.postMessage('build', this.state.get('build'));

    } if (subject === 'fetch file') {
      this.handleFetchFile(payload)

    } else if (subject === 'resize') {
      const { height } = payload;
      if (Math.abs(this.state.get('clientHeight') - height) > 2)
        this.setState({ clientHeight: Math.min(Math.max(height, 10), 800) });
    } else if (
      subject === 'eval-out' || subject === 'eval-out-error' ||
      subject === 'log' || subject === 'warn' || subject === 'error'
    ) {
      this.pushLog(subject, payload);

    } else if (subject === 'vani') {
      this.vani.handleWebframeMessage(payload);

    } else if (subject === 'request') {
      this.handleRequest(payload);

    } else if (this.bridge) {
      this.bridge.handleMessage(message);
    }
  }

  handleRequest = ({ subject, data, requestId }) => {
    const respond = (o={}) => this.postMessage('response', { requestId, ...o });

    // Get file for webrunner
    if (subject === 'get file') {

      this.getRuntimeFile(data.pathname, data.destination)
        .then(file =>  respond({ file }))
        .catch(error => respond({ error: error.message }))
    } else if (this.bridge) {
      this.bridge.handleRequest({ subject, data, respond });
    }
  }

  handleFetchFile = ({ pathname, sameOrigin, url, status }) => {
    const key = sameOrigin ? pathname : url;
    if (status === 'start') {
      this.setState({ loadingFiles: this.state.get('loadingFiles').add(key) });
    } else {
      this.setState({ loadingFiles: this.state.get('loadingFiles').delete(key) });
    }
  }

  handleVaniMessage = (payload) => {
    this.postMessage('vani', payload);
  }

  clear() {
    this.setState({
      errored: false,
      errorTitle: '',
      errorMessage: '',
      hasNewLogs: false,
      hasNewErrors: false,
      logs: List([])
    });
  }

  setBuild(build) {
    this.setState({
      build: build,
      buildVersion: this.state.get('buildVersion') + 1,
      loadingFiles: new Set()
    });
  }

  setError({ title, message }) {
    this.setState({
      errored: true,
      errorTitle: title,
      errorMessage: message
    });
  }
  
  pushLog(type, args) {
    const
      { state } = this,
      logs = state.get('logs').push({ type, args });

    if (!state.get('showConsole')) {
      if (state.get('consoleShownOnBuild') !== state.get('buildVersion')) {
          this.setState({ showConsole: true, consoleShownOnBuild: state.get('buildVersion') });
      } else {
        if (!state.get('hasNewLogs') && type === 'log')
          this.setState({ hasNewLogs: true });
        if (!state.get('hasNewErrors') && (type === 'error' || type === 'eval-out-error'))
          this.setState({ hasNewErrors: true });
      }
    }
    
    this.setState({ logs });
  }

  clearLogs() {
    this.setState({ logs: List([]) });    
  }

  toggleConsole() {
    this.setState({
      showConsole: !this.state.get('showConsole'),
      hasNewErrors: false,
      hasNewLogs: false
    })
  }
  
  submitCommand() {
    const v = this.state.get('logInputValue');
    if (!v)
      return;

    this.setState({ logInputValue: '' });
    
    if (!this.isReady()) {
      this.pushLog('no-eval', ['Web instance is not running']);
      return;
    }
    this.pushLog('eval-in', [v]);
    this.postMessage('eval', v);
  }

  destroy() {
    console.log('destryoing webframe');
    if (this.stopVaniListener) {
      this.stopVaniListener();
    }
    super.destroy();
  }
}

export default Controller;