export default function isFileAccepted(filename, mimeType, acceptString) {
  if (!acceptString) return true;
  
  const accepts = acceptString.split(',').map(x => x.trim().toLowerCase());
  
  for (const accept of accepts) {
    // Handle MIME type wildcards (e.g., "image/*")
    if (accept.includes('/*')) {
      const acceptType = accept.split('/')[0];
      const fileType = mimeType.split('/')[0];
      if (acceptType === fileType) return true;
    }
    
    // Handle specific MIME types
    if (accept.includes('/')) {
      if (mimeType === accept) return true;
    }
    
    // Handle file extensions
    if (accept.startsWith('.')) {
      if (filename.toLowerCase().endsWith(accept)) return true;
    }
  }
  
  return false;
}