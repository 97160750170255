import { Map } from 'immutable';
import BaseElementController from 'modules/elements/lib/BaseElementController';
import { fields } from '../Policies/settings';


export default class Controller extends BaseElementController {
  settingsFields = fields;

  constructor(args) {
    super(args);
    this.commonEmitter.on('files update', () => this.setState({ uploadsUpdatedAt: new Date() }));
  }

  get at() { return this.state.get('at'); }
  get entries() { return this.state.get('entries'); }
  
  addEntry = o => {
    this.setState({
      entries: this.state.get('entries').push(new Map(o || ({ caption: '', filename: '' }))),
      at: this.state.get('entries').size
    })
  }

  deleteEntry = (i) => {
    let
      entries = this.state.get('entries').delete(i),
      at = Math.min(this.state.get('at'), entries.size - 1);
    if (entries.size === 0) {
      entries = entries.push(new Map({ caption: '', filename: '' }));
      at = 0
    }
    this.setState({ entries: entries, at: at });
  }

  deleteCurrentEntry = () => {
    this.deleteEntry(this.state.get('at'));
  }

  updateEntry = (i, k, v) => {
    const
      entry = this.state.get('entries').get(i).set(k, v),
      entries = this.state.get('entries').set(i, entry);
    this.setState({ entries });
  }

  moveEntry = (i, dir) => {
    var entries, entry;
    entries = this.state.get('entries');
    entry = entries.get(i);
    entries = entries.delete(i).insert(i + dir, entry);
    this.setState({ entries, at: i + dir });
  }

  openAddNewImage = () => {}

  editCaption = (i) => {
    const caption = this.state.getIn(['entries', i, 'caption']);
    this.openPopup('rich-text-editor', {
      title: 'Edit Caption',
      format: 'html',
      text: caption || '',
      onSave: text => this.updateEntry(i, 'caption', text)
    });
  }

  uploadMediaFilePopup = () => {
    this.openPopup('upload-file',{
      accept: 'image/*', // 'audio/*,video/*,image/*',
      title: 'Upload an Image',
      onDone: filename => this.addEntry({ filename, caption: '' })
    });
  }

  deleteFrameConfirm = (i) => {
    this.deleteEntry(i);
  }

  selectImagePopup = () => {
    const at = this.state.get('at');
    this.openPopup('upload-files', {
      accept: 'image/*', // 'audio/*,video/*,image/*',
      title: 'Select an Image',
      allowPreview: true,
      onSelect: filename => console.log(filename) || this.updateEntry(at, 'filename', filename)
    });
  }

  setHiddenInputRef = (ref) => { this._hiddenInput = ref; }
  focus = () => { this._hiddenInput.focus();  }
  focusInput = () => { this._hiddenInput.focus(); }
  handleBlur = () => {}
  handleFocus = () => { this.activate(); }
  onKeyDown = (e) => {
    if (e.code === 'ArrowLeft') {
      this.setState({ at: Math.max(this.at - 1, 0) });
    }
    else if (e.code === 'ArrowRight') {
      this.setState({ at: Math.min(this.at + 1, this.entries.size - 1) });
    }
  }
}