import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-aria-components';
import { Button as AriaButton } from 'react-aria-components';
import { MagicWandIcon, TrashIcon, DotFilledIcon, Cross2Icon } from '@radix-ui/react-icons';

import { TextAreaInput } from 'lib/inputs/basic';
import { Button } from 'lib/inputs/buttons';
import Loader, { Timer } from 'lib/staticPages/Loader';
import { Frame } from 'modules/app/views/Home/misc/components';
import { PROVIDERS } from '../controllers/Genai';


export default function Page({ app }) {
  const
    controller = app.genai,
    { userId } = controller;

  return (
    <Frame className="max-w-[44rem] h-full overflow-hidden flex flex-col p-8">
      <SearchBar controller={controller} />

      <hr />
      {userId && (
        <GenIndex controller={controller} />
      )}

      {!userId && (
        <AnonIndex controller={controller} />
      )}
    </Frame>
  );
}

function SearchBar({ controller }) {
  const
    [prompt, setPrompt] = useState(''),
    { state, creditsLeft, userId, anonHasTried } = controller,
    noCredit = (!userId && anonHasTried) || (creditsLeft === 0),
    navigate = useNavigate();

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        controller.generate({ prompt }).then((genId) => {
          if (genId)
            navigate(`/genai/${genId}`);
        })
      }}>

      <div className="flex items-top">
        <div className="text-2xl font-medium mb-5 ml-2">Gen AI Playground</div>
        <div className="flex-grow"></div>
        <div className="pt-1">
          <select
            className="outline-none pr-1 text-sm bg-transparent"
            value={state.get('provider')}
            onChange={e => controller.setState({ provider: e.target.value })}>
            { Object.keys(PROVIDERS).map(k => (
              <option value={k} key={k} disabled={PROVIDERS[k]?.isDisabled}>
                {PROVIDERS[k]?.caption}
              </option>
            )) }
          </select>
        </div>
      </div>


      <div className="mx-2 mb-2">
        Use the power of Generative AI to create article and apps, fine tune them, 
        and move them to your workspace.
      </div>

      <TextAreaInput
        value={prompt}
        onChange={setPrompt}
        isDisabled={state.get('isGenerating') || noCredit}
        placeholder="eg. Write me an app to check if a number is prime." />

      <div className="flex">
        { state.get('generationError') ? (
          <div className="pl-2 text-error text-center text-sm flex items-center gap-2">
            Error: { state.get('generationError') }
            <AriaButton
              onPress={() => controller.setState({ generationError: null })}>
              <Cross2Icon />
            </AriaButton>
          </div>
        ) : state.get('isGenerating') ? (
          <div className="p-2 text-sm">
            Generating article, Please stay put. <Timer />s / ~30s
          </div>
        ) : !userId ? (
          <div className="p-2">
            { !anonHasTried ? (<>
              You have 1 demo credit.
            </>) : <>
              No credit left.
            </> }
          </div>
        )  : (
          <div className="p-2 pt-3 text-sm">
            { creditsLeft === null ? '' : `${creditsLeft} credit${creditsLeft > 0 ? 's' : ''} left` } 
          </div>
        ) }

        <div className="flex-grow"></div>

        <Button
          type="submit"
          isDisabled={state.get('isGenerating') || noCredit}>
          { state.get('isGenerating') ? (
            <>Generating <Loader /></>
          ) : (
            <><MagicWandIcon className="inline" />&nbsp; Generate</>
          ) }
        </Button>
      </div>
    </form>
  );
}

function GenIndex({ controller }) {
  const { state } = controller;

  return (
    <>
      { state.get('loadIndexError') ? (
        <div className="text-error text-center">
          Loading Error, Something went wrong.
        </div>
      ) : state.get('index') === null ? (
        <div className="text-center">
          <Loader className="text-xl" />
        </div>
      ) : (
        <>
          <div className="text-lg font-medium mb-2">
            Recently Generated
          </div>

          <div className="overflow-auto flex-grow">
            { state.get('index').length === 0 && (
              <div className="opacity-50 italic">Nothing generated yet.</div>
            ) }
            { state.get('index').map((gen, i) => (
              <div key={gen.id} className="group flex pr-2">
                <DotFilledIcon className="inline mt-1 mr-2" />
                <Link className="flex-grow mb-2 outline-none" href={`/genai/${gen.id}`}>{gen.title}</Link>
                <DeleteButton controller={controller} genId={gen.id} />
              </div>
            ))}

            { state.get('cursor') && (
              <Button>more</Button>
            )}
          </div>            
        </>
      )}
    </>
  )
}

function AnonIndex({ controller }) {
  const [demoGen, setDemoGen] = useState();
  useEffect(() => {
    controller.fetchDemoGen().then(setDemoGen).catch(e => {})
  }, [controller]);

  return (
    <div className="flex-grow pt-2">
      <div className="text-center">
        Please <Link className="underline font-medium" href="/auth/login">
          Login
        </Link> or <Link href="/auth/signup" className="underline font-medium">
          Sign Up
        </Link> to get free daily credits.
      </div>
      {demoGen && (
        <div className="text-center">
          <div className="mt-4 inline-flex items-center gap-2 group">
            Recently Created: 
            <Link href="/genai/demo" className="font-medium">
              {demoGen.title}
            </Link>

            <AriaButton
              className="outline-none"
              onPress={() => {controller.deleteDemoGen()}}>
              <TrashIcon />
            </AriaButton>          
          </div>
        </div>
      )}
    </div>    
  )
}

function DeleteButton({ controller, genId }) {
  const
    [ deleting, setDeleting ] = useState(false),
    [ errored, setErrored ] = useState(false);

  async function handleDelete() {
    try {
      setDeleting(true);
      await controller.deleteGen({ genId });
    } catch (e) {
      setErrored(true);
    }
    setDeleting(false);
  }

  if (errored)
    return (<div className="text-error">Error deleting.</div>)

  return (
    <AriaButton
      className={deleting ? 'opacity-50': 'opacity-0 group-hover:opacity-100'}
      onPress={handleDelete}
      isDisabled={deleting}>
      <TrashIcon />
    </AriaButton>
  );
}