import { Map, fromJS } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import View from './View';
import { toHuman } from 'lib/firebaseError';
import Prompt from 'lib/popup/popups/Prompt/Controller';


class ArticleAccessController extends BasePopupController {
  static View = View;
  position = new Map({ width: 420 });

  constructor(args, manager) {
    super(args, manager);
    this.article = args.articleController.state.get('article');
    this.provider = args.articleController.provider;
    this.articleId = this.article.get('id');
    this.domainId = this.article.get('domainId');

    this.state = this.state.merge({
      users: null,
      loading: false,
      isAdding: false,
      error: false,
      articleIsOpen: this.article.get('isOpen')
    });

    this.unloadFns.push(this.provider.onArticleAccesses(
      this.domainId, this.articleId, this.handleData, this.handleError
    ));
  }

  handleData = (data) => {
    data.sort((b, a) => ((a.id > b.id) ? -1 : (a.id < b.id) ? 1 : 0));
    this.setState({ users: fromJS(data), loading: false });
  }

  handleError = (e) => {
    console.error(e);
    this.setState({ error: true });
  }

  addAccess = async ({ email }) => {
    const access = 'rw';

    await this.tryFn(() => this.provider.updateArticleAccess({
      domainId: this.domainId,
      articleId: this.articleId,
      email,
      updateData: { access }
    }));

    if (!this.state.get('alert'))
      this.setState({ email: '', access: 'r' });
  }

  updateAccess = ({ email, access }) => {
    console.log(email, access);
    this.tryFn(() => this.provider.updateArticleAccess({
      domainId: this.domainId,
      articleId: this.articleId,
      email,
      updateData: { access }
    }));
  }

  deleteAccess = ({ email }) => {
    this.tryFn(() => this.provider.deleteArticleAccess({
      domainId: this.domainId,
      articleId: this.articleId,
      email: email
    }));
  }

  tryFn = async (fn) => {
    this.setState({ loading: true });
    try {
      await fn();
    } catch (e) {
      console.error(e);
      this.setState({ error: toHuman(e) });
    } finally {
      this.setState({ loading: false });      
    }
  }

  toggleArticleIsOpen = () => {
    const articleIsOpen = this.state.get('articleIsOpen');

    this.manager.open(Prompt, {
      title: 'Article Access',
      question: (
        articleIsOpen ?
          'Not everyone be able to view this article when published. Proceed?' :
          'Everyone will be able to view this article when published. Proceed?'
      ),
      onSubmit: async () => {
        this.setState({ articleIsOpen: !articleIsOpen });
        await this.args.articleController.setInState(['article', 'isOpen'], !articleIsOpen);
        await this.args.articleController.saveArticleStateKey('isOpen');
      },
      submitLabel: 'Yes'
    }, this.sessionId);
  }
}

export default ArticleAccessController;