import settingsFields, { settingsFieldsLayout } from '../Policies/settingsFields';
import { BuildInputs } from 'modules/elements/lib/sidebar/builder';
import Files from './Files';
import Env from './Env';

import './style.css';

function Inspector({ controller, state }) {
  const { getSettingsData, setSettings, getSettingsValue, getSettingsValueOnNull } = controller;

  return (
    <>
      <BuildInputs
        fields={settingsFields}
        layout={settingsFieldsLayout}
        getData={getSettingsData}
        getValue={getSettingsValue}
        setData={setSettings}
        getValueOnNull={getSettingsValueOnNull} />

      <Files controller={controller} />
      <Env controller={controller} />
    </>
  );
}

export default Inspector;