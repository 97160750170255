import './Stars.css';

export default function Stars() {
  return (
    <div className="stars-box">
      <div className="stars"></div>
      <div className="stars2"></div>
      <div className="stars3"></div>
    </div>
  )
}