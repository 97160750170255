import { useState, useEffect } from 'react';
import { Link } from 'react-aria-components';
import { ThickArrowLeftIcon } from '@radix-ui/react-icons';

import { fetchDemo } from 'api/public';
import { ErrorBlock } from 'lib/staticPages';
import { Frame } from '../misc/components';
import Loader from 'lib/staticPages/Loader';
import DemoRunner from './DemoRunner';

export default function ItemPage({ app, demoId, isAdmin }) {
  const [demo, setDemo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDemo(demoId)
      .then(demo => setDemo(demo))
      .catch(error => setError(error));
  }, [demoId]);

  return (
    <Frame className="demo-item-page max-w-[54rem] flex flex-col">
      <DemoPage {...{ app, demo, error }} />
    </Frame>
  )
}

function DemoPage({ app, demo, error }) {
  if (error)
    return (<ErrorBlock title="Demo Load Error" error={error} />);

  if (!demo)
    return (<div className="text-center text-2xl pt-16"><Loader /></div>);

  return (
    <>
      <div className="flex gap-4 items-center p-4 border-b">
        <Link className=" outline-none inline-block" href="/demos">
          <ThickArrowLeftIcon className="h-5 w-5" />
        </Link>
        <div className="text-xl text-center pr-14">
          Demo
        </div>        
      </div>

      <div className="grow flex gap-6 max-md:flex-col p-4">
        <div className="w-[440px] shrink-0 max-md:m-auto">
          <div className="overflow-hidden p-2 border border-rim">
            <DemoRunner demo={demo} app={app} />
          </div>
        </div>

        <div className="flex-grow p-2 max-md:px-4">
          <div className="text-3xl text-center mb-8">
            {demo.title}
          </div>

          <div
            className="body"
            ref={el => {
              if (el)
                el.innerHTML = demo.longDesc || '';
            }}>
          </div>
        </div>
      </div>
    </>
  )
}