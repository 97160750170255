import {
  collection, query, getDocs, doc, getDoc, setDoc,
  updateDoc, addDoc, deleteDoc, // limit, serverTimestamp,
  deleteField } from 'firebase/firestore';

import { db } from './remote/firebase';

window.migratePath = async (fn) => {
  const qss = await getDocs(query(collection(db, 'paths')));
  for (var pathDoc of qss.docs) {
    const d = pathDoc.data()
    if (d.type === 'folder' && d.itemId) {
      const { domainId } = (await getDoc(doc(db, 'folders', d.itemId))).data();
      console.log(pathDoc.id);
      await setDoc(pathDoc.ref, { domainId, type: d.type, folderId: d.itemId });
    }

    if (d.type === 'article' && d.itemId) {
      const { domainId } = (await getDoc(doc(db, 'articles', d.itemId))).data();
      const { itemId, publishedVersionId, publishedVersionSlug } = d;
      console.log(pathDoc.id);
      await setDoc(pathDoc.ref, {
        domainId, type: d.type, articleId: itemId,
        publishedVersionId: publishedVersionId || null,
        publishedVersionSlug: publishedVersionSlug || null
      });
    }

    if (d.type === 'version' && d.itemId) {
      const { articleId, itemId, dvMap } = d;
      const { domainId } = (await getDoc(doc(db, 'articles', articleId, 'versions', d.itemId))).data();
      console.log(pathDoc.id);
      await setDoc(pathDoc.ref, { domainId, type: d.type, versionId: itemId, articleId, dvMap: dvMap || null });
    }
  }
}

window.migrateCode = async (fn) => {
  const qss = await getDocs(query(collection(db, 'articles')));
  for (var article of qss.docs) {
    const eqss = await getDocs(query(collection(db, 'articles', article.id, 'elements')));
    for (let ss of eqss.docs) {
      await fn(ss, article.id);
    }
  }
}

window.migrateCodeElement = async (ss, articleId) => {
  const data = ss.data();
  if (ss.data().type !== 'code')
    return;

  let order = [];
  for (let id of data.order) {
    let
      filename = data.filenames[id],
      content = data.contents[id],
      mode = data.modes[id],
      parentId = ss.id,
      tabMode = '2s',
      isBase64 = false,
      isPulled = false,
      contentType = '';
    
    let file = {
      settings: { filename, mode, isBase64, isPulled, tabMode, contentType },
      parentId,
      content,
      type: 'file'
    }
    console.log('file', file);
    const docRef = await addDoc(collection(db, 'articles', articleId, 'elements'), file);
    order.push(docRef.id);
  };

  let ds = data.settings;

  let newData = {
    settings: {
      theme: ds.theme,
      autoHeight: ds.autoHeight,
      height: ds.height,
      hasLineNumbers: ds.hasLineNumbers,
      border: ds.border || null,
      borderColor: ds.borderColor,
      lineWrapping: ds.lineWrapping,
      isVisible: ds.isVisible,
      isReadOnly: ds.isReadOnly,
      filebarLayout: ds.filebarLayout || 0,
      verticalFileBarWidth: ds.verticalFileBarWidth || null,
    },
    contents: deleteField(),
    filenames: deleteField(),
    ids: deleteField(),
    modes: deleteField(),
    order: order,
    type: 'code2',
  }
  // console.log('orig', data);
  console.log('new', newData);
  await updateDoc(ss.ref, newData);
}

window.changeCodeType = async (ss, articleId) => {
  if (ss.data().type !== 'code2')
    return;
  await updateDoc(ss.ref, { type: 'code' });
}


window.migrateFolders = async (fn) => {
  const qss = await getDocs(query(collection(db, 'folders')));
  
  for (var pathDoc of qss.docs) {
    const
      d = pathDoc.data(),
      { domainId } = d;
      console.log(d.title);
      await setDoc(doc(db, 'domains', domainId, 'folders', pathDoc.id), d);
  }
}

window.migrateArticles = async (fn) => {
  const qss = await getDocs(query(collection(db, 'articles')));

  for (var pathDoc of qss.docs) {
    const
      d = pathDoc.data(),
      { domainId } = d;
      console.log(d.title);
      await setDoc(doc(db, 'domains', domainId, 'articles', pathDoc.id), d);
  }
}

window.migrateArticlesElements = async (fn) => {
  const articles = await getDocs(query(collection(db, 'articles')));
  for (var a of articles.docs) {
    console.log(a.data().title);
    const elements = await getDocs(query(collection(db, 'articles', a.id, 'elements')));
    for (var e of elements.docs) {
      var data = e.data();
      await setDoc(doc(db, 'domains', a.data().domainId, 'articles', a.id, 'elements', e.id), data);
    }
  }
}

window.deleteVersionPaths = async (fn) => {
  const qss = await getDocs(query(collection(db, 'paths')));
  for (var pathDoc of qss.docs) {
    const d = pathDoc.data();
    if (d.type === 'version'){
      console.log(pathDoc.id);
      await deleteDoc(pathDoc.ref);
    }
  }
}


window.forEveryFolder = async (cb) => {
  const qss = await getDocs(query(collection(db, 'domains')));
  for (var domain of qss.docs) {
    const folders = await getDocs(query(collection(db, 'domains', domain.id, 'folders')));
    for (let folder of folders.docs) {
      cb(folder, domain);
    }
  }  
}

// window.deleteFolderField = () => {
//   window.forEveryFolder(async (folder, domain) => {
//     let data = folder.data();
//     if (typeof data.t160 !== 'undefined') {
//       console.log('updating...')
//       await updateDoc(folder.ref, {
//         t160 : deleteField(), t320 : deleteField(), t640 : deleteField(),
//       });
//     }
//   });  
// };