import { Fragment, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useControllerState } from 'lib/BaseController';
import { AddUnderlay, AddHoverBar } from './addOverlays';
import { Button } from 'react-aria-components';
import ElementMenu from './ElementMenu';

import clsx from 'clsx';
import './style.css';


export default function Scroll({ titleElement, renderedElements, hidden, articleController }) {

  const
    { layout, selection } = articleController,
    { activeElementId, isPageFocused, selectedElements } = selection,
    { scroll } = layout,
    state = useControllerState(scroll),
    adding = state.get('adding');

  const handleMouseDownOnPage = e => {
    if (e.target === e.currentTarget) {
      selection.focusPage();
      e.preventDefault();
    }
  }

  const onDragEnd = ({ source, destination }) => {
    if (destination && source.index !== destination.index) {
      layout.moveElementIndex(source.index, destination.index);
    }
  }

  // const
  //   { pointSize, themeData } = layout,
  //   pageMargin = themeData.getValue('pageMargin'),
  //   pagePaddingStyle = {
  //     paddingLeft: pageMargin.left * pointSize,
  //     paddingRight: pageMargin.right * pointSize,
  //     paddingTop: pageMargin.top * pointSize,
  //     paddingBottom: pageMargin.bottom * pointSize
  //   };

  return (
    <div
      className={clsx(
        hidden && 'hidden',
        'px-12 pt-12 pb-[8rem] w-full h-full overflow-auto transition-[padding]',
        'md:group-[.has-sidebar-sm]:pr-[21rem]', // 18 + 3
        'md:group-[.has-sidebar-lg]:pr-[43rem]' // 40 + 3
      )}
      onMouseDown={handleMouseDownOnPage}>
      <div
        className={clsx(
          'relative page-max-width mx-auto bg-paper2',
          // 'border border-marker border-opacity-10'
        )}
        // style={pagePaddingStyle}
        onMouseDown={handleMouseDownOnPage}>
        <div ref={ref => layout.setElementContainerRef('titlebar', ref)}>
          <TitleElement
            isActive={activeElementId === 'titlebar'}
            isSelected={isPageFocused && selectedElements.has('titlebar')}
            layout={layout}
            titleElement={titleElement} />
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                { layout.order.map((id, index) => renderedElements[id] && (
                  <Fragment key={id}>
                    <DraggableElement
                      id={id}
                      index={index}
                      isLast={index === layout.order.size - 1}
                      containerRef={ref => layout.setElementContainerRef(id, ref)}
                      isActive={(activeElementId === id)}
                      isSelected={isPageFocused && selectedElements.has(id)}
                      selectMe={() => selection.setActiveElementId(id)}
                      renderedElement={renderedElements[id]}
                      articleController={articleController} />
                  </Fragment>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <AddHoverBar layout={layout} />
        { adding && (<AddUnderlay scroll={scroll} index={layout.order.length} />) }
        { layout.order.size === 0 && (<Placeholder articleController={articleController} />) }
      </div>
    </div>
  )
}

function TitleElement({ titleElement, isActive, isSelected, articleController }) {
  return (
    <div className="relative mb-6 pt-1">
      <Highlighter
        isActive={isActive}
        isSelected={isSelected}
        isTitle={true}
        articleController={articleController}
        className="pointer-events-none" />
      {titleElement}
    </div>
  );
}

function DraggableElement({
  id, index, renderedElement, isActive, isSelected, isLast,
  containerRef, selectMe, articleController
}) {
  return (
    <Draggable draggableId={id} index={index}>
      {provided => (
        <div {...provided.draggableProps} style={provided.draggableProps.style}>
          <div
            className={clsx(index === 0 ? 'mb-8' : isLast ? 'mt-8' : 'my-8', 'relative')}
            ref={provided.innerRef}
            onMouseDown={selectMe}>
            <Highlighter
              isActive={isActive}
              isSelected={isSelected}
              dragHandleProps={provided.dragHandleProps}
              isTitle={false}
              id={id}
              articleController={articleController}
              tabIndex={-1} />

            <div ref={containerRef} className="scroll-layout-element-container">
              { renderedElement }
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}

function Highlighter({ dragHandleProps={}, id, isActive, isSelected, isTitle, articleController }) {
  const [isOpen, setIsOpen] = useState();
  isActive = isActive || isOpen;

  return (
    <div
      className={clsx(
        'absolute h-full -left-10 flex gap-1 w-5 text-right',
        // !isTitle && 'pointer-events-none'
          (isActive || isSelected) && 'border-r',
          isActive ? 'border-marker' : 'border-pencil3'        
      )}
      {...dragHandleProps}>
      
      <div
        className={clsx(
          isActive ? 'text-marker': '',
          (isTitle || (!isActive && !isSelected)) && 'hidden'
        )}>
        <ElementMenu
          isOpen={isOpen}
          onOpenChange={setIsOpen}
          id={id}
          articleController={articleController} />        
      </div>
    </div>
  )
}

function Placeholder({ articleController }) {
  return (
    <div className="text-center opacity-40 text-sm">
      This article is empty. Start by&nbsp;
      <Button
        className="underline outline-none"
        onPress={() => articleController.layout.scroll.addElementAt(0)}>
        adding elements
      </Button>
    </div>
  )
}