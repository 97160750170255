import RenameSlug from '../../popups/RenameSlug/Controller';
import MoveToFolder from '../../popups/MoveToFolder/Controller';
import DeleteArticle from '../../popups/DeleteArticle/Controller';
import CloneArticle from '../../popups/CloneArticle/Controller';

import {
  OpenInNewWindowIcon, CursorTextIcon, Pencil2Icon,
  ImageIcon, Pencil1Icon, MoveIcon , CopyIcon, TrashIcon
} from '@radix-ui/react-icons';

export function getArticleOptions({ controller, article }) {
  const { popups, popupManager, provider } = controller;
  
  return ([
    ...(provider.isOffline ? [] : [[
      'View Public',
      OpenInNewWindowIcon,
      () => {},
      { href: '/' + article.get('path'), target: '_blank' }
    ]]),
    [
      'Change Title',
      CursorTextIcon,
      () => popups.editTitle({ article })
    ], [
      'Change Description',
      Pencil2Icon,
      () => popups.editDescription({ article })
    ], [
      'Change Thumbnail',
      ImageIcon,
      () => popups.editThumbnail({ article })
    ], [
      'Rename Slug',
      Pencil1Icon,
      () => popupManager.open(RenameSlug, { article, provider }) 
    ],[
    ],[
      'Move to Folder',
      MoveIcon,
      () => popupManager.open(MoveToFolder, { article, provider })
    ], [
      'Clone to a Workspace',
      CopyIcon,
      () => popupManager.open(CloneArticle, {
        sourcePath: article.get('path'),
        title: article.get('title'),
        slug: article.get('slug')
      })
    ], [
      'Delete',
      TrashIcon,
      () => popupManager.open(DeleteArticle, { article, provider })
    ]
  ]);
}