import { ui } from 'lib/popup';
import { useControllerState } from 'lib/BaseController';
import { TextInput, Group } from 'lib/inputs/basic';
import { Buttons, Button } from 'lib/inputs/buttons';

import './style.css';


function DeleteArticlePopupView({ popup }) {
  let
    state = useControllerState(popup),
    article = popup.article,
    slug = state.get('slug'),
    loading = state.get('loading'),
    verified = slug === article.get('slug');

  return (
    <ui.Popup popup={popup} className="delete-article-popup">
      <ui.Dialog>
        <ui.TitleBar>
          <ui.Title>
            Delete Article
          </ui.Title>
          <ui.CloseButton />
        </ui.TitleBar>
        <ui.Body>
          <form
            onSubmit={e => {
              e.preventDefault();
              if (verified)
                popup.deleteArticle();
            }}>
            <ui.Warning title="Warning!">
              <p>
                This action is not reversible. <br />
                Deleting this article will delete <br />
                <u>ALL OF ITS FILES</u>, along with<br />
                <u>ALL OF ITS VERSIONS.</u>
              </p>
            </ui.Warning>

            <Group>
              <div className="mb-4">
                <div className="text-sm font-medium mb-1">Are you sure you want to delete this article?</div>
                <ui.Item
                  itemType="Article"
                  title={article.get('title')}
                  slug={article.get('slug')} />
              </div>

              <TextInput
                label="Please verify the slug"
                autoFocus={true}
                value={slug || ''}
                onChange={slug => popup.setState({ slug })}
                spellcheck={false} />
            </Group>

            <ui.Alert />

            <Buttons className="text-right">
              <Button isDisabled={loading || !verified} type="submit">
                Delete Article
              </Button>
              <Button onPress={e => { popup.close() }}>Cancel</Button>
            </Buttons>
          </form>
        </ui.Body>
      </ui.Dialog>
    </ui.Popup>
  );
}

export default DeleteArticlePopupView;