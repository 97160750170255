import { transpileToJS } from './processCode';

export const getLocalDVMapForArticle = async ({ articleController }) => {
  let
    files = articleController.localFiles.getAllFiles(),
    docPath = articleController.path,
    fetchPath = articleController.provider.fetchPath,
    dependencies = new Set();

  for (let filename in files) {
    let file = files[filename];

    if (!(file.isScript && file.isDocFile))
      continue;

    let content = await file.getContent()
    
    try {
      for (let dep of transpileToJS({ filename, content, docPath }).dependencies)
        dependencies.add(dep);      
    } catch (e) {
      throw new Error(`Error transpiling "${filename}".`);
    }
  }
  // console.log(dependencies)

  return await getLocalDVMap({ dependencies, docPath, fetchPath });
}

export const getLocalDVMap = async ({ dependencies, docPath, localPath, fetchPath }) => {
  const result = {};

  for (let dep of dependencies) {
    let [articlePath, versionSlug] = dep.split('@');

    if (versionSlug || result[articlePath] || articlePath === `/${localPath}`)
      continue;

    // throw error here if path not found.
    const
      path = articlePath.substr(1).replaceAll('/', '\\'),
      pathData = await fetchPath(path);
    if (!pathData)
      throw new Error(`Resource not found: ${articlePath}`);
    result[articlePath] = pathData?.publishedVersionSlug;
  }
  return result;  
}

export const getRemoteDVMap = async ({ docPath, fetchPath }) => {
  const pathData = await fetchPath(docPath.replaceAll('/', '\\'));
  if (!pathData)
    throw new Error(`Path "${docPath}" does not exist.`);
  return pathData.dvMap;
}