import { memo } from 'react';
import { useControllerState } from 'lib/BaseController';
import clsx from 'clsx';

export default function DetailEditor({ controller }) {
  const
    { state, version } = controller,
    detailId = state.get('detailId'),
    elements = version?.article?.elementControllers,
    element = elements && elements[detailId];
  


  return (
    <div
      className={clsx(
        'w-[100%-2rem] md:w-[40rem] h-[calc(100%-2rem)] right-[1rem] top-[1rem] absolute z-30',
        'transform transition-transform duration-100 ease-in-out',
        element ? 'transform-none' : 'translate-x-full'
      )}>
      { element && <MemoizedElement element={element} /> }
    </div>
  )
}

export function Element({ element }) {
  useControllerState(element);
  return (
    <element.lib.Editor
      controller={element.controller}
      isActive={false}
      isGrid={false}
      isDetail={true} />)
}
const MemoizedElement = memo(Element);