import { useEffect, useState } from 'react'
import BarFiles from './components/BarFiles';
import MenuButton from './components/MenuButton';
import clsx from 'clsx';


export default function FloatingFileBar({ controller, isGrid, isActive, isDark, fileControllers }) {
  const
    fileController = fileControllers.get(controller.currentIndex),
    [hasFilename, setHasFilename] = useState(!!fileController.getSettingsValue('filename')),
    hide = !isActive && fileControllers.size === 1 && !hasFilename;

  useEffect(() => {
    const getHasFilename = () => !!fileController.getSettingsValue('filename');
    setHasFilename(getHasFilename());
    return fileController.onStateChange(() => setHasFilename(getHasFilename()));
  }, [fileController])
  
  return (
    <div className="absolute top-2 right-3 flex items-center">
      <BarFiles
        controller={controller}
        fileControllers={fileControllers}
        isGrid={isGrid}
        isActive={isActive}
        isDark={isDark}
        buttonsClassName="gap-2"
        buttonClassName={({ isActive }) => clsx(
          'py-1 px-3 bg-paper2 border border-rim2 text-pencil',
        )} />

      { !hide && (<MenuButton controller={controller} isGrid={isGrid} />)}
    </div>
  )
}