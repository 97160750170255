import BaseController from 'lib/BaseController';
import { Map, List } from 'immutable';

const COLOR_MAP = {
  '30': 'text-black',
  '31': 'text-red-500',
  '32': 'text-green-500',
  '33': 'text-yellow-500',
  '34': 'text-blue-500',
  '35': 'text-purple-500',
  '36': 'text-cyan-500',
  '37': 'text-white',
  '90': 'text-gray-500',
  '91': 'text-red-400',
  '92': 'text-green-400',
  '93': 'text-yellow-400',
  '94': 'text-blue-400',
  '95': 'text-purple-400',
  '96': 'text-cyan-400',
  '97': 'text-gray-100',
};

const BG_COLOR_MAP = {
  '40': 'bg-black',
  '41': 'bg-red-500',
  '42': 'bg-green-500',
  '43': 'bg-yellow-500',
  '44': 'bg-blue-500',
  '45': 'bg-purple-500',
  '46': 'bg-cyan-500',
  '47': 'bg-white',
  '100': 'bg-gray-500',
  '101': 'bg-red-400',
  '102': 'bg-green-400',
  '103': 'bg-yellow-400',
  '104': 'bg-blue-400',
  '105': 'bg-purple-400',
  '106': 'bg-cyan-400',
  '107': 'bg-gray-100',
};

const getNewCursor = () => ({
  currentPos: 0,
  currentLineIdx: 0,
  isStderr: false,
  styles: {
    color: null,
    backgroundColor: null,
    bold: false,
    italic: false,
    underline: false
  }
});

export default class TerminalOutput extends BaseController {
  clear() {
    this.cursor = getNewCursor();
    this.history = new List();
    this.setState({ processedSegments: [], currentLine: [] });
  }

  loadHistory(history) {
    this.clear();
    this.history = history;
    history.forEach(chunk => {
      this.processChunk(chunk.get('text'), chunk.get('isErr'));
    })
  }
  getFileContent() { return this.history.map(([e]) => e.get('text')).join(''); }

  exceedsSizeLimit = () => this.getOutputSize() > 204800;
  getOutputSize= () => this.history.reduce((t, i) => (t + 24 + i.get('text').length), 0) * 2;

  get processedSegments() { 
    return this.state.get('processedSegments') || [];
  }

  get currentLine() { 
    return this.state.get('currentLine');
  }

  setProcessedSegments(processedSegments) {
    this.setState({ processedSegments });
  }

  setCurrentLine(currentLine) {
    this.setState({ currentLine });
  }

  push(text, isErr) {
    this.history = this.history.push(new Map({ text, isErr }));
    this.processChunk(text, isErr);
  }

  processChunk(text, isStderr) {
    if (!text) return;
    
    let buffer = '';
    const chars = text.split('');
    
    for (let i = 0; i < chars.length; i++) {
      const char = chars[i];
      
      if (char === '\u001b' && chars[i + 1] === '[') {
        this.flushBuffer(buffer);
        buffer = '';
        i += 2;
        let code = '';
        
        while (chars[i] && !'ABCDEFGHJKSTfmnsulh'.includes(chars[i])) {
          code += chars[i];
          i++;
        }
        
        this.handleEscapeSequence(code, chars[i], isStderr);
        continue;
      }
      
      if (char === '\b') {
        this.flushBuffer(buffer);
        buffer = '';
        this.handleBackspace();
        continue;
      }
      
      if (char === '\r') {
        this.flushBuffer(buffer);
        buffer = '';
        this.cursor.currentPos = 0;
        continue;
      }
      
      if (char === '\n') {
        this.flushBuffer(buffer);
        buffer = '';
        this.handleNewline(isStderr);
        continue;
      }
      
      buffer += char;
    }
    
    if (buffer) {
      this.flushBuffer(buffer);
    }
  }

  handleEscapeSequence(code, terminator, isStderr) {
    if (terminator === 'm') {
      const codes = code.split(';');
      for (const singleCode of codes) {
        this.handleStyleCode(singleCode, isStderr);
      }
    } else if (terminator === 'J') {
      if (code === '2') {
        this.setProcessedSegments([]);
        this.setCurrentLine([]);
        this.cursor.currentPos = 0;
        this.cursor.currentLineIdx = 0;
      } else if (code === '1') {
        this.setCurrentLine([]);
        this.cursor.currentPos = 0;
        const newSize = Math.max(0, this.cursor.currentLineIdx);
        this.setProcessedSegments(this.processedSegments.slice(0, newSize));
      }
    } else if (terminator === 'K') {
      if (code === '' || code === '0') {
        if (this.currentLine.length > 0) {
          const lastSegment = this.currentLine[this.currentLine.length - 1];
          if (lastSegment) {
            const newSegment = {
              ...lastSegment,
              text: lastSegment.text.slice(0, this.cursor.currentPos)
            };
            this.setCurrentLine([...this.currentLine.slice(0, -1), newSegment]);
          }
        }
      } else if (code === '1') {
        if (this.currentLine.length > 0) {
          const lastSegment = this.currentLine[this.currentLine.length - 1];
          if (lastSegment) {
            const newSegment = {
              ...lastSegment,
              text: lastSegment.text.slice(this.cursor.currentPos)
            };
            this.setCurrentLine([...this.currentLine.slice(0, -1), newSegment]);
            this.cursor.currentPos = 0;
          }
        }
      } else if (code === '2') {
        this.setCurrentLine([]);
        this.cursor.currentPos = 0;
      }
    }
  }

  handleStyleCode(code, isStderr) {
    const numCode = parseInt(code);
    
    if (numCode === 0) {
      this.cursor.styles = {
        color: isStderr ? '31' : null,
        backgroundColor: null,
        bold: false,
        italic: false,
        underline: false
      };
      return;
    }

    switch(numCode) {
      case 1:
        this.cursor.styles.bold = true;
        break;
      case 3:
        this.cursor.styles.italic = true;
        break;
      case 4:
        this.cursor.styles.underline = true;
        break;
      case 22:
        this.cursor.styles.bold = false;
        break;
      case 23:
        this.cursor.styles.italic = false;
        break;
      case 24:
        this.cursor.styles.underline = false;
        break;
      default:
    }

    if ((numCode >= 30 && numCode <= 37) || (numCode >= 90 && numCode <= 97)) {
      this.cursor.styles.color = code;
    }
    
    if ((numCode >= 40 && numCode <= 47) || (numCode >= 100 && numCode <= 107)) {
      this.cursor.styles.backgroundColor = code;
    }
  }

  flushBuffer(buffer) {
    if (!buffer) return;

    const { currentPos, styles, isStderr } = this.cursor;

    const segment = {
      text: buffer,
      ...styles,
      isStderr
    };

    if (currentPos < this.currentLine.length) {
      const existing = this.currentLine[this.currentLine.length - 1];
      const beforeText = existing?.text.slice(0, currentPos) || '';
      const afterText = existing?.text.slice(currentPos + buffer.length) || '';
      const newSegment = {
        ...segment,
        text: beforeText + buffer + afterText
      };
      this.setCurrentLine([...this.currentLine.slice(0, -1), newSegment]);
    } else {
      this.setCurrentLine([...this.currentLine, segment]);
    }
    
    this.cursor.currentPos += buffer.length;
  }

  handleNewline(isStderr) {
    const { styles } = this.cursor;
    
    if (this.currentLine.length === 0) {
      this.setCurrentLine([{ text: '', ...styles, isStderr }]);
    }
    
    this.setProcessedSegments([...this.processedSegments, this.currentLine]);
    this.setCurrentLine([]);
    this.cursor.currentPos = 0;
    this.cursor.currentLineIdx++;
  }

  handleBackspace() {
    const { currentPos } = this.cursor;
    
    if (currentPos > 0) {
      this.cursor.currentPos--;
      if (this.currentLine.length > 0) {
        const lastSegment = this.currentLine[this.currentLine.length - 1];
        if (lastSegment) {
          const newSegment = {
            ...lastSegment,
            text: lastSegment.text.slice(0, -1)
          };
          if (newSegment.text === '') {
            this.setCurrentLine(this.currentLine.slice(0, -1));
          } else {
            this.setCurrentLine([...this.currentLine.slice(0, -1), newSegment]);
          }
        }
      }
    }
  }

  getSegments() {
    return [...this.processedSegments, this.currentLine];
  }

  getStyles(segment) {
    const classes = [];

    if (segment.color) {
      classes.push(COLOR_MAP[segment.color]);
    }
    if (segment.backgroundColor) {
      classes.push(BG_COLOR_MAP[segment.backgroundColor]);
    }
    if (segment.bold) {
      classes.push('font-bold');
    }
    if (segment.italic) {
      classes.push('italic');
    }
    if (segment.underline) {
      classes.push('underline');
    }
    if (segment.isStderr) {
      classes.push('text-red-500');
    }

    return classes.join(' ');
  }

  updateSegments(index, newLine) {
    const newSegments = [...this.processedSegments];
    newSegments[index] = [...newLine];
    this.setProcessedSegments(newSegments);
  }

  removeSegments(start, end) {
    this.setProcessedSegments(this.processedSegments.slice(start, end));
  }
}