import BaseElementController from 'modules/elements/lib/BaseElementController';
import settingsFields from '../Policies/settingsFields';
import { Set } from 'immutable';
// import { getFilenamePromptParams } from 'modules/elements/lib/helpers';


class Controller extends BaseElementController {
  settingsFields = settingsFields;
  _editorMap = {};
  setEditor = (editor, elementId) => this._editorMap[elementId] = editor;
  getCurrentEditor = () => this._editorMap[this.getCurrentFileController().elementId];
  getCurrentFileController = () => this.getOrderedFileControllers().get(this.state.get('currentIndex'));

  focusCurrentEditor = () => {
    setTimeout(() => {
      const editor = this.getCurrentEditor();
      if (editor) editor.focus();
      else console.log('not found.')
    }, 1);
  }

  getOrderedFileControllers() {
    let
      map = new Map(),
      order = this.state.get('order'),
      orderSet = Set(this.state.get('order'));

    this.getChildren().forEach(child => {
      if (!orderSet.has(child.elementId))
        order = order.push(child.elementId);
      map.set(child.elementId, child)
    });
    return order.map(id => map.get(id)).filter(o => o);
  }

  get currentIndex() {
    return Math.max(0, Math.min(this.state.get('currentIndex'), this.getChildren().length - 1));
  }

  focus = () => {
    // if (this.getSettingsValue('isCollapsable') && this.getState().get('isCollapsed')) {
    //   this.setState({ isCollapsed: false });
    // }
    this.focusCurrentEditor();
  }

  setCurrentFileIndex = (index) => {
    this.setState({ currentIndex: index });
    this.focusCurrentEditor();
  }

  moveOrderIndex = (sourceIndex, destinationIndex) => {
    var
      order = this.getOrderedFileControllers().map(c => c.elementId),
      currentIndex = this.state.get('currentIndex');
    
    order = order.splice(sourceIndex, 1).splice(destinationIndex, 0, order.get(sourceIndex));
    if (currentIndex === sourceIndex)
      currentIndex = destinationIndex;
    
    this.setState({ order, currentIndex });
    this.save();
  }

  addNewFilePopup = () => this.addChildFilePopup({
    isPulled: false,
    onCreate: (data) => {
      const index = this.getOrderedFileControllers().findIndex(c => c.elementId === data.id);
      if (index !== -1) {
        this.setCurrentFileIndex(index)
      }
    }
  });
  
  deleteCurrentFilePopup = () => {
    this.getCurrentFileController().deletePrompt();
  }
}

export default Controller;