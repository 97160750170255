import { Button } from 'react-aria-components';
import { schema } from 'lib/prosemirror/schema.ts';
import clsx from 'clsx';
import { 
  FontBoldIcon, FontItalicIcon, UnderlineIcon, CodeIcon,
  TextAlignLeftIcon, TextAlignCenterIcon, TextAlignRightIcon, Link2Icon,
  BorderAllIcon, PinLeftIcon, PinRightIcon, Pencil1Icon
} from '@radix-ui/react-icons';

import { getBlockAttrButtonProps, getBlockButtonProps } from '../Inspector/Toolbar'


export default function QuickSelectMenu({ controller }) {
  const { view, viewState, el } = controller.editorController;
  if (!viewState || !view)
     return '';

  let { to, from } = viewState.selection;
  if (from >= to)
    return '';

  const
    isImage = (viewState.selection.node && viewState.selection.node.type) === schema.nodes.image,
    boxTo = view.coordsAtPos(to),
    boxFrom = view.coordsAtPos(from),
    parentbox = el.getBoundingClientRect(),
    left = (boxFrom.left - parentbox.left) + 'px',
    top = isImage ? (boxFrom.top - parentbox.top - 40) : (boxTo.bottom - parentbox.top + 20) + 'px';

  return (
    <div className="absolute z-30" style={{ top: top, left: left }}>
      {!isImage && <Menu controller={controller} /> }
      {isImage && <ImageMenu controller={controller} node={viewState.selection.node} /> }
    </div>
  )
}

function Menu({ controller }) {
  return (
    <div className="flex bg-paper rounded text-sm drop-shadow-sm border-rim border px-2">
      <MarkButton Icon={FontBoldIcon} mark={schema.marks.strong} controller={controller} />
      <MarkButton Icon={FontItalicIcon} mark={schema.marks.em} controller={controller} />
      <MarkButton Icon={UnderlineIcon} mark={schema.marks.underline} controller={controller} />
      <MarkButton Icon={CodeIcon} mark={schema.marks.code} controller={controller} />

      <MarkButton
        Icon={Link2Icon} mark={schema.marks.link} controller={controller}
        onPress={() => {
          const ec = controller.editorController;
          if (ec.isMarkActive(schema.marks.link)) {
            ec.toggleMark(schema.marks.link);
          } else {
            ec.addLinkPrompt();
          }
        }}/>
        <Break />

        <BlockButton controller={controller} nodeType={schema.nodes.paragraph}>
          Normal
        </BlockButton>

        {[1, 2, 3, 4].map(level => (
          <BlockButton
            key={level}
            controller={controller}
            nodeType={schema.nodes.heading}
            attrs={{ level }}>
            H{level}
          </BlockButton>
        ))}

        <Break />

        <AlignButtons controller={controller} />
    </div>
  );
}

function AlignButtons({ controller }) {
  const center = getBlockAttrButtonProps({ controller, attrKey: 'align', attrValue: 'center' });
  const left = getBlockAttrButtonProps({ controller, attrKey: 'align', attrValue: 'left' });

  return (
    <>
      <MenuButton
        isDisabled={left.isDisabled}
        isActive={left.isActive}
        onPress={left.fn}>
        <TextAlignLeftIcon className="w-4 h-4" />
      </MenuButton>

      <MenuButton
        isDisabled={center.isDisabled}
        isActive={center.isActive}
        onPress={center.fn}>
        <TextAlignCenterIcon className="w-4 h-4" />
      </MenuButton>
    </>
  )
}

function BlockButton({ controller, nodeType, attrs, children }) {
  const { isActive, isDisabled, fn } = getBlockButtonProps({ controller, nodeType, attrs });
  return (<MenuButton isActive={isActive} isDisabled={isDisabled} onPress={fn} children={children} />);
}

function MarkButton({ Icon, mark, controller, onPress }) {
  return (
    <MenuButton
      isActive={controller.editorController.isMarkActive(mark)}
      onPress={onPress || (() => controller.editorController.toggleMark(mark))}>
      <Icon className="w-4 h-4" />
    </MenuButton>
  );
}

function MenuButton(props) {
  return (
    <Button
      className={clsx(
        props.isActive && 'text-marker3',
        props.isDisabled && 'opacity-50',
        'p-1 flex items-center justify-center hover:bg-wax min-w-6 whitespace-nowrap'
      )}
      preventFocusOnPress={true} 
      {...props} />);
}

function Break() {
  return (<div className="border-l border-rim pl-2 ml-2"></div>)
}

function ImageMenu({ controller, node }) {
  const
    { alignment, hasBorder, description } = node.attrs,
    setAttrs = (attrs) => {
      const
        { view, viewState } = controller.editorController,
        { selection } = viewState;

      // Ensure we have a block node
      if (selection.node) {
        const command = (state, dispatch) => {
          dispatch(state.tr.setNodeMarkup(selection.from, null, {...node.attrs, ...attrs }));
        };
        // Execute the command
        command(viewState, view.dispatch);
      }
    };

  return (
    <div className="flex bg-paper rounded text-sm drop-shadow-sm border-rim border px-2"> 
      <MenuButton isActive={alignment === 'left'} onPress={() => setAttrs({ alignment: 'left' })}>
        <TextAlignLeftIcon />
      </MenuButton>
      <MenuButton isActive={alignment === 'center'} onPress={() => setAttrs({ alignment: 'center' })}>
        <TextAlignCenterIcon />
      </MenuButton>
      <MenuButton isActive={alignment === 'right'} onPress={() => setAttrs({ alignment: 'right' })}>
        <TextAlignRightIcon />
      </MenuButton>
      <Break />
      <MenuButton isActive={alignment === 'float-left'} onPress={() => setAttrs({ alignment: 'float-left' })}>
        <PinLeftIcon />
      </MenuButton>
      <MenuButton isActive={alignment === 'float-right'} onPress={() => setAttrs({ alignment: 'float-right' })}>
        <PinRightIcon />
      </MenuButton>

      <Break />

      <MenuButton
        isActive={hasBorder}
        onPress={() => setAttrs({ hasBorder: hasBorder === 'yes' ? null : 'yes' })}>
        <BorderAllIcon />
      </MenuButton>
      <Break />
      
      <MenuButton
        onPress={() => controller.editorController.addImage({ node })}>
        <Link2Icon />&nbsp;
        Image
      </MenuButton>


      <Break />

      <MenuButton
        onPress={() => controller.openPopup('rich-text-editor', {
          title: `Edit Description`,
          format: 'html',
          text: description || '',
          onSave: description => setAttrs({ description })
        })}>
        <Pencil1Icon /> &nbsp;
        Description
      </MenuButton>
    </div>
  )
}