import { Outlet } from 'react-router-dom';
import Container from './Container';
import UserButton from '../User/UserButton';
import ColorSchemeMenu from './misc/ColorSchemeMenu';
import Stars from 'lib/components/Stars';

function Home({ app }) {
  return (
    <Container app={app}>
      <Outlet />

      <Stars />

      <div className="absolute top-4 right-6 max-md:top-2 max-md:right-2 flex items-center gap-2">
        <ColorSchemeMenu app={app} />
        <UserButton app={app} />
      </div>
    </Container>
  );  
}

export default Home;