import Auth from './Auth';
import PopupManager from 'lib/popup/Manager';
import { getKeyDispatcher } from 'lib/KeyDispatcher';
import Vani from './Vani';
import Machine from 'modules/machine/controllers/Machine';
import RemoteProvider from 'api/remote/Provider';
import LocalProvider from 'api/local/Provider';
import { Cache, RemoteCache } from './caching';
import Plugins from './Plugins';

import Workspace from 'modules/workspace/controllers/Workspace';
import Editor from 'modules/editor/controllers/Editor';
import Viewer from 'modules/viewer/controllers/Viewer';
import Theme from 'modules/theme/controllers/Theme';
import ColorScheme from './ColorScheme';
import Genai from 'modules/genai/controllers/Genai';


class App {
  constructor() {
    const app = this;
    this.uuid = (new Date()).getTime();
    this.auth = new Auth(app);
    this.keyDispatcher = getKeyDispatcher();
    this.popupManager = new PopupManager();
    this.colorScheme = new ColorScheme();

    this.localProvider = new LocalProvider();
    this.remoteProvider = new RemoteProvider();

    this.vani = new Vani(app);
    this.machine = new Machine(app);

    this.caching = {
      cache: new Cache(),
      remoteCache: new RemoteCache()
    }
    this.plugins = new Plugins();

    this.workspace = new Workspace({ app });
    this.editor = new Editor({ app });
    this.viewer = new Viewer({ app });
    this.theme = new Theme({ app });
    this.genai = new Genai({ app });
  }
}

export default App;