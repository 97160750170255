const settingsFields = {
  filename: { caption: 'Filename', input: 'textarea', init: '' },

  tabMode: {
    caption: 'Tab Spacing', input: 'options', init: '',
    options: [
      { caption:'2 Spaces', key: '2s' },
      { caption:'4 Spaces', key: '4s' },
      { caption:'Auto', key: '' }
    ],
  },
}

export const settingsFieldsLayout = [
  [
    ['filename'],
  ]
]

export const initialSettings = {
  isBase64: false,
  isPulled: false,
  contentType: null,
  mode: '',
  filename: '',
  tabMode: '',
};

for (let k in settingsFields) {
  initialSettings[k] = settingsFields[k].init;
}
export default settingsFields;