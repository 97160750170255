import { map } from 'lodash';
import React from 'react';
import Icon from 'lib/Icon';
import { LinkBreak2Icon, Cross2Icon } from '@radix-ui/react-icons';
import { Button } from 'lib/inputs/buttons';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';


function Machine({ popup }) {
  const
    machine = popup.machine,
    machineInfo = machine.state.get('machineInfo'),
    processes = machine.state.get('processes');
  
  if (!machineInfo || !processes) {
    return (<div className="loading">...</div>);
  }
    
  const cpus = {};
  machineInfo.cpus.forEach(m => cpus[m.model] = (cpus[m.model] || 0) + 1);

  return (
    <div className="machine">
      <div className="">
        <div className="flex gap-6 font-mono px-4 py-2">
          <div className="text-4xl">
            <Icon faIcon={faLaptopCode} />
          </div>

          <div className="text-sm">
            <div><span className="inline-block font-bold w-11">Host:</span> {machineInfo.hostname}</div>
            <div><span className="inline-block font-bold w-11">CWD:</span> {machineInfo.cwd}</div>
            <div><span className="inline-block font-bold w-11">PID:</span> {machineInfo.pid}</div>
            <div><span className="inline-block font-bold w-11">CPUS:</span> {map(cpus, (v, k) => `${v} x ${k}`).join(', ')}</div>
          </div>
        </div>

        <div className="text-right">
          <Button onPress={() => popup.closeConnection()} size="small">
            <LinkBreak2Icon className="inline" /> &nbsp;Disconnect
          </Button>
        </div>
      </div>

      <div className="pb-3">
        <div className="ml-3 mb-2 font-medium text-sm">Active Processes</div>
        <div className="border border-rim p-2 h-[14rem] overflow-auto">
          {processes.valueSeq().map((process) => (
            <Process key={process.id} process={process} machine={machine} />
          ))}
          {processes.isEmpty() && (
            <div className="text-center text-pencil3 text-sm p-2">No Active Process</div>
          )}
        </div>
      </div>
    </div>
  )
}

function Process({ process, machine }) {
  return (
    <div className="process flex text-sm py-3 px-5 border border-rim rounded-sm mb-2">
      <div className="flex-grow font-mono text-xs">
        <div className="">
          {new Date(Number(process.id)).toLocaleString()}
          <div
            className={clsx(
              'inline ml-2 py-0.5 px-2 bg-wax2 font-bold',
              process.status === 'running' ? 'text-green-700' : 'text-red-700'
            )}>
            {process.status}
          </div>
        </div>
        <div>
          <strong>PID:</strong> {process.pid}
        </div>
        <div>
          <strong>CWD:</strong> {process.opts.subPath}
        </div>
        <div className="font-bold">
          Command: {process.opts.command}
        </div>
      </div>
      <div>
        {process.status === 'running' && (
          <Button size="small" onClick={_ => machine.killProcess(process.id)}>
            <Cross2Icon className="inline" />&nbsp; Kill
          </Button>
        )}        
      </div>
    </div>    
  );
}


export default Machine;