import { Map } from 'immutable';
import BasePopupController from 'lib/popup/BasePopupController';
import { toHuman } from 'lib/firebaseError';
import View from './View';
import templates from './templates';
import clone from 'api/clone';


class PopupController extends BasePopupController {
  static View = View;
  position = new Map({ width: 340 });

  constructor(args, manager) {
    super(args, manager);
    this.fetchPath = args.provider.fetchPath;
    this.createArticle = args.provider.createArticle;
    this.updateArticle = args.provider.updateArticle;
    this.checkAvailable = args.provider.checkAvailable;

    this.state = this.state.merge({
      parentPath: args.parentPath,
      title: '',
      slug: '',
      at: 0,
      layoutType: 'scroll',
      templateIndex: 0,
      autoSlug: true,
      created: false
    });
  }

  async addArticle() {
    this.setState({ loading: true, alert: '' });
    try {
      if (this.state.get('layoutType') === 'grid' || this.state.get('layoutType') === 'scroll')
        await this.createBlankArticle();
      else
        await this.cloneTemplate();

      console.log('[article created]');
      this.setState({ loading: false, created: true, at: 2 });
    } catch(e) {
      console.error(e);
      this.setState({ alert: toHuman(e), loading: false });
    }
  }

  async createBlankArticle() {
    const
      layoutType = this.state.get('layoutType'),
      parentPath = this.state.get('parentPath'),
      { domainId, folderId } = (await this.fetchPath(parentPath)),
      parentId = folderId,
      title = this.state.get('title'),
      slug = this.state.get('slug');

    return await this.createArticle({ domainId, parentId, slug, title, layoutType });
  }

  async cloneTemplate() {
    const
      title = this.state.get('title'),
      sourcePath = templates[this.state.get('templateIndex')].path,
      targetPath = `${this.state.get('parentPath')}\\${this.state.get('slug')}`;

    return await clone({ sourcePath, targetPath, title });
  }
}

export default PopupController;