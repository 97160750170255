import { useState, useEffect } from 'react';
import clsx from 'clsx';

import './Loader.css';


export default function Loader({ className }) {
  return (
    <div className={clsx('nb-dots-loader', className)}>
      <div className="dot dot1 fade-in">.</div>
      <div className="dot dot2 fade-in">.</div>
      <div className="dot dot3 fade-in">.</div>
    </div>
  )
}

export function Timer() {
  const [s, setS] = useState(0);

  useEffect(() => {
    let i = setInterval(() => { setS(s => s + 1); }, 1000)
    return () => clearInterval(i);
  }, [])
  return <>{s}</>
}