import React from 'react';
import { Button as AriaButton, Link } from 'react-aria-components';
import clsx from 'clsx';


export function Button({
  children, href, isDisabled, type, className, marginClass, borderClass,
  size, onPress, onClick, autoFocus, target
}) {
  const C = href ? Link : AriaButton;

  return (
    <C
      type={type || 'button'}
      href={href}
      target={target}
      className={({ isDisabled }) => clsx(
        'text-sm text-center font-medium rounded-sm min-w-[4rem]',
        isDisabled && 'pointer-events-none opacity-50',
        borderClass || 'border border-rim3',
        size === 'small' ? 'px-2 py-1' : 'px-3 py-2',
        marginClass || 'my-1 mr-2 last:mr-0',
        href && 'inline-block',
        className
      )}
      autoFocus={autoFocus}
      isDisabled={isDisabled}
      children={children}
      onPress={onPress}
      onClick={onClick} />
  );
}

export function Buttons({ className, children }) {
  return (
    <div className={clsx('py-2 mt-2', className)}>
      {children}
    </div>
  );
}

export function ButtonIcon(props) {
  const { Icon } = props;
  return (<div className="nb-icon-container"><Icon /></div>);
}
