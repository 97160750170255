import ui from 'lib/popup/components/ui';
import { useControllerState } from 'lib/BaseController';
import { Button } from 'lib/inputs/buttons';
import TerminalOutput from 'lib/TerminalOutput/View';


export default function Popup({ popup }) {  
  // const {  } = popup;
  const state = useControllerState(popup);

  return (
    <ui.Window popup={popup} title="Terminal">
      { 
        state.get('processIsActive') ?
          <Terminal popup={popup} /> :
          <StartButton popup={popup} />
      }
    </ui.Window>
  )
}

function StartButton({ popup }) {
  return (
    <div className="text-center py-10">
      <Button onPress={() => {popup.run()}}>
        Run - Bash
      </Button>
    </div>
  )
}

function Terminal({ popup }) {
  // const outputS = useControllerState();
  return (
    <div>
      <div className="border px-4 py-2 h-[20rem] overflow-auto bg-wax">
        <TerminalOutput
          controller={popup.terminalOutput}
          processIsActive={popup.state.get('processIsActive')}
          setTerminalInputEl={popup.setTerminalInputEl}
          sendStdin={message => popup.machineInterface.sendStdin(message)} />
      </div>
      <div className="text-right">
         <Button onPress={() => popup.kill()}>
          Kill
        </Button>        
      </div>
    </div>
  )
}