export function DocLike({ className }) {
  return (
    <svg className={className} width="120" height="98" viewBox="0 0 120 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="0.75" width="118.5" height="96.5" stroke="currentColor" strokeWidth="1" strokeLinejoin="round"/>
      <rect x="39.5" y="20.5" width="36" height="20" stroke="currentColor"/>
      <path d="M39 57H55" stroke="currentColor"/>
      <path d="M60 57H76" stroke="currentColor"/>
      <path d="M39 67H55" stroke="currentColor"/>
      <path d="M60 67H76" stroke="currentColor"/>
      <path d="M39 62H55" stroke="currentColor"/>
      <path d="M60 62H76" stroke="currentColor"/>
      <path d="M39 72H55" stroke="currentColor"/>
      <path d="M60 72H76" stroke="currentColor"/>
      <path d="M39 49H76" stroke="currentColor" strokeWidth="2"/>
    </svg>
  )
}

export function BookLike({ className }) {
  return (
    <svg className={className} width="120" height="98" viewBox="0 0 120 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="15.5" y="27.5" width="36" height="20" stroke="currentColor"/>
      <rect x="0.75" y="0.75" width="118.5" height="96.5" stroke="currentColor" strokeWidth="1" strokeLinejoin="round"/>
      <path d="M67 27H103" stroke="currentColor"/>
      <path d="M67 47H103" stroke="currentColor"/>
      <path d="M67 37H103" stroke="currentColor"/>
      <path d="M67 57H103" stroke="currentColor"/>
      <path d="M67 32H103" stroke="currentColor"/>
      <path d="M67 52H103" stroke="currentColor"/>
      <path d="M67 42H103" stroke="currentColor"/>
      <path d="M67 62H103" stroke="currentColor"/>
      <path d="M15 56H52" stroke="currentColor" strokeWidth="2"/>
    </svg>
  )
}


export function BlogLike({ className }) {
  return (
    <svg className={className} width="122" height="100" viewBox="0 0 122 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 57H53M65 66H76V76H65V66ZM79 66H90V76H79V66ZM93 66H104V76H93V66ZM65 53H76V63H65V53ZM79 53H90V63H79V53ZM93 53H104V63H93V53ZM65 40H76V50H65V40ZM79 40H90V50H79V40ZM93 40H104V50H93V40ZM65 27H76V37H65V27ZM79 27H90V37H79V27ZM93 27H104V37H93V27ZM16 28H53V49H16V28ZM1 1H121V99H1V1Z"
        stroke="currentColor"
        stroke-width="1"/>
    </svg>
  )
}