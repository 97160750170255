import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeIcon, TriangleRightIcon, PlusIcon } from '@radix-ui/react-icons';
import ItemList from 'modules/folder/views/List';
import AddMenu from 'modules/folder/views/AddMenu';
import { useControllerState } from 'lib/BaseController';
import Loader from 'lib/staticPages/Loader';
import clsx from 'clsx';

import { DialogTrigger, Link, Button, Popover, OverlayArrow, Dialog } from 'react-aria-components';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import Arrow from 'lib/components/Arrow';
import Logo from 'lib/Logo';
import { OneTitle } from './Titles';


export default function LogoButton({
  editor, children,
  showChevron=true,
  offset=20,
  placement='bottom',
  showTitle=false,
  wide=false
}) {
  const [isOpen, setOpen] = useState(false);

  return (
    <DialogTrigger>
      <Button className="outline-none" onPress={() => setOpen(true)}>
        {children || (
          <>
            <Logo className="w-6 h-6 inline-block light:text-orange-800" />
            { showChevron && (
              <ChevronDownIcon className="inline-block ml-2" />
            )}
          </>
        )}
      </Button>

      <Popover offset={offset} isOpen={isOpen} onOpenChange={setOpen} placement={placement}>
        <OverlayArrow>
          <Arrow className={clsx(placement === 'end' && '-rotate-90 -mr-[1px]')} />
        </OverlayArrow>

        <Dialog className="outline-none">
          <Leaf editor={editor} close={() => setOpen(false)} showTitle={showTitle} />
        </Dialog>
      </Popover>
    </DialogTrigger>
  )
}

function Leaf({ editor, close, showTitle }) {
  const
    articleController = editor.currentArticleController,
    path = articleController.state.getIn(['article', 'path']),
    folderId = articleController.state.getIn(['article', 'parentId']),
    domainId = articleController.state.getIn(['article', 'domainId']);

  return path && (
    <div className="flex flex-col bg-paper border border-rim rounded-sm w-[24rem] overflow-hidden py-1 shadow">
      { showTitle && (
        <div className="p-2 text-center border-b border-rim">
          <OneTitle editor={editor} />
        </div>
      ) }

      <LinkChain editor={editor} />
      <div className="flex-grow">
        {folderId && (
          <FolderPanel editor={editor} domainId={domainId} folderId={folderId} close={close} />
        )}
      </div>
    </div>
  )
}

function LinkChain({ editor }) {
  const
    { domainController, folderController, currentArticleController } = editor,
    crumbs = [
      [
        domainController.state.getIn(['domain', 'title']),
        domainController.state.getIn(['domain', 'slug'])
      ]
    ]
    if (folderController.state.getIn(['self', 'parentId']))
      crumbs.push([
        folderController.state.getIn(['self', 'title']),
        folderController.state.getIn(['self', 'path'])
      ]);
    crumbs.push([
      currentArticleController.state.getIn(['article', 'title']),
      currentArticleController.state.getIn(['article', 'path'])  
    ]);
    // folderTitle = domainController.state.getIn(['domain', title]),
    // path = currentArticleController.state.getIn(['article', 'path']),
    // crumbs = path && path.split('\\');



  return (
    <div className="flex bg-paper py-3 px-4 items-top overflow-auto no-scrollbar">
      <Link href="/" className="block">
        <HomeIcon className="inline-block w-4 h-4" />
      </Link>
      <div className="">
        { crumbs.map(([title, path], i) => (
          <div className="inline-block" key={i}>
            <TriangleRightIcon className="inline-block flex-shrink-0 mx-2" />

            <Link href={'/workspaces/' + path} isDisabled={i === crumbs.length - 1}>
              {title}
            </Link>
          </div>          
        ))}
      </div>
    </div>
  )
}

function FolderPanel({ editor, domainId, folderId, close }) {
  const
    { folderController, currentArticleController } = editor,
    { provider } = currentArticleController,
    navigate = useNavigate();
  useControllerState(folderController);

  useEffect(() => {
    if (folderId)
      folderController.load({ domainId, folderId, provider: provider });
  }, [domainId, folderId, provider, folderController]);

  if (!editor.folderController.isLoaded) {
    return (<div className="pb-4 text-center text-xl"><Loader /></div>)
  }

  return (
    <>
      <div className="max-h-[25rem] overflow-auto min-h-[10rem] slim-sidebar border-t border-b border-rim bg-paper2 p-2">
        <ItemList
          activeId={editor.currentArticleId}
          controller={folderController}
          openIds={new Set(editor.state.get('articleIds'))}
          replace={true}
          onAfterSelect={close}
          compact={true}
          onPressClose={(id) => editor.closeArticle(id, navigate)} />
      </div>

      <div className="text-right px-4 py-1">
        <AddMenu controller={folderController} className="py-2">
          <PlusIcon className="w-5 h-5" />
        </AddMenu>
      </div>
    </>
  );
}
