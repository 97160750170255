export default class Bridge {
  constructor({ webrunner }) {
    this.webrunner = webrunner;
  }

  handleRequest({ subject, data, respond }) {
    const respondPromise = (promise) => (
      promise
          .then(data => respond(data))
          .catch(e => {
            console.error(e);
            return respond({ error: e.message });
          })
    );

    if (subject === 'get editor data') {
      respond({ data: this.getEditorData() });
    }
    else if (subject === 'save editor data') {
      respondPromise(this.saveEditorData(data));
    }

    else if (subject === 'save editor file') {
      respondPromise(this.saveEditorFile(data));
    }
    else if (subject === 'delete editor file') {
      respondPromise(this.deleteEditorFile().then(isDeleted => ({ isDeleted })));
    }
    else if (subject === 'fetch editor file') {
      respondPromise(this.fetchEditorFile().then(file => ({ file })));
    }
    else if (subject === 'edit editor filename') {
      respondPromise(this.editEditorFilename({
        extension: data?.extension
      }).then(filename => ({ filename })));
    }
    else if (subject === 'fetch editor filename') {
      respondPromise(this.fetchEditorFilename().then(filename => ({ filename })));
    }

    else if (subject === 'open popup prompt') {
      const { question, height, width, title, inputs, submitLabel, cancelLabel } = data;
      this.webrunner.openPopup('prompt', {
        question, height, width, title, inputs, submitLabel, cancelLabel,
        onSubmit: (values) =>  respond({ values }),
        onUnload: () => respond({ values: null })
      });
    }
    else if (subject === 'open popup uploads') {
      const { accept, title } = data;
      
      this.webrunner.openPopup('upload-files', {
        title,
        accept: accept,
        allowPreview: true,
        onSelect: filename => respond({ filename }),
        onUnload: () => respond({ filename: null })
      });
    }
  }

  handleMessage = ({ subject, payload }) => {
    if (subject === 'focus event'){
      this.webrunner.activate();
    }
  }

  getEditorData = () => this.webrunner.getState().get('editorData');

  saveEditorData = async (data) => {
    if (typeof data !== 'object')
      throw new Error('The data should be an object');
    
    if (JSON.stringify(data).length > 200000)
      throw new Error('Data too large.');

    this.webrunner.setState({ editorData: data });
  }

  saveEditorFile = async (data) => {
    let child = this.webrunner.getChildren()[0];
    if (!child)
      throw new Error('Missing filename.');

    if (typeof data.content !== 'string')
      throw new Error('Content not provided.');

    child.setState({ content: data.content });
    child.setSettings('isBase64', data.isBase64 || false);
    child.setSettings('contentType', data.contentType || null);
    return null;
  }

  fetchEditorFile = async () => {
    let child = this.webrunner.getChildren()[0];

    if (!child)
      return null;

    return {
      content: child.state.get('content'),
      isBase64: child.getSettingsValue('isBase64'),
      contentType: child.getSettingsValue('contentType')
    }
  }

  deleteEditorFile = async() => {
    let child = this.webrunner.getChildren()[0];

    if (child)
      return child.deletePrompt();

    return false;
  }

  editEditorFilename = async ({ extension }) => {
    let child = this.webrunner.getChildren()[0];

    if (child)
      return await child.editFilenamePopup({ allowEmpty: false, extension });

    const data = await this.webrunner.addChildFilePopup({ isPulled: false, extension });
    return data?.settings?.filename;
  }

  fetchEditorFilename = async () => {
    let child = this.webrunner.getChildren()[0];
    if (child)
      return child.getSettingsValue('filename');
    return null;
  }
}