import { useNavigate } from "react-router-dom";
import {
  Link, Button, TooltipTrigger, Tooltip
} from 'react-aria-components';
import {
  ThickArrowLeftIcon, CopyIcon, HobbyKnifeIcon, TrashIcon,
  CheckIcon, Cross2Icon, ChatBubbleIcon,
  DotFilledIcon, DotIcon
} from '@radix-ui/react-icons';
import Loader from 'lib/staticPages/Loader';
import MenuBuilder from 'lib/components/MenuBuilder';

import clsx from 'clsx';
import { PROVIDERS } from '../../controllers/Genai';


export default function Topbar({ controller }) {
  const mode = controller.state.get('mode');

  return (
    <div className={clsx(
      'absolute top-0 left-0 w-[calc(100%-1rem)] z-30 pt-4 pb-8 pl-6 pr-2 items-center',
      'bg-gradient-to-b from-paper via-paper via-75%'
      )}>
      {
        mode === 'refine' ?
          <RefineBar {...{ controller }} /> :
          <MenuBar {...{ controller }} />
      }
    </div>
  )
}

function MenuBar({ controller }) {
  const { gen } = controller;

  return (
    <div className="flex gap-2">
      <div>
        <Link href="/genai">
          <ThickArrowLeftIcon className="inline w-5 h-5" />
        </Link>
      </div>

      <div className="grow"></div>

      <div className="flex items-center gap-4">
        <VersionBar {...{ controller }} />
        <div className="text-sm">
          {PROVIDERS[gen.provider]?.caption}
        </div>
        <Menu {...{ controller }} />        
      </div>
    </div>
  )
}


const Menu = ({ controller }) => {
  const
    { userId, gen } = controller,
    navigate = useNavigate();

  return (
    <MenuBuilder
      className="p-1 mx-2"
      options={[
        ['Refine', HobbyKnifeIcon, () => controller.setState({ mode: 'refine' }), { isDisabled: !userId } ],
        ['Clone to Workspace', CopyIcon, () => controller.clonePrompt(), { isDisabled: !userId } ],
        [],
        ['Delete', TrashIcon, () => {
          controller
            .deleteGen({ genId: gen.id })
            .then((deleted) => {
              if (deleted) navigate('/genai');
            })
        }, {}]
      ]} popoverClassName="min-w-28" />
  );
}


function VersionBar({ controller }) {
  const
    { gen, version } = controller,
    versions = gen?.versions;

  return versions && versions.length > 1 && (
    <div className="flex gap-1">
      {versions.map((v, i) => (
        <TooltipTrigger key={i}>
          <Button
            className="outline-none"
            onPress={() => controller.setState({ versionId: v.id }) }>
            { v.id === version.id ? <DotFilledIcon /> : <DotIcon /> }
            { v.log && (
              <Tooltip
                placement="bottom"
                className="text-sm p-1 px-3 border border-rim bg-paper text-pencil max-w-[12rem]">
                {v.log}
              </Tooltip>
            )}
          </Button>
        </TooltipTrigger>
      ))}
    </div>
  )
}


function RefineBar({ controller }) {
  const
    isRefining = controller.state.get('isRefining'),
    refineError = controller.state.get('refineError');

  return (
    <div className="flex gap-4">
      <div className="pl-4 text-pencil2">
        {isRefining ? (
          <>Refining. Please Wait<Loader /></>
        ) : refineError ? (
          <div className="text-error">
            Error: {refineError}
            <Button
              className="ml-2"
              onPress={() => controller.setState({ refineError: null })}>
              <Cross2Icon />
            </Button>
          </div>
        ) : (
          <>
            Add feedbacks <ChatBubbleIcon className="inline mx-2" /> and submit.
          </>
        ) }
      </div>

      <div className="grow">
      </div>

      <TopButton onPress={() => {
        controller.refine()
      }} isDisabled={isRefining}>
        <CheckIcon className="inline" /> Submit
      </TopButton>
      <TopButton onPress={() => controller.setState({ mode: 'normal' })} isDisabled={isRefining}>
        <Cross2Icon className="inline" /> Cancel
      </TopButton>
    </div>
  )
}

const TopButton = (props) => (
  <Button
    {...props}
    size="small"
    borderClass=" "
    className={({ isDisabled }) => clsx(
      'gap-2 flex items-center outline-none',
      isDisabled && 'opacity-60'
    )} />
);