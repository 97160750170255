import { useControllerState } from 'lib/BaseController';
import { BarHorizontal, BarVertical } from './bars/bars';
import Inspector from 'modules/article/views/Inspector';
import DetailEditor from'./DetailEditor';
import Logo from 'lib/Logo';
import { Link } from 'react-aria-components';
import clsx from 'clsx';



export default function Switch({ editor, children }) {
  const
    { articleController, panels } = editor,
    articleState = useControllerState(articleController || null),
    panelState = useControllerState(panels);

  useControllerState(articleController || null);
  useControllerState(articleController?.selection || null);

  if (!articleController)
    return '';

  const error = articleState && articleState.get('error');
  if (error)
    return (<ErrorMode error={error}>{children}</ErrorMode>);

  const
    hudLayout = panelState.get('hudLayout'),
    Layout = hudLayout === '2' ? SideBarMode : RegularMode;

  return (<Layout editor={editor}>{children}</Layout>);
}

function RegularMode({ editor, children }) {
  return (
    <div className="h-full w-full relative casing-floating overflow-auto pt-[3rem]">
      <BarHorizontal editor={editor} />
      <Content editor={editor}>{children}</Content>
    </div>
  );
}

function SideBarMode({ editor, children }) {
  return (
    <div className="h-full w-full overflow-hidden relative flex">
      <BarVertical editor={editor} />        
      <div className="flex-grow">
        <Content editor={editor} vertical={true}>{children}</Content>        
      </div>
    </div>
  );
}


function Content({ editor, children, vertical }) {
  const
    sidebar = editor.panels.state.getIn(['expand', 'right']),
    detailId = sidebar && sidebar.startsWith('detail') && sidebar.substr(7),
    detailElement = detailId && editor.currentArticleController?.elements[detailId],
    className = (
      detailElement ? 'has-sidebar-lg' :
      sidebar === 'inspector' ? 'has-sidebar-sm' :
      ''
    );

  return (
    <div className={clsx('flex h-full group', className)} >
      <div className="flex-grow h-full overflow-hidden">
        {children}
      </div>
      
      <div className={clsx(
        'fixed bottom-4 right-4 z-20 max-w-full',
        !sidebar && 'pointer-events-none',
        vertical ? 'top-4' : 'top-[4.5rem]',
        'group-[.has-sidebar-sm]:w-[18rem]', // 18 + 3
        'group-[.has-sidebar-lg]:w-[39rem]', // 40 + 3
      )}>
        { 
          detailId ?
            (<DetailEditor editor={editor} detailId={detailId} />) :
            sidebar === 'inspector' ? (
              <div className="border border-rim bg-paper h-full">
                <Inspector editor={editor} />
              </div>
            ) : null
        }
      </div>
    </div>
  )
}

function ErrorMode({ children }) {
    return (
      <div className="py-16">
        <div className="text-center">
          <Link href="/" className="inline-block">
            <Logo className="w-16 h-16 text-pen" />
          </Link>
        </div>
        {children}
      </div>
    );
}