import { Link } from 'react-aria-components';
import Folder from './components/Folder';
import clsx from 'clsx';

export default function LeftPanel({ viewer }) {
  const panelLeft = viewer.state.get('panelLeft');
  
  return panelLeft && viewer.currentArticle && (
    <div className={clsx(
      'max-md:hidden',
      'fixed top-0 left-0 bottom-0 w-[20rem] border-rim bg-paper cursor-default pr-6 border-r bg-wax'
    )}>
      <FolderBar viewer={viewer} />
    </div>
  )
}

function FolderBar({ viewer }) {
  return (
    <div className="flex flex-col h-full overflow-auto flat-scrollbar border-r bg-paper scrollbar-auto-hide pl-6 pr-2">
      <div className="sticky top-0">
        <TitleBar viewer={viewer} />
      </div>
      <div className="pt-2 pb-6">
        <Folder viewer={viewer} />
      </div>
    </div>
  )
}

function TitleBar({ viewer }) {
  return (
    <div className="flex pt-6 pb-2 items-start bg-paper">
      <Link className="text-xl font-medium outline-none inline" href={'/' + viewer.workspace?.domain?.slug}>
        {viewer.workspace.domain.title}
      </Link>
    </div>
  )
}