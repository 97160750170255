import { useContext, useState } from 'react';
import AppContext from 'modules/app/Context';

import ChangePasswordController from 'modules/app/popups/ChangePassword/Controller';
import ChangeEmailController from 'modules/app/popups/ChangeEmail/Controller';
import ChangeUserInfoController from 'modules/app/popups/ChangeUserInfo/Controller';


export default function UserSettings() {
  const
    app = useContext(AppContext),
    state = app.auth.state,
    user = state.get('user');

  return (
    <div className="px-8 py-8">
      <div className="font-medium mb-8 text-center text-2xl">User Settings</div>
      <hr className="mb-4" />
      { user && (
        <>
          <div className="my-4">
            <Label>Display Name</Label> <br/>
            <LinkButton
              onClick={_ => app.popupManager.open(ChangeUserInfoController, { auth: app.auth })}>
              Change Name
            </LinkButton>
            {user.displayName || (<i>None</i>)}
          </div>
          
          <div className="my-4">
            <Label>Email</Label> <br/>
            <LinkButton
              onClick={_ => app.popupManager.open(ChangeEmailController, { auth: app.auth })}>
              Change Email
            </LinkButton>
            {user.email}
          </div>

          <div className="my-4">
            <Label>Password</Label> <br/>
            { app.auth.findProvider('google.com') ? (
              <span><i>Logged in with Google</i></span>
            ) : (
              <>
                <LinkButton
                  onClick={_ => app.popupManager.open(ChangePasswordController, { auth: app.auth })}>
                  Change Password
                </LinkButton>              
                *********
              </>
            ) }
          </div>

          <UserInfo app={app} />

          <EmailVerification app={app} />
        </>
      )}
    </div>
  )
}

function Label({ children }) {
  return (<label className="text-sm mb-4 font-medium">{children}</label>);
}

function LinkButton({ children, onClick }) {
  return (
    <button className="link-button float-right text-sm" onClick={onClick}>
      {children}
    </button>
  );
}


function EmailVerification({ app }) {
  const
    user = app.auth.user,
    [sendEmailStatus, setSendEmailStatus] = useState(null);
  
  
  function sendVerificationEmail() {
    setSendEmailStatus('sending');
    app.auth
      .sendEmailVerification()
      .then(_ => setSendEmailStatus('done'))
      .catch(e => {
        console.error(e);
        setSendEmailStatus('error')
      });
  }

  if (user.emailVerified)
    return null;
  
  return (
    <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 text-center">
    { sendEmailStatus === null ? (
      <>
        Your email address hasn't been verified. <br />
        Please check your inbox. <br />
        <span className="link-button" onClick={sendVerificationEmail}>
          Resend Verification Email
        </span> to {user.email}
      </>) :
      sendEmailStatus === 'error' ? 
      (<>There was an error. Please try again later!</>) :
        sendEmailStatus === 'sending' ?
        (<>Sending Verification Email... </>) :
        (<>Verification Email sent!</>)
    }
    </div>
  )
}


function UserInfo({ app }) {
  const
    auth = app.auth,
    userInfo = auth.state.get('userInfo');
  
  if (!userInfo)
    return '';

  return (
    <div>
      <hr className="my-3" />

      <div className="my-4">
        <Label>URL</Label> <br/>
        <div className="text-sm bg-wax p-2 font-mono">{userInfo.url || (<i>None</i>)}</div>
      </div>

      <div className="my-4">
        <Label>One Line Bio</Label>
        <div className="text-sm bg-wax p-2">{userInfo.bio || (<i>None</i>)}</div>

        <LinkButton
          onClick={_ => app.popupManager.open(ChangeUserInfoController, { auth: app.auth })}>
          Change
        </LinkButton>
        <div className="clear-both"></div>
      </div>
    </div>
  )
}