import { useState, useEffect } from 'react';
import { Button } from 'react-aria-components';
import clsx from 'clsx';
import { HeightResizer } from 'modules/elements/lib/components/resizers';
import MachineFiles from './MachineFiles';
import { LinkBreak2Icon } from '@radix-ui/react-icons';
import { useControllerState } from 'lib/BaseController';
import TerminalOutput from 'lib/TerminalOutput/View';


export default function Terminal({ controller, isGrid }) {
  const
    { getSettingsValue, setSettings } = controller,
    height = getSettingsValue('height'),
    autoHeight = getSettingsValue('autoHeight'),
    [drag, setDrag] = useState(null);

  return (
    <>
      <div
        className={clsx(
          'flex-grow min-h-10 font-code pt-2 pb-10 overflow-auto px-4',
          getSettingsValue('wordWrap') ? 'whitespace-pre-wrap' : 'whitespace-pre',
          isGrid && 'h-full'
        )}
        style={
          isGrid ? {} : autoHeight ? { maxHeight: `${height}px` } : {height : `${height}px` }
        }
        onClick={() => {
          if (!drag.isDragging)
            controller.terminalInputEl?.focus();
        }}
        onMouseDown={(e) => setDrag({ x: e.clientX, y: e.clientY, isDragging: false })}
        onMouseMove={(e) => {
          if (e.buttons !== 1 || !drag || drag.isDragging)
            return;
          if (Math.abs(e.clientX - drag.x) + Math.abs(e.clientY - drag.y) > 5)
            setDrag({...drag, isDragging: true });
        }}
        ref={el => controller.setTerminalEl(el)}>
        <Env controller={controller} />

        <TerminalOutput
          controller={controller.terminalOutput}
          processIsActive={controller.state.get('processIsActive')}
          setTerminalInputEl={controller.setTerminalInputEl}
          sendStdin={controller.sendStdin} />

        { !isGrid && (
          <HeightResizer
            onChange={(dy) => {
              let
                height = getSettingsValue('height') + dy,
                range = controller.settingsFields.height.range;
              height = Math.max(Math.min(height, range[1]), range[0])
              setSettings('height', height);
            }} />
        )}

        <MachineFiles controller={controller} />
      </div>
      
      <StatusIndicator controller={controller} />
      <OutputButtons controller={controller} />      
    </>
  )
}

function StatusIndicator({ controller }) {
  const
    processIsActive = controller.state.get('processIsActive'),
    status = controller.state.get('connectionStatus'),
    connectionErrored = processIsActive && status === 'disconnected';

  return (
    <div className="text-xs absolute bottom-2 left-4 bg-paper">
      { connectionErrored ? (
        <span className="text-red-600">
          <LinkBreak2Icon className="inline" /> Disconnected
        </span>
      ) : processIsActive ? (
        <span className="text-green-600">Running</span>
      ) : '' }
    </div>
  )
}

function OutputButtons({ controller }) {
  const
    { state, isReadOnly, terminalOutput } = controller,
    exceeded = terminalOutput.exceedsSizeLimit(),
    { history } = terminalOutput,
    savedHistory = state.get('savedHistory'),

    terminalIsEmpty = history.size === 0,
    savedIsEmpty = savedHistory.size === 0,

    isSavable = !exceeded && (history !== savedHistory) && !(savedIsEmpty && terminalIsEmpty),
    isRevertable = history !== savedHistory && !savedIsEmpty && !(savedIsEmpty && terminalIsEmpty),
    isClearable = !terminalIsEmpty;

  useControllerState(terminalOutput);

  return (
    <div className="text-xs absolute bottom-1 right-2 bg-paper">
      {!isReadOnly && (
        <OutputButton
          onPress={() => controller.saveOutput()}
          isDisabled={!isSavable}>
          { exceeded ? (<span>Cannot save &gt; 200kb</span>) : <span>Save</span> }
        </OutputButton>
      )}
      
      <OutputButton
        onPress={() => controller.revertOutput()}
        isDisabled={!isRevertable}>
        Load
      </OutputButton>

      <OutputButton
        onPress={() => controller.clearOutput()}
        isDisabled={!isClearable}>
        Clear
      </OutputButton>
    </div>
  )
}


function OutputButton(props) {
  return (
    <Button
      {...props}
      className={({ isDisabled }) => clsx(
        'uppercase p-1 mx-1',
        isDisabled && 'opacity-30'
      )} />);
}


function Env({ controller }) {
  const
    env = controller.getSettingsValue('env')?.toJS(),
    keys = env && Object.keys(env),
    [myKeys, setMyKeys] = useState(null);

  useEffect(() => {
    // can add check if eng has secret
    setMyKeys(controller.getMyKeys());
    window.addEventListener('my keys update', (e) => {
      setMyKeys(e.detail);
    });
  }, [controller]);


  return (
    <div
      className="font-mono mb-2 cursor-pointer text-pencil3"
      onClick={e => controller.openEnvPopup()}>
      {keys && keys.map((k, i) => (
        <div className="text-xs w-full break-words mt-1" key={i}>
          <span className="">{k}=</span><CheckedValue myKeys={myKeys} value={env[k]} />
        </div>
      ))}
    </div>
  )
}

function CheckedValue({ myKeys, value }) {
  const
    isSecret = value.startsWith('[#') && value.endsWith('#]'),
    v = isSecret ? value.substring(2, value.length - 2) : value,
    missing = isSecret && myKeys && !myKeys[v];

  return (
    <>
      <span
        className={clsx(
          isSecret && (missing ? 'text-error' : 'text-marker'),
          isSecret && 'opacity-80'
        )}>
        {v}
      </span>
    </>
  );
}