import { ui } from 'lib/popup';
import { useControllerState } from 'lib/BaseController';
import Home from './Home';


function ArticleAccess({ popup }) {
  useControllerState(popup);
  
  return (
    <ui.Window
      popup={popup}
      title="Article Access and Visibility">
      <Home popup={popup} />
    </ui.Window>
  );
}

// function Body({ popup }) {
//   let state = useControllerState(popup);

//   if (state.get('dataError'))
//     return (
//       <div className="text-red-6 p-4">
//         The user access list could not be loaded.<br />
//         Make sure you have the required permission and try again.
//       </div>
//     );

//   if (state.get('showNotice'))
//     return (<Notice onDone={() => popup.setState({ showNotice: false})} />)

//   if (state.get('dataLoading'))
//     return (<div className="p-3 text-center"><Loader /></div>);

//   return (
//     <div className="">
//       <UserAccessTable popup={popup} />
//       <AddAccessForm popup={popup} />
//     </div>
//   )
// }

export default ArticleAccess;