import { Button, Menu, MenuItem, MenuTrigger, Popover, Separator, OverlayArrow } from 'react-aria-components';
import { DragHandleDots2Icon, HamburgerMenuIcon, DotFilledIcon } from '@radix-ui/react-icons';
import Icon from 'lib/Icon';
import clsx from 'clsx';
import Arrow from 'lib/components/Arrow';
import { TrashIcon, MagicWandIcon, ArrowUpIcon, ArrowDownIcon } from '@radix-ui/react-icons';


export default function ElementMenu({ isOpen, onOpenChange, articleController, id }) {
  let options = [
    ['Delete', TrashIcon, () => articleController.popups.deleteElementPopup(id)],
    ['Use AI Assist', MagicWandIcon, () => {}],
  ];

  return (
    <MenuTrigger {...{isOpen, onOpenChange}} >
      <Button className="outline-none" preventFocusOnPress={true}>
        <DragHandleDots2Icon />
      </Button>

      <MenuBuilderPopOver {...{ options }} />
    </MenuTrigger>
  );
}

export function MenuBuilderPopOver({ options }) {
  return (
    <Popover className="min-w-36" placement="bottom bottom left">
      <Menu
        onAction={(i) => options[i][2] && options[i][2]()}
        className="outline-none"
        // className="bg-paper font-normal bg-paper shadow outline-none border border-rim rounded-sm"
        disabledKeys={[]}>
        { options.map(([caption, Icon, fn, props], i) => (
          <MenuItem
            id={i}
            key={i}
            className={({ isDisabled, isFocused }) => clsx(
              'block cursor-pointer text-sm outline-none flex flex-row-reverse gap-1 text-right p-1 text-marker3',
              isDisabled && 'pointer-events-none text-pencil3',
              isFocused ? 'font-medium' : 'bg-paper'
            )}>
            <div>
              <Icon className="inline" />
            </div>
            <div className="text-right">
              {caption}
            </div>
          </MenuItem>
        )) }
      </Menu>
    </Popover>
  )
}