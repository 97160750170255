import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import { checkIsAdmin } from 'api/remote/calls/auth';
import { editExamplePopup, addExamplePopup } from '../adminFns';
import Icon from 'lib/Icon';

import AppContext from 'modules/app/Context';
import { fetchExamples } from 'api/public';
import { faPlus, faPen } from '@fortawesome/free-solid-svg-icons';

function Featured() {
  const
    app = useContext(AppContext),
    [examples, setExamples] = useState(),
    [version, setVersion] = useState(0),
    [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => { fetchExamples().then(f => setExamples(f)) }, [version]);
  useEffect(() => { checkIsAdmin().then(a => setIsAdmin(a)) }, []);

  return (
    <div className="featured flex flex-col flex-1">
      <h4 className="text-2xl font-medium group/featured text-pencil">
        Featured Examples

        { isAdmin && (
          <button
            className="text-xs float-right group-hover/featured:block hidden"
            onClick={() => addExamplePopup(app, () => setVersion(version + 1))}>
            <Icon faIcon={faPlus} />
          </button>
        ) }
      </h4>

      <div className="examples">
        {  examples && examples.map((entry, i) => (
          <div key={i} className="entry mb-3 group">
            { isAdmin && (
              <button
                className="admin-button float-right text-xs group-hover/featured:block hidden"
                onClick={() => editExamplePopup(entry, app, () => setVersion(version + 1))}>
                <Icon faIcon={faPen} />
              </button>
            )}

            <Link className="font-bold my-1 text-pencil" to={entry.link}>
              {entry.title}
            </Link>
            <div className="text-sm text-pencil2">{entry.description}</div>
          </div>
        )) }
      </div>
    </div>
  )
}

export default Featured;