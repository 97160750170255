import BaseElementController from 'modules/elements/lib/BaseElementController';
import { fromJS } from 'immutable';
import settingsFields from '../Policies/settingsFields';
import TerminalOutput from 'lib/TerminalOutput/Controller';
import { Button } from 'react-aria-components';


class Controller extends BaseElementController {
  settingsFields = settingsFields;

  constructor(args) {
    super(args);
    this.machineInterface = this.getMachineInterface();
    const mi = this.machineInterface;

    this.terminalOutput = new TerminalOutput();

    this.unloadFns = [
      mi.subscribeDataStream(this.handleStream),
      mi.subscribeProcessEvent(this.handleProcess),
      mi.subscribeProcessIndex(this.handleProcessIndex),
      mi.subscribeConnectionStatus(this.handleConnectionStatus)
    ];
    this.checkProcessIsActive();
    this.terminalOutput.loadHistory(this.state.get('savedHistory'));
  }
  
  get elementFilenames() {
    if (this.getSettingsValue('filename'))
      return new Set([this.getSettingsValue('filename')]);
    return null;
  }

  getElementFile(filename) {
    if (filename !== this.getSettingsValue(filename))
      return;

    return ({
      filename: filename,
      getContent: () => this.terminalOutput.getFileContent(),
      contentType: 'text/plain',
      isBase64: false,
      isPulled: true
    });
  }

  setCommandInputEl = (el) => { this.commandInputEl = el; }
  setTerminalEl = (el) => { this.terminalEl = el; }
  setTerminalInputEl = (el) => { this.terminalInputEl = el; }

  focus() {
    if (this.state.get('processIsActive')) {
      this.terminalInputEl?.focus();
    } else {
      this.commandInputEl?.focus();
    }
  }

  handleStream = ({ type, data }) => {
    this.terminalOutput.push(data, type === 'error');
    // if (outputEl && outputEl.scrollHeight - outputEl.scrollTop <= outputEl.clientHeight + 1) {
    //   setTimeout(() => { outputEl.scrollTop = outputEl.scrollHeight }, 100);
    // }
  }

  handleProcess = ({ process, event, error }) => {
    if (event === 'start') {
      this.clearFetchStatus();
      this.terminalOutput.clear();
    } else if (event === 'error') {
      this.terminalOutput.processChunk(error, true);
    } else if (event === 'close') {
      this.fetchFiles();
    }
    this.checkProcessIsActive();
  }

  handleProcessIndex = () => { this.checkProcessIsActive(); }

  handleConnectionStatus = (status) => {
    this.setState({ connectionStatus: status });
  }

  
  clearOutput = () => this.terminalOutput.clear();
  saveOutput = () => {
    this.setState({ savedHistory: this.terminalOutput.history });
    this.save();
  }
  revertOutput = () => this.terminalOutput.loadHistory(this.state.get('savedHistory'));

  run = () => this.machineInterface.syncAndRun({
    command: this.state.get('settings').get('command'),
    isCommon: this.state.get('settings').get('isCommon'),
    env: this.getEnv()
  });
  kill = () => this.machineInterface.kill();
  
  checkProcessIsActive = () => {
    const processIsActive = this.machineInterface.processIsActive();
    if (processIsActive !== this.getState().get('processIsActive'))
      this.setState({ processIsActive });
  }

  destroy = () => {
    this.unloadFns.forEach(fn => fn());
    this.unloadFns = [];
  }

  fetchFiles = async () => {
    for (let child of this.getChildren()) {
      const { elementId } = child;
      this.setFetchStatus(elementId, 'loading');
      try {
        await this.fetchFile(child);
        this.setFetchStatus(elementId, 'loaded');
      } catch (e) {
        console.error(e);
        this.setFetchStatus(elementId, 'error');
        this.setFetchStatus('error' + elementId, e.message);
      }
    }
  }

  fetchFile = async (child) => {
    const
      filename = child.getSettingsValue('filename'),
      response = await this.machineInterface.fetchFile(child.getSettingsValue('filename'));

    // await new Promise(r => setTimeout(r, 2000));
    if (response.status === 404) {
      throw new Error('404. Not found.');
    } else if (response.status !== 200) {
      throw new Error('Load error.');
    }
    let
      reader = new FileReader(),
      blob = await response.blob(),
      dataURL = await new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.error);
        reader.readAsDataURL(blob);
      }),
      [header, content] = dataURL.split(','),
      contentTypeMatch = dataURL.match(/^data:(.*?);/),
      contentType = contentTypeMatch ? contentTypeMatch[1] : null,
      isBase64 = header.includes(';base64'),
      isPulled = true,
      settings = child.state.get('settings').merge({ contentType, isBase64, isPulled });

    if (!isBase64) {
      content = decodeURIComponent(content);
    }
    // console.log(contentType, isBase64, isPulled);
    // check for limit exceeded
    child.setState({ content, settings });
    child.save();
    this.commonEmitter.emit(`file update ${filename}`);
  }

  clearFetchStatus = () => {
    let m = {};
    for (let child of this.getChildren()) { m[child.elementId] = 'init'; }
    this.setState({ fetchStatus: fromJS(m) })
  }

  setFetchStatus = (elementId, value) => this.setState({
    fetchStatus: this.state.get('fetchStatus').set(elementId, value)
  });

  sendStdin = (message) => {
    this.machineInterface.sendStdin(message);
  }

  openEnvPopup = () => {
    const env = this.getSettingsValue('env')?.toJS() || {};

    this.openPopup('prompt', {
      title: 'Edit Environment Variables',
      inputs: [{
        label: 'Environment Variables',
        type: 'key-value',
        defaultValue: env,
        autoFocus: false
      }],
      content: (
        <div className="text-sm border py-2 px-2 mb-4">
          <span className="font-medium">Note:</span> If you'd like to use non-public keys,
          use square bracket and hash delimeter. eg. <span className="text-xs font-mono bg-wax">[#SOME_API_KEY#]</span>
          <br /><br />
          The non-public key-value can be added at&nbsp;
          <Button
            className="underline font-medium"
            onPress={() => this.openPopup('my key manager')}>my key manager</Button>.
        </div>
      ),
      submitLabel: 'Save',
      onSubmit: ([env]) => {
        this.setSettings('env', fromJS(env));
      }
    });
  }

  getMyKeys = (key) => {
    try {
      return JSON.parse(localStorage.getItem('myKeys') || '{}')
    } catch (e) {
      return {}
    }
  }

  getEnv = () => {
    const env = this.getSettingsValue('env')?.toJS() || {};
    const myKeys = this.getMyKeys();
    const r = {}
    for (let k in env) {
      let v = env[k];
      if (v.startsWith('[#') && v.endsWith('#]')) {
        v = v.substring(2, v.length - 2)
        v = myKeys[v] || env[k]
      }
     r[k] = v;
    }
    return r;
  }
}

export default Controller;