import { useState } from 'react';
import startDragging from 'lib/startDragging';
import clsx from 'clsx';

export function HeightResizer({ onChange, onDrag }) {
  return (<Resizer {...{onChange, onDrag}} />);
}

export function WidthResizer({ onChange, onDrag }) {
  return (<Resizer {...{onChange, onDrag}} w={true} />);
}


export function Resizer({ onChange, onDrag, w }) {
  const
    [dragging, setDragging] = useState(false),
    [d, setD] = useState(0);

  onDrag = onDrag || (() => {});
  return (
    <div
      className={clsx(
        w && 'h-[calc(100%+0.5rem)] absolute w-[6px] -top-1',
        !w && 'w-[calc(100%+0.5rem)] absolute h-[6px] -left-1',
        'bg-black z-10',
        dragging ? 'opacity-30' : 'opacity-0 hover:opacity-20'
      )}
      style={(
        !w ?
          ({ bottom: -d - 4, cursor: 'ns-resize' }) :
          ({ right: -d - 4, cursor: 'ew-resize' })
      )}
      onMouseDown={(e) => {
        setDragging(true);
        onDrag(true);
        startDragging({
          event: e,
          onMove: (e, dx, dy) => { setD(w ? dx : dy); },
          onEnd: (e, dx, dy) => {
            onChange(w ? dx : dy);
            setD(0);
            setDragging(false);
            onDrag(false);
          }
        });
      }} />);
}