import LocalFiles from 'modules/article/controllers/files/LocalFiles';
import RemoteFiles from 'modules/article/controllers/files/RemoteFiles';
import Emitter from 'lib/Emitter';

import Layout from 'modules/layout/Controller';
import { fromJS } from 'immutable';
import Element from './Element';

const noop = () => {};

export default class Article {
  constructor({ version, elements, app, provider, themeData, expandDetail }) {
    this.app = app;
    const { article, uploads } = version;
    this.version = version;
    this.article = article;
    this.uploads = uploads;
    this.themeData = themeData;
    this.provider = provider;
    this.commonEmitter = new Emitter();
    this.state = fromJS({ article: this.article});
    this.expandDetail = expandDetail;

    // Layout
    this.layout = new Layout({
      getId: () => this.article.id,
      state: this.state,
      setState: noop,
      save: noop,
      openPopup: noop,
      popups: {
        newElementPopup: noop,
        openPopup: noop,
      },
      domain: { themeData: themeData },
      selection: noop,
      readOnly: true,
      themeData: this.themeData,
    });

    // Elements
    this.elementControllers = {};
    for (let key in elements) {
      let data = ({ id: key, ...elements[key] });
      this.elementControllers[key] = new Element(data, this);
    }

    // Files
    this.localFiles = new LocalFiles({
      getUploads: () => {
        const r = [];
        for (let id in this.uploads) {
          r.push({ id: id, ...this.uploads[id] });
        }
        return fromJS(r);
      },
      getElements: () => this.elementControllers,
      getProvider: () => provider
    });
    this.remoteFiles = new RemoteFiles({
      getProvider: () => provider,
      getLocalPath: () => this.path
    })
  }

  unload() {
  }
}