import { useControllerState } from 'lib/BaseController';
import { useState } from 'react';
import clsx from 'clsx';


const TerminalOutput = ({ controller, prefix, processIsActive, sendStdin, setTerminalInputEl }) => {
  const segments = controller.getSegments();
  useControllerState(controller);

  return (
    <>
      {segments.map((line, lineIndex) => (
        <span key={lineIndex}>
          {line.map((segment, segmentIndex) => (
            <span
              key={segmentIndex}
              className={controller.getStyles(segment)}>
              {segment.text}
            </span>
          ))}
          { lineIndex !== segments.length - 1 && <br /> }
        </span>
      ))}
      {prefix}<TerminalInput {...{ processIsActive, sendStdin, setTerminalInputEl }} />
    </>
  );
};


function TerminalInput({ processIsActive, sendStdin, setTerminalInputEl }) {
  const
    [value, setValue] = useState(''),
    [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <span className={clsx(!processIsActive && 'hidden', 'text-top')}>
        <span className="stdin">{value}</span>
        <span className="">{isFocused ? '■' : '□' }</span>
      </span>

      <input
        ref={(el) => setTerminalInputEl(el)}
        className={clsx(!processIsActive && 'hidden', 'w-0')}
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={e => {
          setValue(e.currentTarget.value);          
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            sendStdin(value + '\n');
            setValue('');
            e.preventDefault();
          } else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            e.preventDefault();
            sendStdin('\x03');
          }
        }} />
    </>
  )
}

export default TerminalOutput;