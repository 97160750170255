import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './style.css';

const fontAwesomeDict = {}
const svgDict = {}
const pngDict = {}



export default function Icon({ type, faIcon, className }) {
  if (faIcon)
    return <FontAwesomeIcon icon={faIcon} className={className} />

  if (fontAwesomeDict['fa' + type]) {
    return (<FontAwesomeIcon className={className} icon={fontAwesomeDict['fa' + type]} />);
  }

  if (svgDict[type]) {
    const Comp = svgDict[type];
    return (<span className="svg-icon"><Comp /></span>);
  }

  if (pngDict[type]) {
    return (
      <span className="img-icon">
        <img src={pngDict[type]} style={{ height: '1em' }} alt="icon" />
      </span>
    );
  }

  console.error('Icon not found ' + type);
  return ('-', type)
}