import { Break, Block, Label } from 'modules/elements/lib/sidebar/inputs/components';
import { Buttons, Button } from 'modules/elements/lib/sidebar/inputs/buttons';


export default function EnvironmentVariables({ controller }) {
  const
    env = controller.getSettingsValue('env')?.toJS(),
    keys = env && Object.keys(env);
  
  return (
    <>
      <Break />
      <Block>
        <Label>Environment Variables</Label>
        <div className="pt-1 px-2 font-mono text-sm w-full">
          {(!keys || keys.length === 0) && (
            <div className="text-sm opacity-50 py-1">None</div>
          )}
          {keys && keys.map((k, i) => (
            <div className="text-xs w-full break-words mt-1" key={i}>
              <span className="bg-wax">{k}</span>: <span className="bg-wax">{env[k]}</span>
            </div>
          ))}
        </div>

        <Buttons className="text-right">
          <Button onPress={e => controller.openEnvPopup()}>Edit</Button>
        </Buttons>
      </Block>
    </>
  )
}