import StorageImage from 'lib/StorageImage';
import { Link } from 'react-aria-components';
// import { Button } from 'lib/inputs/buttons';
import { CardStackIcon } from '@radix-ui/react-icons'
import { clsx } from 'clsx';
import ItemTitle from './common/ItemTitle';


export default function Design({ viewer }) {
  const
    { workspace } = viewer,
    { domain } = workspace;

  return (
    <div className="md:p-8 h-full overflow-auto flat-scrollbar">
      <div className="bg-paper md:border border-rim max-w-[60rem] mx-auto p-16">
        <div className="text-center">
          {domain.thumbnail ? (
            <>
              <div className="max-w-[18rem] w-full mb-8 mx-auto">
                <StorageImage
                  className=""
                  path={domain.thumbnail['640x640']}
                  getDownloadURL={viewer.provider.getDownloadURL} />
              </div>

              <div className="text-5xl font-bold mb-4 text-pencil">
                {domain.title}
              </div>
            </>
          ) : (
            <>
              <div className="text-6xl font-bold mb-6 text-pencil">
                {domain.title}
              </div>
            </>
          ) }

          <div className="w-16 h-1 bg-pencil2 mb-4 mx-auto"></div>

          { domain.description && (
            <div className="text-md mb-4 text-pencil2  max-w-[32rem] mx-auto">
              <div ref={el => { if (el) el.innerHTML = domain.description; }}></div>
            </div>
          ) }

          { domain.author && (
            <div className="text-md mb-8 text-pencil2 font-medium">
              By&nbsp; {domain.author}
            </div>
          ) }
        </div>

        <div className="">
          <FolderContent viewer={viewer} />
        </div>
      </div>
    </div>
  )
}


function FolderContent({ viewer }) {
  const sections = viewer.workspace.sections.filter(s => s.items.length > 0);

  return (
    <div className="pt-12 border-t border-rim grid gap-6 lg:grid-cols-3 md:grid-cols-2">
      { sections.map((section, i) => section.items.length > 0 && (
        <div className="" key={i}>
          { section.title && (
            <div className="mb-2 flex gap-4 text-lg font-medium">
              {section.title}
            </div>
          ) }

          <div className="flex flex-col">
            {section.items.map(item => (
              <FolderItem
                key={item.id}
                item={item}
                section={section}
                viewer={viewer} />
            ))}
          </div>
        </div>
      )) }
    </div>
  )
}

export function FolderItem({ item, section, viewer }) {
  const { path, type } = item;

  return (
    <Link
      className="outline-none"
      href={'/' + path.replace('\\', '/')}>
      <div
        className={clsx(
          'py-0.5',
          'font-normal gap-4 flex',
          'transition-all duration-100 hover:translate-x-0.5 text-marker3',
        )}>
        <div className="flex-grow"><ItemTitle item={item} viewer={viewer} /></div>
        {type === 'folder' && (
          <div><CardStackIcon /></div>
        )}
      </div>
    </Link>
  );
}