import { fromJS } from 'immutable';
import { initialSettings } from './settingsFields';


export default class Policies {
  static autoSave = true;

  static getInitialData() {
    return {
      settings: initialSettings,
      savedHistory: []
    }
  }

  static dataToState(data, currentState) {
    if (!currentState)
      currentState = fromJS({ output : null, fetchStatus: {} });

    return (
      currentState.merge(fromJS({
        settings: data.settings,
        savedHistory: data.savedHistory || [],
      }))
    );
  }

  static stateToData(state) {
    const { savedHistory, settings } = state.toJS();
    return { savedHistory, settings };
  }

  static contentHasChanged(newState, oldState) {
    return (
      newState.get('settings') !== oldState.get('settings') ||
      newState.get('savedHistory') !== oldState.get('savedHistory')
    )
  }

  static getDataUpdates(newState, oldState) {
    var r = {};
    if (newState.get('settings') !== oldState.get('settings'))
      r['settings'] = newState.get('settings').toJS();
    if (newState.get('savedHistory') !== oldState.get('savedHistory'))
      r['savedHistory'] = newState.get('savedHistory').toJS();
    if (newState.get('filenames') !== oldState.get('filenames'))
      r['filenames'] = newState.get('filenames').toJS();
    if (newState.get('contents') !== oldState.get('contents'))
      r['contents'] = newState.get('contents').toJS();
    return r;
  }
}