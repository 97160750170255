import { filenameRegex } from 'lib/regexps';

export function getSizeString(size: number): string {
  var units = ['bytes', 'kB', 'MB', 'GB', 'TB'];
  for (var i = 0; size > 1024; i++) {
    size = size / 1024;
  }
  return Number(size).toFixed(2) + ' ' + units[i];
}

export const validateFilename = (val: string) => {
  if (typeof val !== 'string')
    return 'Not a string';
  if (val !== '' && !filenameRegex.test(val))
    return 'Control characters are not allowed';
  if (val.length > 30)
    return 'Filename should be less than 30 chars';
}

export const getDayStamp = () => (new Date()).toLocaleString('en-US', { 
  timeZone: 'America/New_York',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
}).replace(/\//g, "-");


export const toRoman = (num: number) => {
    if (isNaN(num))
        return NaN;

    var digits = String(+num).split(''),
        key = ['','C','CC','CCC','CD','D','DC','DCC','DCCC','CM',
               '','X','XX','XXX','XL','L','LX','LXX','LXXX','XC',
               '','I','II','III','IV','V','VI','VII','VIII','IX'],
        roman = '',
        i = 3;

    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || '') + roman;

    return Array(+digits.join('') + 1).join('M') + roman;
}