import Page from 'modules/layout/View/Page';
import { getRenderedElements } from './Elements';
import TitleElement from './TitleElement';
import { useControllerState } from 'lib/BaseController';


function Doc({ articleController, hidden }) {
  const
    { selection, keyManager, layout } = articleController,
    colorSchemeManger = articleController.app.colorScheme,
    colorScheme = colorSchemeManger.getSchemeValue(),
    renderedElements = getRenderedElements({ articleController, colorScheme }),
    titleElement = (
      <TitleElement
        articleController={articleController}
        onInputEl={el => articleController.titleElement.setElement(el)} />
    );

  useControllerState(colorSchemeManger);

  return (
    <>
      <input
        className="w-0 h-0 overflow-hidden fixed opacity-0 pointer-events-none"
        ref={el => {
          selection.setFocusPageFn(() => el.focus())
          selection.setBlurPageFn(() => el.blur())
        }}
        onFocus={() => { selection.handlePageFocus(); keyManager.resetControlKeys(); }}
        onBlur={() => { selection.handlePageBlur(); keyManager.resetControlKeys(); }}
        onKeyDown={keyManager.handleInputKeyDown}
        onKeyUp={keyManager.handleInputKeyUp} />
      
      <Page
        articleController={articleController}
        layout={layout}
        hidden={hidden}
        titleElement={titleElement}
        renderedElements={renderedElements}
        selection={selection} />
    </>
  );
}

export default Doc;