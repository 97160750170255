import { useState } from 'react';
import { Button, Form, Input } from 'react-aria-components';
import Loader from 'lib/staticPages/Loader';
import { TrashIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { DotFilledIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';


export default function Users({ popup }) {
  const
    users = popup.state.get('users'),
    error = popup.state.get('error'),
    linkClassName = 'font-medium text-marker3 outline-none',
    [isAdding, setIsAdding] = useState(false),
    [email, setEmail] = useState('');

  return (
    <div>
      { users === null ? (
        <Loader />
      ) : users.size === 0 ? (<>
        <span className="italic">None</span>&nbsp;
      </>) : (
        <div className="flex flex-col gap-1 my-1">
          {users.map((user, i) => (
            <div className="flex gap-2" key={i}>
              <span className="pt-1">
                <DotFilledIcon />                
              </span>
              <span key={user.get('id')} className="text-sm">{user.get('id')}</span>
              <Button
                className={linkClassName}
                onPress={() => popup.deleteAccess({ email: user.get('id') })}>
                <TrashIcon className="size-4" />
              </Button>
            </div>
          ))}
        </div>
      )}
      

      { !isAdding && (
        <Button className={clsx(linkClassName)} onPress={e => setIsAdding(true)}>
          <PlusIcon className="size-4 inline" /> add user
        </Button>
      ) }
      { isAdding && (
        <Form
          className="my-2 flex gap-4"
          onSubmit={e => { e.preventDefault(); popup.addAccess({ email }); setEmail(''); setIsAdding(false); }}>
          <Input
            type="email"
            placeholder="email"
            value={email}
            onChange={e => setEmail(e.currentTarget.value)}
            autoFocus={true}
            className="outline-none border-b px-2" />
          <Button className={linkClassName} type="submit">add</Button>
          <Button className={linkClassName} onPress={e => setIsAdding(false)}>cancel</Button>
        </Form>
      ) }
      { error && (
        <div className="mt-2">
          <div className="text-error inline">{ error }</div>
          <Button onPress={() => popup.setState({ error: '' })}>
            <XMarkIcon className="size-4 inline" />
          </Button>
        </div>
      )}
    </div>
  );
}