import BaseSelection from 'lib/inputs/BaseSelection';


export default function Topbar({ popup }) {
  return (
    <div className="flex py-4 px-8">
      <Count popup={popup} />
      <div className="flex-grow"></div>
      <SortInput popup={popup} />
    </div>
  )
}

function Count({ popup }) {
  const { domain, articleId } = popup.args;

  let count, plus;
  if (!articleId) {
    count = (domain.commentsCount || 0) + popup.state.get('countDiff');
    plus = '';
  } else {
    count = (popup.state.get('list') || []).length;
    plus = (popup.state.get('nextCursor') ? '+' : '');    
  }

  return (
    <div className="font-medium inline flex-grow text-pencil text-sm">
      {count === 0 ? 'No' : count}{plus} Comment{ count > 1 && 's'}
    </div>
  )
}

function SortInput({ popup }) {
  const
    sortBy = popup.state.get('sortBy');

  return (
    <div className="inline-flex items-center text-sm gap-2">
      <div className="font-bold">Sort by:</div>
      <BaseSelection
        value={sortBy}
        label="Sort By"
        autoFocus={false}
        options={[
          { key: 'createdAt', caption: 'Newest First' },
          { key: 'likesCount', caption: 'Most Likes' },
        ]}
        buttonClassName="gap-2"
        onChange={v => { popup.setState({ sortBy: v }); popup.fetch(); } } />
    </div>
  )
}