export default class Interface {
  constructor(machine) {
    this.machine = machine;
  }

  getProxy({ elementId, docId, docPath, getLocalFiles }) {
    return {
      subscribeDataStream: cb => this.machine.emitter.on(`data.${elementId}`, cb),
      subscribeProcessEvent: cb => this.machine.emitter.on(`process.${elementId}`, cb),
      subscribeProcessIndex: cb => this.machine.emitter.on(`process.index`, cb),
      subscribeConnectionStatus: cb => {
        cb(this.machine.state.get('status'));
        return this.machine.emitter.on(`connection`, cb);
      },

      syncAndRun: ({ command, isCommon, env }) => this.syncAndRun({
        command, isCommon, elementId, docId, docPath, getLocalFiles, env }),
      run: ({ command, isCommon, env }) => this.run({
        command, isCommon, elementId, docId, docPath, env
      }),
      processIsActive: _ => this.getActiveProcessByElementId(elementId) ? true : false,
      kill: _ => this.killActiveProcessByElementId(elementId),
      fetchFile: (filename) => this.machine.diskSync.fetchFile({ docId, filename }),
      sendStdin: (message) => this.sendStdin(elementId, message)
    }
  }

  syncAndRun({ command, isCommon, elementId, docId, docPath, getLocalFiles, env }) {
    const
      autoSyncAndClose = true,
      onClose = synced => {
        if (synced) {
          this.run({ command, isCommon, elementId, docId, docPath, env })
        }
      };
    this.machine.showDiskSyncPopup({
      docId, docPath, getLocalFiles,
      onClose, autoSyncAndClose
    });
  }

  run({ command, isCommon, elementId, docId, docPath, env }) {
    this.machine.startProcess({ command, isCommon, elementId, docId, docPath, env });
  }


  getActiveProcessByElementId(elementId) {
    const processes = this.machine.state.get('processes');
    return (
        this.machine.state.get('status') === 'connected' &&
        processes &&
        processes.find(v => v.caller.elementId === elementId && v.status === 'running')
    );
  }

  killActiveProcessByElementId(elementId) {
    const process = this.getActiveProcessByElementId(elementId);
    if (process)
      this.machine.killProcess(process.id);
  }

  sendStdin(elementId, message) {
    const process = this.getActiveProcessByElementId(elementId);
    if (process)
      this.machine.sendStdin(process.id, message);
  }
}