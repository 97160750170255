const defaultTheme = {
  // colors
  // color: '#000000',
  // backgroundColor: '#ffffff',
  linkColor: '#1e40af',  
  borderColor: '#e5e7eb',
  
  navColor: '#000000',
  navBackgroundColor: '#ffffff',

  // font families
  // font: 'Merriweather',
  // headerFont: 'Raleway',
  // cursiveFont: 'Kaushan+Script',

  font: 'Source+Sans+3',
  headerFont: 'Source+Sans+3',
  cursiveFont: 'Kaushan+Script',

  // page style
  titleAlignment: 'center',
  pageWidth: 'normal',
  fontSize: '16',
  lineSpacing: '1.5',
  headerFontWeight: '400',

  // pageBorderType: 'none', // 'box', 'margin'
  pageMargin: { left: 0, right: 0, top: 0, bottom: 0, },
  // pagePadding: 8,

  // code
  // codeFont: 'System',
  codeFont: 'Fira+Code',
  codeThemeLight: 'plain',
  codeThemeDark: 'githubDark',
  codeFontSize: '13',
  codeLineHeight: '18px',
  codeHasLineNumbers: false,
  // codeBorder: 'full',
  // codeBorderColor: '#eeeeee',
  coverPageLayout: 'blog-like',
  coverPageHasImage: false,
}


export default defaultTheme;