import { useState } from 'react';
import { useControllerState } from 'lib/BaseController';

import p from 'lib/popup/components/ui';
import {
  Group, TextInput, TextAreaInput, Checkbox, Switch,
  NativeSelection, Selection, CardSelect, RadioSelect,
} from 'lib/inputs/basic';
import KeyValueInput from 'lib/inputs/KeyValueInput';
import { Buttons, Button } from 'lib/inputs/buttons';

import './style.css';

function Popup({ popup }) {
  const
    state = useControllerState(popup),
    [locked, setLocked] = useState(false),
    { inputs, inputValues } = popup;

  return (
    <p.Popup popup={popup} className="prompt">
      <p.Dialog>
        <p.TitleBar>
          <p.Title>{state.get('title')}</p.Title>
          <p.CloseButton />
        </p.TitleBar>
        <p.Body>
          <form onSubmit={e => { e.preventDefault(); popup.handleSubmit(); }}>
            {state.get('question') && <p.Question>{state.get('question')}</p.Question>}
            {popup.args.content}
            <Group>
              {inputs.map((input, i) => {
                const isHidden = input.isHiddenFn ? input.isHiddenFn({ inputValues }) : input.isHidden;
                console.log(isHidden)

                const props = {
                  label: input.label,
                  autoFocus: input.autoFocus,
                  value: state.get('inputValue' + i),
                  onChange: v => popup.setState({ ['inputValue' + i]: v }),
                  isDisabled: input.isDisabledFn ? input.isDisabledFn({ inputValues }) : input.isDisabled
                };

                return !isHidden && (
                  (input.type === 'text' || input.type === 'number') ? (
                    <TextInput
                      key={i}
                      {...props}
                      type={input.type}
                      placeholder={input.placeholder}
                      spellCheck={input.spellCheck === true}
                      unit={input.unit}
                      isFontMono={input.isFontMono} />
                  ) :
                  input.type === 'textarea' ? (
                    <TextAreaInput
                      key={i}
                      {...props}
                      spellCheck={input.spellCheck === true} />
                  ) : 
                  input.type === 'checkbox' ? (
                    <Checkbox
                      key={i}
                      {...props}
                      isYesNo={input.isYesNo}
                     /> 
                  ) :
                  input.type === 'select' ? (
                    <NativeSelection
                      key={i}
                      {...props}
                      options={input.options} />
                  ) :
                  input.type === 'select2' ? (
                    <Selection
                      key={i}
                      {...props}
                      options={input.options} />
                  ):

                  input.type === 'card-select' ? (
                    <CardSelect
                      key={i}
                      {...props}
                      align={input.align}
                      itemClassName={input.itemClassName || 'text-center w-20 p-2 text-center text-sm outline-none'}
                      options={input.options} />
                  ) :

                  input.type === 'radio' ? (
                    <RadioSelect
                      key={i}
                      {...props}
                      align={input.align}
                      options={input.options} />
                  ):

                  input.type === 'switch' ? (
                    <Switch
                      key={i}
                      {...props} />
                  ) :

                  input.type === 'key-value' ? (
                    <KeyValueInput
                      key={i}
                      setLocked={setLocked}
                      {...props} />
                  ) : ''
                );
              })}
            </Group>

            <p.Alert />

            <Buttons className="text-right">
              <Button
                type="submit"
                isDisabled={state.get('loading') || locked}
                autoFocus={inputs.length === 0}>
                { state.get('submitLabel') || 'Submit' }
              </Button>
              {popup.args.hasCancel !== false && (
                <Button onPress={e => { popup.close(true) }}>
                  { state.get('cancelLabel') || 'Cancel' }
                </Button>
              )}
            </Buttons>
          </form>
        </p.Body>
      </p.Dialog>
    </p.Popup>
  )
}

export default Popup;