import { fromJS } from 'immutable';
import { initialSettings } from './settings';

export default class Policies {
  static autoSave = true;
  
  static getInitialData() {
    return {
      version: 0,
      settings: initialSettings,
      entries: [{ filename: '', caption: '' }]
    }
  }

  static dataToState(data, currentState) {
    if (!currentState)
      currentState = fromJS({ at: 0, tab: 'entries' });

    return (
      currentState
        .set('entries', fromJS(data.entries))
        .set('settings', fromJS(data.settings))
    );
  }

  static stateToData(state) {
    return {
      settings: state.get('settings').toObject(),
      entries: state.get('entries').map(e => e.toObject()).toArray()
    }
  }

  static contentHasChanged(newState, oldState) {
    return (
      newState.get('settings') !== oldState.get('settings') ||
      newState.get('entries') !== oldState.get('entries')
    )
  }
}