import { useState } from 'react';
import Icon from 'lib/Icon';
import { Button } from 'react-aria-components';
import { LinkBreak2Icon, ExternalLinkIcon, LaptopIcon, ChevronRightIcon, UpdateIcon } from '@radix-ui/react-icons';
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import { useControllerState } from 'lib/BaseController';
import { BarButton, Break } from './res/components';
import { DialogTrigger, Popover } from 'react-aria-components';
import { Laptop2Icon } from './res/icons';



export default function MachineButton({ machine, editor, withBreak, verticle }) {
  const
    state = useControllerState(machine),
    [isOpen, setOpen] = useState(false);

  return state.get('status') === 'connected' && (
    <>
      { withBreak && (<Break verticle={verticle} />) }

      <DialogTrigger>
        <BarButton
          Icon={Laptop2Icon}
          tooltip="Remote Machine, Connected"
          fn={() => setOpen(true)} />

        <Popover isOpen={isOpen} onOpenChange={setOpen}>
          <MachineLeaf
            machine={machine}
            setOpen={setOpen}
            articleController={editor.currentArticleController} />
        </Popover>
      </DialogTrigger>
    </>
  );
}


function MachineLeaf({ machine, articleController, setOpen }) {
  const
    machineInfo = machine.state.get('machineInfo'),
    docId = articleController.id,
    docPath = articleController.state.getIn(['article', 'path']).replaceAll( '\\', '/');

  return (
    <div className="bg-paper border border-rim w-[12em] px-4 py-4 flex flex-col gap-2">
      <div className="text-center">
        <div className="text-xl"><Icon faIcon={faLaptopCode} /></div>
        {machineInfo && (<div className="text-sm text-marker3">{machineInfo.hostname}</div>)}
      </div>

      <div className="border-b border-rim w-full"></div>

      <MenuButton
        onPress={() => {
          setOpen(false);
          machine.showMachinePopup();
        }}
        Icon={LaptopIcon}>
        Processes
      </MenuButton>

      <MenuButton
        onPress={() => {
          setOpen(false);
          machine.showDiskSyncPopup({
            docId,
            docPath,
            getLocalFiles: () => articleController.localFiles.proxy
          });
        }}
        Icon={UpdateIcon}>
        Files Sync
      </MenuButton>

      <MenuButton
        onPress={() => {
          setOpen(false);
          machine.browseFilesPopup({
            docId,
            docPath,            
          })
        }}
        Icon={ExternalLinkIcon}>
        Browse Files
      </MenuButton>

      <MenuButton
        onPress={() => {
          setOpen(false);
          machine.showTerminalPopup({ docId, docPath });
        }}
        Icon={ChevronRightIcon}>
        Terminal
      </MenuButton>

      <div className="border-b border-rim w-full"></div>

      <MenuButton
        onPress={() => {
          setOpen(false);
          machine.close();
        }}
        Icon={LinkBreak2Icon}>
        Disconnect
      </MenuButton>
    </div>
  );
}

function MenuButton({ Icon, onPress, children }) {
  return (
    <Button {...{ onPress }} className="px-4 py-1 text-sm hover:bg-wax2 w-full text-left border">
      <Icon className="inline-block mr-2" /> {children}
    </Button>
  );
}