import { useState, useEffect } from 'react';
import { getDownloadURL as getDownloadURL_ } from 'api/public';


export default function StorageImage({
  path, style, className, placeholder,
  children, getDownloadURL, backgroundColor,
  tilePlaceholder, aspectRatio=1
}) {
  const
    [url, setUrl] = useState(null),
    [error, setError] = useState(null);
  getDownloadURL = getDownloadURL || getDownloadURL_;

  useEffect(() => {
    if (path)
      getDownloadURL(path)
        .then(u => setUrl(u))
        .catch(e => { console.error(e); setError(e.message) });
  }, [path, getDownloadURL]);

  style = {
    ...style,
    width: '100%',
    paddingBottom: `${Math.floor(100 / aspectRatio)}%`,
    position: 'relative',
    backgroundColor: backgroundColor || '#eee'
  };

  let imageUrl = url || placeholder;
  if (imageUrl) {
    style = { ...style, backgroundImage: `url(${imageUrl})` }
  }

  if (!url && placeholder && tilePlaceholder) {
    style = {...style,
      backgroundSize: 'auto',
      backgroundRepeat: 'repeat',
    }
  } else {
    style = {...style,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    }
  }

  return (
    <div className={className} style={style}>
      {error && <div className="text-pencil text-center pt-4">Error Loading Image!</div>}
      {!error && children}
    </div>
  )
}