import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { fetchDemos } from 'api/public';
import { editDemoPopup, editTextPopup } from '../adminFns';
import { Frame } from '../misc/components';
import StorageImage from 'lib/StorageImage';
import placeholder from './placeholder/struckaxiom.png';
import { Button } from 'react-aria-components';
import { Pencil1Icon, PlusIcon } from '@radix-ui/react-icons';

import clsx from 'clsx';

export default function Collection({ isAdmin, app }) {
  const
    [demos, setDemos] = useState([]),
    [version, setVersion] = useState(0),
    update = () => setVersion(version + 1);

  useEffect(() => { fetchDemos(10).then(r => setDemos(r)) }, [version]);

  return (
    <Frame className="demo-index-page max-w-[54rem] p-8 flex flex-col gap-4">
      <div className="text-2xl font-medium">Quick Demos</div>
      
      <div className="flex-grow overflow-auto">
        <div className="mb-8">Demos made here. Curated every week. Click and run.</div>
        <div className="cards my-4 grid gap-x-6 gap-y-3 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {demos.map((demo, i) => (
            <div className="group" key={i}>
              <Link to={'./' + demo.id} className="">
                <StorageImage
                  path={demo.thumbnailPath}
                  className="image relative bg-striped"
                  placeholder={placeholder}
                  tilePlaceholder={true}
                  >
                  <div
                    className={clsx(
                      'description absolute p-3 top-0 w-full h-full text-sm',
                      ' bg-paper bg-opacity-50 overflow-auto hidden group-hover:block'
                    )}
                    ref={el => { if (el) el.innerHTML = demo.shortDesc || ''; }} />
                </StorageImage>

                <div className="title py-1 px-2 text-center">
                  {demo.title}
                </div>
              </Link>
              <AdminEditMenu {...{isAdmin, demo, app, update}} />            
             </div>
          ))}
        </div>

        <AdminAddMenu {...{isAdmin, app, update}} />
      </div>
    </Frame>
  )
}

function AdminEditMenu({ isAdmin, demo, app, update }) {
  return isAdmin && (
    <span className="hidden group-hover:flex text-sm absolute bg-paper gap-2 ">
      <AdminButton onPress={() => editDemoPopup(demo, app, update)}>
        <Pencil1Icon className="inline" /> Info
      </AdminButton>
      <AdminButton onPress={() => editTextPopup(demo, app, 'shortDesc', update, 'Short Description')}>
        <Pencil1Icon className="inline" /> Desc
      </AdminButton>
      <AdminButton onPress={() => editTextPopup(demo, app, 'longDesc', update, 'Long Description')}>
        <Pencil1Icon className="inline" /> Desc2
      </AdminButton>
    </span>
  );
}

function AdminAddMenu({ isAdmin, app, update }) {
return (
    <div className="text-right">
      <AdminButton onPress={() => editDemoPopup({}, app, update)}>
        <PlusIcon />
      </AdminButton>
    </div>
  )
}

const AdminButton = (props) => <Button {...props} className="underline text-left px-1 text-sm" />