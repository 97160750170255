import { useState } from 'react';
import { Button, Link } from 'react-aria-components';
import { Cross1Icon, ArrowRightIcon, ArrowLeftIcon } from '@radix-ui/react-icons';
import Logo from 'lib/Logo';
import clsx from 'clsx';
// import smiles from './gifs/js-smiles.gif';

export default function Intro({ onClose }) {
  const [index, setIndex] = useState(0);

  return (
    <div className="absolute top-0 left-1/2 -translate-x-1/2 h-full py-10">
      {contents.map((Content, i) => (
        <Page
          key={i}
          onClose={onClose}
          isActive={i === index}
          changePage={(di) => setIndex(Math.min(Math.max(index + di, 0), contents.length - 1))}>
          <Content />
        </Page>
      )) }
    </div>
  )
}

const contents = [() => (
  <div className="h-full w-full flex items-center p-20">
    <div>
      <div className="mb-8 text-right">
        <Logo className="w-20 h-20" />        
      </div>
      <div className="text-4xl">What can you do with</div>
      <div className="text-7xl pb-6 mt-2 font-medium">Xenote?</div>
      <div className="text-base">
        7 things in in 20 seconds. <br />
        (Number 7 will blow your mind)
      </div>
    </div>
  </div>
), () => (
    <Numd num={1}>
      You can write documents.
    </Numd>
), () => (
    <Numd num={2}>
      Code and run JavaScript.
    </Numd>
), () => (
    <Numd num={3}>
      Publish JavaScript libraries and <Ref href="/references/simple-imports">import</Ref> them
      from anywhere.
    </Numd>
), () => (
    <Numd num={4}>
      Write <Ref href="/references/python-plot-via-pull">Python</Ref> code, run it. <br />
      Write <Ref href="/references/rust-web-assembly">Rust</Ref> code, compile it to web-assembly.
      Export it, import it, run it. <br />
      Write anything actually, run it <Ref href="references/intro-to-baklava">remotely</Ref>.
    </Numd>
), () => (
    <Numd num={5}>
      Build editors,
      upload files, <Ref href="references/uploaded-file">create interactive documents</Ref> to
      use them.
    </Numd>
), () => (
    <Numd num={6}>
      Build <Ref href="references/vani-example-rpi-servo">robots</Ref> maybe. Maybe write a book.
    </Numd>
), () => (
    <Numd num={7}> Use <Ref href="/genai">AI</Ref>.
    </Numd>
)];

const Numd = ({ num, children, bg }) => (
  <div
    className={clsx(
      'h-full w-full flex items-center overflow-hidden',
      bg && 'bg-cover bg-center bg-no-repeat',
    )}
    style={!bg ? {} :{ backgroundImage: `url(${bg})` }}>
    <div
      className={clsx(
        bg && 'bg-rim',
        'flex gap-2 pl-20 pr-10 w-full py-2'
      )}>
      <div>{num}.</div><div>{children}</div>
    </div>
  </div>
);

const Ref = ({ href, children }) => (
  <Link href={href} target="_blank" className="text-marker2 outline-none">{children}</Link>
)


function Page({onClose, isActive, changePage, children}) {
  return (
    <div
      className={clsx('bg-paper border border-rim h-full w-[30rem] relative text-lg', !isActive && 'hidden')}>
      <Button className="absolute -right-6 top-4 outline-none" onPress={onClose}>
        <Cross1Icon />
      </Button>
      {children}

      <Button className="absolute -right-7 bottom-4 outline-none p-1" onPress={() => changePage(-1)}>
        <ArrowLeftIcon />
      </Button>
      <Button className="absolute -right-7 bottom-12 outline-none p-1" onPress={() => changePage(1)}>
        <ArrowRightIcon />
      </Button>
    </div>
  );
}