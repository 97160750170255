import { CaretDownIcon, DiscordLogoIcon, MagicWandIcon } from '@radix-ui/react-icons';
import { PlayIcon } from '@heroicons/react/24/solid'
import { name, slogan } from 'meta';
import { Page } from './common';
import { Link, Button } from 'react-aria-components';
import { discrodUrl } from 'config';


export default function HomePage({ scrollTo, setShowIntro }) {

  return (
    <Page className="flex items-center relative">
      <div className="text-center flex-grow">
        <div className="text-8xl md:text-9xl leading-tight relative">
          <span className="relative">
            {name}

            <div className="absolute -right-10 top-2">
              <BetaStamp />
            </div>
          </span>
        </div>
        <div className="pt-4 pb-4 w-90 m-auto text-lg">
          {slogan}
        </div>

        <div className="">
          <Button
            onPress={() => setShowIntro(true)}
            className="border border-rim rounded py-5 px-8 bg-paper text-lg outline-none inline-flex gap-2">
            <PlayIcon className="inline w-6 text-pencil3" />
            Watch 20-second Intro
          </Button>
        </div>

        <div className="inline-flex flex-col gap-2 px-10 pt-6 pb-6 border-t mt-6">
          <div>
            Make this app better 
            - <Link className="text-marker2" href={discrodUrl} target="_blank">
              Join discussion. &nbsp;<DiscordLogoIcon className="inline-block mb-0.5" />
            </Link>          
          </div>

          <div>
            Use AI to build Notebooks
            - <Link className="text-marker2" href="/genai">
              Open Gen AI Playground. &nbsp;<MagicWandIcon className="inline-block mb-0.5" />
            </Link>
          </div>
        </div>
      </div>

      <div className="text-center bottom-8 absolute w-full">
        <Button className="p-2 inline-block outline-none" onPress={() => scrollTo('intro')}>
          <CaretDownIcon className="inline w-6 h-6" />
        </Button>
      </div>
    </Page>
  )
}

function BetaStamp() {
  return (
    <div
      className="border-2 w-12 h-12 flex items-center border-pencil">
      <div className="w-full text-center text-base font-medium text-pencil">beta</div>
    </div>  
  )
}