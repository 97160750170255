import {
  DotsVerticalIcon,
  LaptopIcon,
  ChatBubbleIcon,
  CopyIcon,
  CheckIcon
} from '@radix-ui/react-icons';
import { useState } from 'react';

import { EnterFullScreenIcon, ExitFullScreenIcon } from '@radix-ui/react-icons';

import MenuBuilder from 'lib/components/MenuBuilder';
import {useFullScreen, toggleFullScreen} from './fullscreen';
import clsx from 'clsx';

export default function Menu({ viewer, onOpen }) {
  const
    isFullScreen = useFullScreen(),
    [isOpen, setIsOpen] = useState(false),
    // autoHideMenu = viewer.state.get('autoHideMenu'),

    { workspace, currentArticle } = viewer,
    { domain } = workspace,
    allowComments = domain.allowComments || {},
    articleId = currentArticle && currentArticle.article.id,
    isCommentsDisabled = (
      (articleId &&  !allowComments.articles) ||
      (!articleId && !allowComments.workspace)
    );

  let options = [
    [
      'Comments',
      ChatBubbleIcon,
      () => viewer.openComments(),
      { isDisabled: isCommentsDisabled }
    ],
    ['Machine', LaptopIcon, () => viewer.app.machine.showMachinePopup(),],
    [
      isFullScreen ? 'Exit Full Screen' : 'Full Screen',
      isFullScreen ? ExitFullScreenIcon : EnterFullScreenIcon,
      toggleFullScreen
    ],
    [
      'Keep Articles Active',
      viewer.state.get('keepArticleActive') ? CheckIcon : '',
      viewer.toggleKeepArticleActive
    ],
    // ['Show Minimap', BoxModelIcon, () => {}, { isDisabled: true }],
    // ['Menubar on Hover', autoHideMenu ? CheckIcon : '', () => viewer.setAutoHide(!autoHideMenu)],
  ];

  if (articleId) {
    options.push([
      'Clone to Workspace',
      CopyIcon,
      () => viewer.openClonePopup()
    ],)
  }

  return (
    <MenuBuilder
      options={options}
      onOpenChange={c => { setIsOpen(c); if (onOpen) { onOpen(c); } } }>
      <div className={clsx('p-2 hover:bg-wax', isOpen && 'bg-wax')}>
        <DotsVerticalIcon />      
      </div>
    </MenuBuilder>
  )  
}
