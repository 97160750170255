import { getUserId, getUserName } from 'api/remote/calls/auth';
import { fetchPath, checkAvailable } from 'api/remote/calls/path';
import { fetchUserInfo } from './calls/user';

import {
  onFolder, onChildrenFolders, onChildrenArticles,
  createFolder, createArticle, updateFolder,
  deleteArticle, deleteFolder, renameSlug,
  fetchFolder, fetchChildrenFolders, fetchChildrenArticles,
  moveToFolder,
} from 'api/remote/calls/folder';

import {
  onDomain, updateDomain, fetchDomain, fetchTally, deleteDomain,
  getDomainAccessList, addDomainAccess,
  getDomainUserAccess, deleteDomainUserAccess, updateDomainUserRole,
  removeThumbnail, fetchDomainPlans
} from 'api/remote/calls/domain';

import {
  onArticle,
  onArticleElementChange,
  onArticleUploads,
  updateArticle,
  createElement,
  updateElement,
  deleteElement,

  onArticleVersions,
  createVersion,
  fetchVersion,
  fetchVersionElements,
  updateVersion,
  deleteVersion,
  revertToVersion,
  publishVersion,
  unpublishArticle,
  fetchArticle,
  fetchArticleElements,

  onArticleAccesses,
  updateArticleAccess,
  deleteArticleAccess
 } from 'api/remote/calls/article';

import {
  getDownloadURL,
  uploadImage,
  uploadArticleFile,
  getUploadUsedBy,
  deleteUpload
} from 'api/remote/calls/file';

import {
  fetchComments,
  postComment,
  deleteComment,
  likeComment,
  reportComment,
  fetchCommentLikes
} from 'api/remote/calls/comments';


export default class RemoteProvider {
  fetchPath = fetchPath;
  checkAvailable = checkAvailable;

  onFolder = onFolder;
  onChildrenFolders = onChildrenFolders;
  onChildrenArticles =  onChildrenArticles;
  createFolder = createFolder;
  createArticle = createArticle;
  updateFolder = updateFolder;
  deleteArticle = deleteArticle;
  deleteFolder = deleteFolder;
  renameSlug = renameSlug;
  moveToFolder = moveToFolder;
  
  fetchFolder = fetchFolder;
  fetchChildrenFolders = fetchChildrenFolders;
  fetchChildrenArticles = fetchChildrenArticles;

  getDownloadURL = getDownloadURL;
  uploadImage = uploadImage;
  onDomain = onDomain;
  updateDomain = updateDomain;
  fetchDomain = fetchDomain;
  fetchTally = fetchTally;
  deleteDomain = deleteDomain;
  removeThumbnail = removeThumbnail;
  fetchDomainPlans = fetchDomainPlans;

  getDomainAccessList = getDomainAccessList;
  addDomainAccess = addDomainAccess;
  getDomainUserAccess = getDomainUserAccess;
  deleteDomainUserAccess = deleteDomainUserAccess;
  updateDomainUserRole = updateDomainUserRole;
  getUserId = getUserId;
  getUserName = getUserName;

  onArticle = onArticle;
  onArticleElementChange = onArticleElementChange;
  onArticleUploads = onArticleUploads;
  updateArticle = updateArticle;
  
  createElement = createElement;
  updateElement = updateElement;
  deleteElement = deleteElement;

  uploadArticleFile = uploadArticleFile;
  getUploadUsedBy = getUploadUsedBy;
  deleteUpload = deleteUpload;

  onArticleVersions = onArticleVersions;
  createVersion = createVersion;
  fetchVersion = fetchVersion;
  fetchVersionElements = fetchVersionElements;
  updateVersion = updateVersion;
  deleteVersion = deleteVersion;
  revertToVersion = revertToVersion;
  publishVersion = publishVersion;
  unpublishArticle = unpublishArticle;
  fetchArticle = fetchArticle;
  fetchArticleElements = fetchArticleElements;
  
  onArticleAccesses = onArticleAccesses;
  updateArticleAccess = updateArticleAccess;
  deleteArticleAccess = deleteArticleAccess;

  fetchComments = fetchComments;
  fetchCommentLikes = fetchCommentLikes;
  postComment = postComment;
  deleteComment = deleteComment;
  likeComment = likeComment;
  reportComment = reportComment;

  fetchUserInfo = fetchUserInfo;
}