import { fromJS } from 'immutable';
import { initialSettings } from './settings';

export default class Policies {
  static autoSave = true;

  static getInitialData() {
    return {
      version: 0,
      settings: initialSettings,
      content: null
    }
  }

  static dataToState(data, currentState) {
    if (!currentState)
      currentState = fromJS({});

    return (
      currentState
        .set('settings', fromJS(data.settings))
        .set('content', data.content)
    );
  }

  static stateToData(state) {
    return {
      settings: state.get('settings').toObject(),
      content: state.get('content')
    }
  }

  static contentHasChanged(newState, oldState) {
    return (
      newState.get('settings') !== oldState.get('settings') ||
      newState.get('content') !== oldState.get('content')
    );
  }
}