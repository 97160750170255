import { TrashIcon, PlusIcon, Cross1Icon } from '@radix-ui/react-icons';
import Loader from 'lib/staticPages/Loader';
import { Button as AriaButton } from 'react-aria-components';
import { Buttons, Button } from 'lib/inputs/buttons';
import { KeyIcon } from '@heroicons/react/24/outline'

export default function Editors({ access }) {
  return (
    <div>
      <div className="font-medium mb-4 flex gap-4 items-center text-lg">
        <div><KeyIcon className="size-5" /></div>
        <div>Who can edit/admin this workspace?</div>
      </div>
      <List access={access} />
    </div>
  )
}

function List({ access }) {
  const
    { state } = access,
    list = state.get('list'),
    isDisabled = state.get('processing');

  if (state.get('loadingError'))
    return (<div className="p-4 text-center">Loading error!</div>)

  if (!list)
    return (<div className="p-4 text-center text-2xl"><Loader /></div>)

  if (state.get('alert'))
    return (
      <div className="p-4 text-center">
        There was an error performing your action.
        <AriaButton onPress={() => { access.fetchList(); }} className="ml-2">
          <Cross1Icon className="inline" />
        </AriaButton>
      </div>
    );


  return (
    <div className="">
      <div className="">
        <div className="flex gap-4 text-sm">
          <div className="w-1/2">Email</div>
          <div className="">Access</div>
        </div>

        <div className="flex flex-col flex-grow overflow-auto">
          { list.map(entry =>(
            <AccessRow key={entry.id} entry={entry} isDisabled={isDisabled} access={access} />)
          )}
          { list.length === 0 && (
            <div className="p-3 text-sm text-pencil3 ml-3">No one has been added yet.</div>
          )}
        </div>
      </div>

      <div className="text-right">
        <Button
          size="small"
          className="flex items-center gap-1 ml-auto outline-none" onPress={access.addDomainAccessPopup}>
          <PlusIcon />
          Add
        </Button>
      </div>
    </div>
  )
}


function AccessRow({ entry, controller, isDisabled, access }) {
  const
    { email, id, role } = entry,
    userId = access.getUserId(),
    isSelf = id === userId;

  return (
    <div className="flex py-1 gap-4 items-center" key={id}>
      <div className="font-medium w-1/2">
        {email}
      </div>

      <div className="">
        <select
          className="text-sm p-1 border border-rim3 w-28 rounded font-medium bg-paper"
          value={role}
          onChange={e => {
            access.updateDomainUserRole({ userId: id, role: e.currentTarget.value });
          } }
          disabled={isDisabled || isSelf}>
          <option value="admin">Admin</option>
          <option value="write">Read / Write</option>
          <option value="read">Read Only</option>
        </select>
      </div>

      <div className="p-1 px-4">
        <AriaButton
          className="p-2 rounded data-[disabled]:text-pencil3 "
          isDisabled={isDisabled || isSelf}
          onPress={_ => access.deleteUserAccess({ userId: id })}>
          <TrashIcon className="w-4 h-4" />
        </AriaButton>
      </div>
    </div>
  )
}
