import StorageImage from 'lib/StorageImage';
import { Link } from 'react-aria-components';
import { clsx } from 'clsx';
import Carousel from './common/Carousel';
import ItemTitle from './common/ItemTitle';


export default function Design({ viewer }) {
  const
    { workspace } = viewer,
    { domain } = workspace;

  return (
    <div className="lg:p-8 h-full overflow-auto">
      <div className="max-w-[70rem] mx-auto lg:flex">
        <div className="lg:basis-5/12">
          <div className="p-8 py-16 max-md:pt-32 lg:sticky lg:top-0 bg-paper max-lg:px-16 max-lg:pt-16">
            {domain.thumbnail ? (
              <>
                <div className="max-w-[18rem] w-full mb-4">
                  <StorageImage
                    className=""
                    path={domain.thumbnail['640x640']}
                    getDownloadURL={viewer.provider.getDownloadURL} />
                </div>

                <div className="text-3xl font-bold mb-4 text-pencil">
                  {domain.title}
                </div>
              </>
            ) : (
              <>
                <div className="text-6xl font-bold mb-6 text-pencil">
                  {domain.title}
                </div>

                <div className="w-6 h-2 bg-pencil2 mb-6">
                </div>
              </>
            ) }


            { domain.description && (
              <div className="text-md mb-8 text-pencil2 w-[80%]">
                <div ref={el => { if (el) el.innerHTML = domain.description; }}></div>
              </div>
            ) }

            { domain.author && (
              <div className="text-md text-pencil2 font-medium">
                By&nbsp; {domain.author}
              </div>
            ) }
          </div>
        </div>

        <div className="lg:basis-7/12 overflow-hidden">
          <FolderContent viewer={viewer} />
        </div>
      </div>
    </div>
  )
}


function FolderContent({ viewer }) {
  const sections = viewer.workspace.sections.filter(s => s.items.length > 0);

  return (
    <div className="flex flex-col gap-8 px-4 md:px-12 pt-8 pb-16 bg-wax">
      { sections.map((section, i) => section.items.length > 0 && (
        <Section key={i} section={section} viewer={viewer} />
      )) }
    </div>
  )
}

function Section({ section, viewer }) {
  const Wrapper = section.displayMode === 'featured' ? Carousel : Regular;

  return (
    <div>
      { section.title && (
        <div className="mb-2 flex gap-4 ml-4 text-pencil3 font-medium">
          {section.title}
        </div>
      ) }

      <Wrapper size={section.items.length} displayMode={section.displayMode}>
        {section.items.map(item => (
          <FolderItem
            key={item.id}
            item={item}
            viewer={viewer}
            section={section}
            displayMode={section.displayMode} />
        ))}
      </Wrapper>
    </div>
  )
}

const Regular = ({ children, displayMode }) => {
  const isCards = displayMode === 'cards';
  return (
    <div
      className={clsx(
        'flex flex-col gap-2',
        isCards && 'grid md:grid-cols-3 grid-cols-2 lg:grid-cols-2'
      )}>
      {children}
    </div>
  )
};

export function FolderItem({ item, section, viewer }) {
  const
    { path, description, thumbnail } = item,
    { displayMode } = section;

  if (displayMode === 'title' || !displayMode) {
    return (
      <Link
        className="outline-none block font-medium py-1 px-4 bg-paper"
        href={'/' + path.replace('\\', '/')}>
        <div className="text-pen"><ItemTitle item={item} viewer={viewer} /></div>
      </Link>
    );
  }

  if (displayMode === 'featured') {
    return (
      <div className="shrink-0 snap-center w-full">
        <Link className="outline-none block" href={'/' + path.replace('\\', '/')}>
          <div className="p-4 bg-paper shadow-sm flex flex-col gap-2 text-pen font-normal">
            <div>
              <StorageImage
                path={thumbnail && thumbnail['1200x675']}
                aspectRatio={16/9}
                getDownloadURL={viewer.provider.getDownloadURL} />
            </div>
            <div className="flex flex-col gap-2">
              <div className="font-medium gap-4 flex text-lg">
                <div className="flex-grow"><ItemTitle item={item} viewer={viewer} /></div>
              </div>
              <div className="text-sm" ref={el => { if (el) el.innerHTML = (description || ''); }} />
            </div>
          </div>
        </Link>
      </div>
    )    
  }

  const
    isList = displayMode === 'list',
    isCards = displayMode === 'cards';

  return (
    <div>
      <Link
        className="outline-none block"
        href={'/' + path.replace('\\', '/')}>
        <div className={clsx(
          'flex text-pen font-normal bg-paper p-4',
          isCards && 'flex-col gap-2 shadow-sm',
          isCards && 'transition-all duration-100 hover:-translate-y-0.5',
          isList && 'gap-2'
        )}>
          { section.showThumb && (
            <div
              className={clsx(
                isList && 'w-[5rem] flex-shrink-0 p-1',
                isCards && ''
              )}>
              <StorageImage
                path={thumbnail && thumbnail[isList ? '320x320' : '640x640']}
                getDownloadURL={viewer.provider.getDownloadURL} />
            </div>
          ) }
          <div className="flex flex-col gap-2">
            <div className="font-medium gap-4 flex">
              <div className="flex-grow"><ItemTitle item={item} viewer={viewer} /></div>
            </div>
            { section.showDesc && description && (
              <div
                className="text-sm"
                ref={el => { if (el) el.innerHTML = description; }} />
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}