import Article from 'modules/viewer/controllers/Article';
import elementLibs from 'modules/elements/all';
import ThemeData from 'modules/theme/controllers/ThemeData';


export default class Gen {
  constructor({ id, title, elements, app, expandDetail }) {
    this.id = id;
    this.title = title;
    this.rawElements = elements;
    this.app = app;
    this.themeData = new ThemeData();
    this.themeData.load({});

    const processed = processElements(elements);
    this.elements = processed.elements;
    this.article = new Article({
      app: app,
      provider: app.remoteProvider,
      version: {
        article: {
          title: title,
          path: `genai/${this.id}`,
          layout: { order: processed.order }
        },
      },
      elements: processed.elements,
      uploads: {},
      themeData: this.themeData,
      expandDetail
    });
  }
}


function processElements(rawElements) {
  let order = [];
  let elements = {};

  for (let element of rawElements) {
    let initial = elementLibs[element.type].Policies.getInitialData();
    if (element.type !== 'file'){
      order.push(element.id);
    }
    elements[element.id] = {
      ...initial, ...element,
      settings: { ...initial.settings, ...element.settings }
    };
  };
  return ({ elements, order });
}