import {
  collection, query, orderBy, where, getDocs, limit,
  addDoc, startAt
} from 'firebase/firestore';
import { db, functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

// Comments
export async function fetchComments({ domainId, articleId, sortBy, limitBy=10, startCursor=null }) {
  var
    q = query(
      collection(db, 'domains', domainId, 'comments'),
      where('articleId', '==', articleId),
      where('isDeleted', '==', false),
      orderBy(sortBy, 'desc'),
      orderBy(sortBy === 'createdAt' ? 'likesCount' : 'createdAt', 'desc'),
      limit(limitBy + 1),
    );

  if (startCursor)
    q = query(q, startAt(startCursor));

  const
    qss = await getDocs(q),
    { docs } = qss,
    nextCursor = docs.length > limitBy ? docs.pop() : null,
    data = docs.map(doc => ({ id: doc.id, ...doc.data() }));

  return { data, nextCursor };
}


export async function fetchCommentLikes({ domainId, articleId }) {
  const { currentUser } = getAuth();

  if (!currentUser)
    throw new Error('Not logged in');

  const
    q = query(
      collection(db, 'domains', domainId, 'commentLikes'),
      where('articleId', '==', articleId),
      where('userId', '==', currentUser.uid)
    ),
    qss = await getDocs(q),
    docs = qss.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return docs;
}

export async function postComment({ domainId, articleId, commentId, parentId, comment }) {
  return await httpsCallable(functions, 'postCommentCall')({
    domainId, articleId, commentId, parentId, comment
  });
}

export async function likeComment({ domainId, articleId, commentId, id, like }) {
  return await httpsCallable(functions, 'likeCommentCall')({ domainId, articleId, commentId, id, like });
}

export async function deleteComment({ domainId, commentId }) {
  return await httpsCallable(functions, 'deleteCommentCall')({ domainId, commentId });
}

export async function reportComment({ domainId, commentId, cause }) {
  const
    user = getAuth().currentUser,
    data = { domainId, commentId, cause, userId: user && user.uid  };
  await addDoc(collection(db, 'reported'), data);
}