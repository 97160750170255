import { Page } from './common';
import { CheckIcon } from '@radix-ui/react-icons';
// import Rockets from './Rockets';


export default function PageAbout() {
  return (
    <Page className="px-8 py-8 overflow-auto relative" id="intro">
{/*      <div className="absolute top-0 left-0 w-full h-full">
        <Rockets count={5} />
      </div>
*/}
      <div className="py-8 border-b mb-8 md:px-12">
        <div className="text-5xl text-center mb-4">
          What makes Xenote so special?
        </div>
        <div className="text-center">
          There are are a good dozen of notebook options out there. Why this one?
        </div>      
      </div>

      <div className="md:flex gap-8 overflow-auto">
        <div className="flex-1 flex flex-col">
          <div className="text-2xl mb-4">
            1. It's on the Web
          </div>
          <div className="mb-8">
            Start creating immediately with our intuitive document editor,
            built-in code environment, and interactive components. No setup required.
            No context switching. Just pure creativity.
          </div>

          <div className="text-2xl mb-4">
            2. Notebooks are Connected
          </div>
          <div className="mb-8">
            Your notebooks aren't islands - they're nodes in a web of knowledge.
            Import libraries from one notebook to another, share components across
            projects, and build complex systems modularly. Everything stays organized,
            versioned, and accessible.
          </div>          
        </div>

        <div className="flex-1">
          <div className="text-2xl mb-4">
            3. Has all the necessary features
          </div>
          <div className="mb-8">
            <p>
              <Check /> Collaborative, <Check /> Version Cntrol, <Check /> Access Control, <Check />
              Sharing, <Check /> Multi-language Runtimes
            </p>
            <p>
              Whether you're writing documentation, prototyping an application, or building
              a complex system, Xenote adapts to your workflow, takes out the painful overhead,
              and gives you back the joy of creating.
            </p>
          </div>

          <div className="text-xl mb-4">
            But wait, there's even more...
          </div>
          <div>
            <p>
              Do you want to - Build a custom editor of your choice?
              A class website? Or write a travel bug?
              Or share 3D Printable STLs? Or use AI and write and share an mobile webapp in minutes? 
              Xenote is greater than the sum of its parts.
              And we <strong>can't wait</strong> to see what you use this platform for.              
            </p>
           </div>
        </div>
      </div>
    </Page>
  )
}

const Check = () => <CheckIcon className="inline-block" />
