import { useState, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import { checkIsAdmin } from 'api/remote/calls/auth';
import IndexPage from './IndexPage';
import ItemPage from './ItemPage';


export default function Page({ app }) {
  const
    match = useMatch({ path: '/demos/*' }),
    demoId = match.params['*'],
    [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    checkIsAdmin().then(a => setIsAdmin(a));
  }, []);

  return (
    <>
      { !demoId && <IndexPage app={app} isAdmin={isAdmin} /> }
      { demoId && <ItemPage app={app} demoId={demoId} isAdmin={isAdmin} /> }
    </>
  );
}