import { useControllerState } from 'lib/BaseController';
import { Window } from 'lib/popup/components/ui';
import Loader from 'lib/staticPages/Loader';
import Body from './Body';


function Popup({ popup }) {
  const state = useControllerState(popup);

  return (
    <Window title={`Comments for "${popup.args.title}"`} popup={popup} bodyClassName="h-full overflow-hidden">
      {
        popup.args.isDisabled ?
          <Disabled articleId={popup.articleId} /> :
          state.get('error') ?
            <ErrorFrame error={state.get('error')} /> :
            (state.get('loading') || state.get('list') === null) ?
              <div className="p-4 text-center"><Loader /></div> :
              <Body popup={popup} />
      }
    </Window>
  )
}

export default Popup;


function Disabled({ articleId }) {
  return (
    <div className="text-center pt-8 text-pencil3">
      Comments are disabled.
    </div>
  );
}

function ErrorFrame({ error }) {
  return (
    <div className="pt-8 pb-12 text-center text-error2">
      There was an error loading comments. <br />
      Please try again later.
    </div>
  )
}