import { Cross2Icon, PlusIcon } from '@radix-ui/react-icons';
import Styler from 'modules/theme/views/Styler';
import { Button, TextArea } from 'react-aria-components';

import clsx from 'clsx';

import { ErrorBlock } from 'lib/staticPages';
import Loader from 'lib/staticPages/Loader';

import Scroll from 'modules/viewer/views/Article/Scroll';
import { Frame } from 'modules/app/views/Home/misc/components';

import Topbar from './Topbar';
import Detail from './Detail';


export default function Page({ app }) {
  const
    controller = app.genai,
    { gen, state } = controller;

  return (
    <>
      <Frame className="max-w-[60rem] h-full relative">
        { state.get('isLoadingGen') ? (
          <div className="text-center p-8 text-xl">
            <Loader />
          </div>
        ) : state.get('loadGenError') ? (
          <ErrorBlock
            title="Loading Error"
            error={state.get('loadGenError')} />
        ) : gen ? (
          <Gen
            gen={gen}
            controller={controller} />
        ) : '' }
      </Frame>

      <Detail controller={controller} />
    </>
  )
}

function Gen({ controller }) {
  const
    { version, state } = controller,
    mode = state.get('mode'),
    isRefining = controller.state.get('isRefining');

  const before = mode === 'refine' && (
    <Feedback controller={controller} id="document" isDisabled={isRefining} />
  );

  const beforeElement = (id) => {
    return mode === 'refine' && (
      <Feedback controller={controller} id={id} isDisabled={isRefining} />
    )
  }

  return (
    <>
      <Topbar controller={controller} />

      <div className="nb-genai-theme w-full h-full overflow-auto">
        <Styler themeData={version.themeData} baseClass="nb-genai-theme" />

        <Doc
          key={version.id}
          article={version.article}
          beforeElement={beforeElement}
          before={before} />
      </div>
    </>
  )
}

const Feedback = ({ controller, id, className, isDisabled }) => {
  const
    feedback = controller.state.getIn(['feedbacks', id]),
    setFeedback = f => controller.setInState(['feedbacks', id], f);

  if (typeof feedback === 'string') {
    return (
      <div className="w-full relative mb-4 border">
        <TextArea
          className="outline-none w-full bg-transparent py-2 px-4"
          placeholder={id === 'document' ? 'Write document feedback' : 'Write feedback' }
          value={feedback}
          onChange={e => setFeedback(e.currentTarget.value)}
          autoFocus={true}
          disabled={isDisabled} />

        <Button
          className="top-2 right-2 absolute"
          onClick={() => setFeedback(null)}
          isDisabled={isDisabled}>
          <Cross2Icon />
        </Button>
        
        <svg
          width={16} height={16}
          viewBox="0 0 16 16"
          className="absolute left-4 -bottom-4 fill-paper text-rim">
          <path d="M0 0 L8 9 L16 0" strokeWidth="1" stroke="currentColor" />
        </svg>
      </div>
    )
  }

  return (
    <Button
      className={clsx('p-1 flex gap-2 items-center absolute -right-10 outline-none')}
      onPress={() => { setFeedback('');}}
      isDisabled={isDisabled}>
      <PlusIcon />
    </Button>
  )  
}

const Doc = ({ article, beforeElement, before }) => {
  return (
    <Scroll
      article={article}
      beforeElement={beforeElement}
      before={before} />);
};