import {
  CursorTextIcon, Pencil1Icon, ImageIcon,
  GearIcon, ComponentBooleanIcon, BarChartIcon, StarIcon
} from '@radix-ui/react-icons';
import { KeyIcon } from '@heroicons/react/24/outline'


export function getDomainOptions({ controller }) {
  const
    { popups, state } = controller,
    cover = state.getIn(['domain', 'cover640']),
    slug = state.getIn(['domain', 'slug']);

  return ([
    ['Change Title', CursorTextIcon, popups.editTitlePopup],
    ['Change Description', Pencil1Icon, popups.editDescriptionPopup],
    [`${cover ?  'Change' : 'Upload'} Cover Image`, ImageIcon , popups.editThumbnailPopup],
    [''],
    ['Preferences', GearIcon, null, { href: '/preferences/' + slug }],
    ['Access', KeyIcon, null, { href: '/users/' + slug  }],
    ['Theme', ComponentBooleanIcon, null, { href: '/theme/' + slug }],
    [''],
    ['Usage and Limits', BarChartIcon, null, { href: '/usage/' + slug  }],
    ['Upgrade', StarIcon, null, { href: '/upgrade/' + slug  }],
  ]);
}