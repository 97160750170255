import { fromJS } from 'immutable';
import { initialSettings } from './settings';

export default class Policies {
  static autoSave = true;

  static getInitialData() {
    return {
      version: 0,
      settings: initialSettings
    }
  }

  static dataToState(data, currentState) {
    if (!currentState)
      currentState = fromJS({});

    return currentState.set('settings', fromJS(data.settings));
  }

  static stateToData(state) {
    return { settings: state.get('settings').toObject() }
  }

  static contentHasChanged(newState, oldState) {
    return newState.get('settings') !== oldState.get('settings');
  }
}