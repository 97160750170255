import { Button } from 'react-aria-components';
import { Label } from './basic';
import { useState } from 'react';
import { TrashIcon, Pencil1Icon } from '@radix-ui/react-icons';
import clsx from 'clsx';


export default function KeyValueInput({ label, value, isDisabled, onChange, valueType, setLocked }) {
  const
    kv = value,
    [k, setK] = useState(''),
    [v, setV] = useState(''),
    [adding, setAdding] = useState(false),
    [editingKey, setEditingKey] = useState(null);

  const onAdd = () => {
    if (!k)
      return;
    let n = {...value};
    if (editingKey)
      delete n[editingKey];
    onChange({ ...n, [k]: v });
    setK(''); setV(''); setAdding(false); setLocked(false); setEditingKey(null);
  }

  const onCancel = () => {
    setK(''); setV(''); setAdding(false); setLocked(false); setEditingKey(null);
  }

  return (
    <div className="nb-key-value-input">
      <Label>{label}</Label>
      <div className="gap-1 flex flex-col mb-2 py-2 ">
        { Object.keys(kv).length === 0 && (
          <div className="italic text-sm bg-wax py-2 px-4 w-full opacity-60 text-center">Empty</div>
        )}
        { Object.keys(kv).map((k, i) => (
          <div
            key={i}
            className={clsx('text-xs py-1 font-mono group', editingKey === k && 'bg-wax')}>
            <div className="float-right flex gap-2 group-hover:opacity-100 opacity-0">
              <Button
                className="px-1"
                onPress={e => {
                  delete kv[k];
                  onChange({ ...kv });
                }}>
                <TrashIcon />
              </Button>

              <Button
                className="px-1"
                onPress={e => {
                  setK(k); setV(kv[k]); setEditingKey(k); setLocked(true);
                }}>
                <Pencil1Icon />
              </Button>
            </div>
            <span className="bg-wax px-1 break-words">{k}</span>:&nbsp;
            <span className="bg-wax px-1 break-words">{kv[k]}</span>
          </div>
        )) }

        { (adding || editingKey) ? (
            <Form {...{k, setK, v, setV, onAdd, onCancel, editingKey }} />
          ) : (
          <div className="text-right">
            <TinyButton onPress={e => { setAdding(true); setLocked(true); }}>
              Add New Key
            </TinyButton>
          </div>
          ) }
      </div>
    </div>
  );
}


function Form({ k, setK, v, setV, onAdd, onCancel, editingKey }) {
  const
    onEnter = e => { if (e.key === 'Enter') { e.preventDefault();  onAdd(); } };

  return (
    <div className="border-t border-rim pt-2 mt-2">
      <div className="flex gap-2 text-xs">
        <input
          className="outline-none bg-wax py-1 px-2 self-start w-full font-mono"
          value={k}
          placeholder="Key"
          autoFocus={true}
          onChange={e => setK(e.currentTarget.value)}
          onKeyDown={onEnter} />

        <textarea
          className="outline-none bg-wax py-1 px-2 w-full font-mono"
          value={v}
          placeholder="Value"
          onChange={e => setV(e.currentTarget.value)}
          onKeyDown={onEnter} />
      </div>

      <div className="text-right mt-2">
        <TinyButton onPress={onAdd}>
          { editingKey ? 'Update' : 'Add' }
        </TinyButton>

        <TinyButton onPress={onCancel}>
          Cancel
        </TinyButton>
      </div>
    </div>    
  )
}

function TinyButton(props) {
  return (<Button {...props} className="text-sm outline-none border-rim3 px-2 py-0.5 font-medium" />)
}