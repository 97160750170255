import {
  collection, query, limit, startAt, orderBy,
  doc, addDoc, setDoc, updateDoc, getDoc, getDocs, serverTimestamp,
  writeBatch
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../firebase';
import { slugify } from 'lib/slugify';


export async function generate({ prompt, provider }) {
  return await httpsCallable(functions, 'generateCall')({ prompt, provider });
}

export async function refine({ prompt, provider }) {
  return await httpsCallable(functions, 'refineCall')({ prompt, provider });
}

export async function fetchGens({ userId, cursor, itemsPerPage }) {
  const queryRef = query(
    collection(db, 'userInfos', userId, 'gens'),
    orderBy('createdAt', 'desc')
  );
  return await fetchPaginaged({ queryRef, cursor, itemsPerPage });
}

export async function fetchPaginaged({ queryRef, cursor, itemsPerPage=10 }) {
  let q = query(queryRef, limit(itemsPerPage + 1), );  

  if (cursor)
    q = query(q, startAt(cursor));

  const
    qss = await getDocs(q),
    { docs } = qss,
    nextCursor = docs.length > itemsPerPage ? docs.pop() : null,
    data = docs.map(doc => ({ id: doc.id, ...doc.data() }));

  return { data, nextCursor };
}

export async function fetchGen({ userId, genId }) {
  if (genId === 'demo')
    return fetchLocally('gen');

  const ref = doc(db, 'userInfos', userId, 'gens', genId);
  const data = (await getDoc(ref)).data();
  if (!data)
    throw new Error('Gen not found.');
  return ({ id: genId, ...data });
}

export async function fetchGenVersion({ userId, genId, versionId }) {
  if (genId === 'demo')
    return fetchLocally('version');

  const
    ss = (
      versionId ?
        await getDoc(doc(db, 'userInfos', userId, 'gens', genId, 'versions', versionId)) :
        await getLatest(collection(db, 'userInfos', userId, 'gens', genId, 'versions'))
    ),
    data = ss && ss.data();
  
  if (!data)
    throw new Error('Version not found.');
  return ({ id: ss.id, ...data });
}

const getLatest = async (ref, genId) => {
  const q = query(ref, orderBy('timestamp', 'desc'), limit(1));
  const qss = await getDocs(q);
  return !qss.empty && qss.docs[0];
}

export async function saveGen({ userId, data }) {
  if (!userId)
    return saveLocally('gen', data);

  const
    colRef = collection(db, 'userInfos', userId, 'gens'),
    docRef = await getGenRef(colRef, data.title);
  await setDoc(docRef, { ...data, createdAt: serverTimestamp() });
  return docRef.id;
};

const getGenRef = async (colRef, title) => {
  let slug = slugify(title), s = slug;
  console.log('title', title, colRef);
  for (let i = 0; i < 20; i++) {
    let docRef = await doc(colRef, s);
    if (!(await getDoc(docRef)).exists())
      return docRef;
    s = `${slug}-${i}`
  }
  throw new Error('Recursive loop!!')
}

export async function saveGenVersion({ userId, genId, data }) {
  if (!userId)
    return saveLocally('version', data);

  const
    genRef = doc(db, 'userInfos', userId, 'gens', genId),
    genDoc = await getDoc(genRef),
    ref = collection(genRef, 'versions'),
    ss = await addDoc(ref, { ...data, timestamp: serverTimestamp() });
  
  const
    entry = { id: ss.id, log: data.log || null  },
    versions = [...(genDoc.data()?.versions || []), entry];

  await updateDoc(genRef, { versions });
  return ss.id;
}

export async function deleteGen({ userId, genId }) {
  if (genId === 'demo') {
    removeLocally('gen');
    removeLocally('version');
  }

  const genRef = doc(db, 'userInfos', userId, 'gens', genId);
  const versionsRef = collection(genRef, 'versions');
  const versionsSnapshot = await getDocs(versionsRef);
  const batch = writeBatch(db);
  versionsSnapshot.docs.forEach((versionDoc) => {
    batch.delete(doc(versionsRef, versionDoc.id));
  });
  batch.delete(genRef);
  await batch.commit();
};


const localKeyPrefix = 'genai-data-';

function saveLocally(field, data) {
  let key = `${localKeyPrefix}-${field}`;
  window.localStorage.setItem(key, JSON.stringify(data));
  return 'demo';
}

function removeLocally(field) {
  window.localStorage.removeItem(`${localKeyPrefix}-${field}`);
}

function fetchLocally(field) {
  let key = `${localKeyPrefix}-${field}`;
  let d = window.localStorage.getItem(key);
  if (!d)
    throw new Error('Not found.');
  return d && JSON.parse(d);
}