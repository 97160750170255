export const EXTENSION_MODE_MAP = {
  '.txt': 'plain',
  '.c': 'c',
  '.cpp': 'cpp',
  '.css': 'css',
  '.html': 'xml',
  '.java': 'java',
  '.js': 'jsx',
  '.mjs': 'jsx',
  '.cjs': 'jsx',
  '.json': 'json',
  '.jsx': 'jsx',
  '.less': 'less',
  '.lua': 'lua',
  '.php': 'php',
  '.py': 'python',
  '.rb': 'ruby',
  '.rs': 'rust',
  '.sh': 'shell',
  '.sql': 'sql',
  '.swift': 'swift',
  '.ts': 'tsx',
  '.tsx': 'tsx',
}

export const EXTENSION_MIME_TYPE_MAP = {
  '.txt': 'text/plain',
  '.c': 'text/x-c',
  '.cpp': 'text/x-c++src',
  '.css': 'text/css',
  '.html': 'text/html',
  '.java': 'text/x-java-source',
  '.js': 'application/javascript',
  '.json': 'application/json',
  '.jsx': 'text/jsx',
  '.less': 'text/less',
  '.lua': 'text/x-lua',
  '.php': 'application/x-httpd-php',
  '.py': 'text/x-python',
  '.rb': 'application/x-ruby',
  '.rs': 'text/rust',
  '.sh': 'application/x-sh',
  '.sql': 'application/sql',
  '.swift': 'text/swift',
  '.ts': 'application/typescript',
  '.tsx': 'application/typescript',
  '.mjs': 'application/javascript',
  '.cjs': 'application/javascript',
};


export const MODE_CAPTION_MAP = {
  'plain': 'Plain',
  'clike': 'C Fam',
  'cpp': 'C++',
  'css': 'CSS',
  'dockerfile': 'Dockerfile',
  'java': 'Java',
  'javascript': 'JavaScript',
  'json': 'JSON',
  'jsx': 'JSX',
  'less': 'Less',
  'lua': 'Lua',
  'mysql': 'MySQL',
  'pgsql': 'PostgreSQL',
  'php': 'PHP',
  'python': 'Python',
  'ruby': 'Ruby',
  'rust': 'Rust',
  'shell': 'Shell',
  'sql': 'SQL',
  'swift': 'Swift',
  'toml': 'TOML',
  'ts': 'Typescript',
  'tsx': 'TSX',
  'xml': 'XML',
}

export const MODE_OPTIONS = (
  Array.from(Object.keys(MODE_CAPTION_MAP))
    .map(t => ({ key: t, caption: MODE_CAPTION_MAP[t] }))
);

export function getMode(filename) {
  const
    match = filename.match(/\.[0-9a-z]+$/i),
    extension = match && match[0];

  return EXTENSION_MODE_MAP[extension] || 'plain';
}

export function getMimeType(filename) {
  const
    match = filename.match(/\.[0-9a-z]+$/i),
    extension = match && match[0];
  return EXTENSION_MIME_TYPE_MAP[extension] || 'text/plain';
}