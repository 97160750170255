import { CubeIcon } from '@radix-ui/react-icons';
import StorageImage from 'lib/StorageImage';
import { Group, Label, LinkButton } from './common';


export default function Publication({ controller }) {
  const domain = controller.state.get('domain');

  if (!domain)
    return '';

  const
    imagePath = domain.getIn(['logo', '320x320']),
    acw = domain.getIn(['allowComments', 'workspace']) || false,
    aca = domain.getIn(['allowComments', 'articles']) || false;

  return (
    <div>
      <Group>
        <Label>Logo</Label><br/>
        <LinkButton onPress={controller.popups.editLogoPopup}>
          Change
        </LinkButton>

        <div className="mt-1">
          <div className="border border-rim rounded-sm w-12 h-12">
            { imagePath ?
              <StorageImage path={imagePath} getDownloadURL={controller.provider.getDownloadURL} backgroundColor="none" /> :
              <CubeIcon className="w-12 h-12 p-3" /> }
          </div>
        </div>
      </Group>

      <Group>
        <Label>Index Page Layout</Label><br/>
        <LinkButton onPress={controller.popups.editCoverPageLayoutPopup}>
          Change
        </LinkButton>

        <div className="">
          {
            controller.themeData.getValue('coverPageLayout') === 'doc-like' ?
              'Documentation' :
              controller.themeData.getValue('coverPageLayout') === 'blog-like' ?
                 'Blog':
                 'Book'
           }
        </div>
      </Group>


      <Group>
        <Label>Allow Comments</Label><br/>
        <LinkButton onPress={controller.popups.editAllowComments}>
          Change
        </LinkButton>

        <div className="">
          { (!aca && !acw) ? (
            <div>No</div>
          ) : (
            <div>
              Yes, {acw ? 'on workspace' : ''}{acw && aca ? ', and ' : ''}{aca ? 'on articles' : ''}
            </div>
          )  }
        </div>
      </Group>      
    </div>
  )
}