import { Map } from 'immutable';

export default class LayoutManager {
  constructor(folder) { this.folder = folder; }

  get layout() {
    return this.folder.state.getIn(['self', 'layout']);
  }

  get list() {
    return this.layout.get('list');
  }

  addNewSection({ title, displayMode, showDesc, showThumb }) {
    const
      id = `${(new Date()).getTime()}`,
      type = 'section',
      entry = new Map({ type, title, id, displayMode, showDesc, showThumb }),
      list = this.list.push(entry);
    
    return this.saveList(list);
  }

  editSection(id, updates) {
    const
      index = this.list.findIndex(i => i.get('id') === id),
      list = this.list.set(index, this.list.get(index).merge(updates));
    return this.saveList(list);
  }

  deleteSection(sectionId) {
    const list = this.list.filter(item => item.get('id') !== sectionId);
    return this.saveList(list);
  }

  moveItem = (i0, i1) => {
    var
      list = this.list,
      item = list.get(i0);
    list = list.splice(i0, 1).splice(i1, 0, item);
    return this.saveList(list);
  }

  saveList(list) {
    this.folder.setSelfState({ layout : this.layout.set('list', list) });
    this.folder.saveSelfKey('layout');
  }
}