import StorageImage from 'lib/StorageImage';
import { Link } from 'react-aria-components';
// import { Button } from 'lib/inputs/buttons';
import { CardStackIcon } from '@radix-ui/react-icons'
import { clsx } from 'clsx';
import { toRoman } from 'lib/tools.ts';
import ItemTitle from './common/ItemTitle';


export default function Design({ viewer }) {
  const
    { workspace } = viewer,
    { domain } = workspace;

  return (
    <div className="md:p-8 h-full overflow-hidden">
      <div className="max-w-[70rem] mx-auto bg-paper rounded h-full bg-paper md:border border-rim md:flex max-md:overflow-auto">
        <div
          className={clsx(
            'md:basis-5/12 p-8 flex flex-col justify-center max-md:border-b border-rim md:border-r',
            'max-h-full overflow-auto',
            'max-md:h-full',
            // 'bg-gradient-to-br from-yellow-400 to-yellow-300'
          )}>
          <div className="pl-8">
            {domain.thumbnail ? (
              <>
                <div className="max-w-[18rem] w-full mb-4">
                  <StorageImage
                    className=""
                    path={domain.thumbnail['640x640']}
                    getDownloadURL={viewer.provider.getDownloadURL} />
                </div>

                <div className="text-3xl font-bold mb-4 text-pencil">
                  {domain.title}
                </div>
              </>
            ) : (
              <>
                <div className="text-6xl font-bold mb-6 text-pencil">
                  {domain.title}
                </div>

                <div className="w-6 h-2 bg-pencil2 mb-6">
                </div>
              </>
            ) }


            { domain.description && (
              <div className="text-md mb-8 text-pencil2 w-[80%]">
                <div ref={el => { if (el) el.innerHTML = domain.description; }}></div>
              </div>
            ) }

            { domain.author && (
              <div className="text-md mb-8 text-pencil2 font-medium">
                By&nbsp; {domain.author}
              </div>
            ) }
          </div>

          {/*<BuySection />*/}
        </div>

        <div className="md:basis-7/12 p-16 md:h-full md:overflow-auto flat-scrollbar bg-paper2">
          <div className="">
            <div className="font-bold ">
              CONTENTS
            </div>

            <FolderContent viewer={viewer} />
          </div>
        </div>
      </div>
    </div>
  )
}


function FolderContent({ viewer }) {
  const sections = viewer.workspace.sections.filter(s => s.items.length > 0);

  let index = 1;

  return (
    <div className="py-4 flex flex-col gap-6">
      { sections.map((section, i) => section.items.length > 0 && (
        <div className="" key={i}>
          { section.title && (
            <div className="mb-2 flex gap-4 text-pencil3">
              <div className="w-4 shrink-0 text-right">{toRoman(i + 1)}.</div>
              {section.title}
            </div>
          ) }

          <div className="flex flex-col">
            {section.items.map(item => (
              <FolderItem
                key={item.id}
                item={item}
                index={index++}
                section={section}
                viewer={viewer} />
            ))}
          </div>
        </div>
      )) }
    </div>
  )
}

export function FolderItem({ item, index, section, viewer }) {
  const { path, type } = item;

  return (
    <Link
      className="outline-none"
      href={'/' + path.replace('\\', '/')}>
      <div
        className={clsx(
          'py-0.5',
          'text-pen font-normal gap-4 flex',
          'transition-all duration-100 hover:translate-x-0.5 hover:text-marker3',
        )}>
        <div className="w-4 shrink-0 text-right">{index}.</div>
        <div className="flex-grow"><ItemTitle item={item} viewer={viewer} /></div>
        {type === 'folder' && (
          <div><CardStackIcon /></div>
        )}
      </div>
    </Link>
  );
}

/*
function BuySection() {
  return (
    <div className="border-t gap-0 flex flex-col pt-6 pl-8 gap-6">
      <div className="flex gap-8">
        <div className="">
          <div className="text-sm">Full Access</div>
          <div className="font-bold text-2xl">25.00$</div>
        </div>
        <Button className="outline-none">
          BUY NOW
        </Button>
      </div>

      <div className="text-sm">
        <div>
          <ClockIcon className="inline w-3 h-3 mr-1" /> Instant access after purchase
        </div>
        <div>
          <CheckCircledIcon className="inline w-3 h-3 mr-1" /> Lifetime access
        </div>
      </div>
    </div>
  )
}
*/