import { Link, Button } from 'react-aria-components';
import clsx from 'clsx';

import Stars from 'lib/components/Stars';
import BlogIndex from './BlogIndex';
import DocIndex from './DocIndex';
import BookIndex from './BookIndex';

import Logo from '../common/Logo';
import Menu from '../common/Menu';
import { useBookmark } from '../common/bookmarks';


export default function Workspace({ viewer }) {
  const
    { themeData } = viewer.workspace,
    Component = (
      themeData.getValue('coverPageLayout') === 'book-like' ? BookIndex :
        themeData.getValue('coverPageLayout') === 'doc-like' ? DocIndex :
          BlogIndex
    ),
    isHidden = viewer.state.get('type') !== 'folder';

  return (
    <div className={clsx('h-full overflow-hidden nb-viewer-theme', isHidden && 'hidden')}>
      <Stars />
      <Topbar viewer={viewer} />
      <Component viewer={viewer} />
    </div>
  );
}

function Topbar({ viewer }) {
  return (
    <div className="absolute px-6 py-4 flex top-0 left-0 w-full">
      <Link
        className="outline-none p-1 bg-paper"
        href="/">
        <span className="text-pen">
          <Logo viewer={viewer} className="md:w-7 md:h-7" />
        </span>
      </Link>
      <div className="grow"></div>
      <div className="flex gap-2 bg-paper">
        <BookmarkButton viewer={viewer} />
        <Menu viewer={viewer} />
      </div>
    </div>
  )
}


function BookmarkButton({ viewer }) {
  const { toggleBookmark, Icon } = useBookmark(viewer);
  return (
    <Button onPress={toggleBookmark} className="outline-none px-2">
      <Icon />
    </Button>
  )
}