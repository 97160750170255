import { Group, Label, LinkButton } from './common';
import { Button } from 'react-aria-components';

export default function Preferences({ controller }) {
  const domain = controller.state.get('domain');

  return domain && (
    <div>
{/*      <Group>
        <div className="max-w-[10rem] m-auto">
          <ImageInput controller={controller} />
        </div>
      </Group>*/}
      <Group>
        <Label>Title</Label> <br/>
        <LinkButton onPress={() => controller.popups.editTitlePopup({ autoFocusIndex: 0 })}>
          Change
        </LinkButton>

        {domain.get('title')}
      </Group>

      <Group>
        <Label>Author</Label> <br/>
        <LinkButton onPress={() => controller.popups.editTitlePopup({ autoFocusIndex: 1 })}>
          Change
        </LinkButton>

        {domain.get('author') || (<div className="italic">- -</div>)}
      </Group>

      <Group>
        <Label>Slug</Label> <br/>
        <LinkButton onPress={controller.popups.renameSlugPopup}>
          Change
        </LinkButton>

        <div className="font-mono">{domain.get('slug')}</div>
      </Group>

      <Group>
        <div className="text-right">
          <Button
            className="text-red-800 underline"
            onPress={() => controller.popups.deleteDomain()}>
            Delete this Domain
          </Button>
        </div>
      </Group>
    </div>
  )
}