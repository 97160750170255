export function SidebarIcon({ className }) {
  return (
    <svg
      width="15" height="15"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M 10 1 L 13.5 1 C 13.776 1 14 1.223 14 1.5 L 14 11.5 C 14 11.776 13.776 12 13.5 12 L 10 12 L 10 1 Z M 7 1 L 1.5 1 C 1.224 1 1 1.223 1 1.5 L 1 11.5 C 1 11.776 1.224 12 1.5 12 L 9 12 L 9 1 L 7 1 Z M 0 1.5 C 0 0.6709999999999996 0.6719999999999996 0 1.5 0 L 13.5 0 C 14.329 0 15 0.6709999999999996 15 1.5 L 15 11.5 C 15 12.328 14.329 13 13.5 13 L 1.5 13 C 0.6719999999999996 13 0 12.328 0 11.5 L 0 1.5 Z"
        fill="currentColor" 
        fillRule="evenodd"
        clipRule="evenodd"
        transform="matrix(1, 0, 0, 1, 0, -1.7763568394002505e-15)"/>
    </svg>
  )  
}

export function Disk2Icon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      className={className}>
      <path d="M2 12.0833V2.91667C2 2.41041 2.4104 2 2.91667 2H10.9703C11.2134 2 11.4466 2.09658 11.6185 2.26849L12.7315 3.38151C12.9034 3.55342 13 3.78658 13 4.02969V12.0833C13 12.5896 12.5896 13 12.0833 13H2.91667C2.41041 13 2 12.5896 2 12.0833Z" stroke="currentColor" strokeLinejoin="round"/>
      <path d="M5 13V9H10V11V13" stroke="currentColor" strokeLinejoin="round"/>
    </svg>
  )
}


export function Laptop2Icon({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M2 4.25C2 4.11193 2.11193 4 2.25 4H12.75C12.8881 4 13 4.11193 13 4.25V11.5H2V4.25ZM2.25 3C1.55964 3 1 3.55964 1 4.25V12H0V12.5C0 12.7761 0.223858 13 0.5 13H14.5C14.7761 13 15 12.7761 15 12.5V12H14V4.25C14 3.55964 13.4404 3 12.75 3H2.25Z" fill="currentColor"/>
      <path d="M6 6.5L4 8L6 9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9 6.5L11 8L9 9.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

/*
export function UploadIcon({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.5 2C3.22386 2 3 2.22386 3 2.5V12.5C3 12.7761 3.22386 13 3.5 13H11.5C11.7761 13 12 12.7761 12 12.5V6H8.5C8.22386 6 8 5.77614 8 5.5V2H3.5ZM9 2.70711L11.2929 5H9V2.70711ZM2 2.5C2 1.67157 2.67157 1 3.5 1H8.5C8.63261 1 8.75979 1.05268 8.85355 1.14645L12.8536 5.14645C12.9473 5.24021 13 5.36739 13 5.5V12.5C13 13.3284 12.3284 14 11.5 14H3.5C2.67157 14 2 13.3284 2 12.5V2.5Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.29289 7.5C7.4255 7.5 7.55268 7.55268 7.64645 7.64645L8.93934 8.93934C9.1346 9.1346 9.1346 9.45118 8.93934 9.64645C8.74408 9.84171 8.4275 9.84171 8.23223 9.64645L7.79289 9.20711V11C7.79289 11.2761 7.56904 11.5 7.29289 11.5C7.01675 11.5 6.79289 11.2761 6.79289 11V9.20711L6.35355 9.64645C6.15829 9.84171 5.84171 9.84171 5.64645 9.64645C5.45118 9.45118 5.45118 9.1346 5.64645 8.93934L6.93934 7.64645C7.03311 7.55268 7.16028 7.5 7.29289 7.5Z" fill="currentColor"/>
    </svg>
  )
}


export function Upload2Icon({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" className={className}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.54897 8.50004C7.69316 8.50179 7.82958 8.56571 7.92319 8.67539L9.6303 10.6754C9.80958 10.8854 9.78464 11.201 9.57461 11.3803C9.36457 11.5596 9.04897 11.5346 8.8697 11.3246L8.04289 10.3559V14C8.04289 14.2761 7.81904 14.5 7.54289 14.5C7.26675 14.5 7.04289 14.2761 7.04289 14V10.3068L6.1223 11.3338C5.93798 11.5394 5.62187 11.5566 5.41625 11.3723C5.21063 11.188 5.19337 10.8719 5.3777 10.6662L7.17059 8.66625C7.26685 8.55887 7.40477 8.49829 7.54897 8.50004Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 2.5C3 2.22386 3.22386 2 3.5 2H8V5.5C8 5.77614 8.22386 6 8.5 6H12V12.5C12 12.7761 11.7761 13 11.5 13H9.5C9.22386 13 9 13.2239 9 13.5V13.5C9 13.7761 9.22386 14 9.5 14H11.5C12.3284 14 13 13.3284 13 12.5V5.5C13 5.36739 12.9473 5.24021 12.8536 5.14645L8.85355 1.14645C8.75979 1.05268 8.63261 1 8.5 1H3.5C2.67157 1 2 1.67157 2 2.5V12.5C2 13.3284 2.67157 14 3.5 14H5.5C5.77614 14 6 13.7761 6 13.5V13.5C6 13.2239 5.77614 13 5.5 13H3.5C3.22386 13 3 12.7761 3 12.5V2.5ZM11.2929 5L9 2.70711V5H11.2929Z" fill="currentColor"/>
    </svg>
  )
}
*/