import { BookOpenIcon, LockClosedIcon, LockOpenIcon, CheckIcon } from '@heroicons/react/24/outline';
import { Buttons, Button } from 'lib/inputs/buttons';


export default function Viewers({ controller }) {
  const
    isRestricted = controller.state.getIn(['domain', 'isRestricted']),
    isSelling = controller.state.getIn(['domain', 'isSelling']);

  return (
    <div>
      <div className="text-lg font-medium mb-4 flex gap-4 items-center">
        <div>
          <BookOpenIcon className="size-5" />
        </div>
        <div>
          Who can read the published articles?
        </div>
      </div>
      {!isRestricted && (
        <>
          <div className="flex gap-2 items-center">
            <div><CheckIcon className="size-4" /></div>
            <div>Everyone</div>
          </div>
        </>
      )}

      {isRestricted && (
        <div className="flex flex-col gap-1 mb-2">
          <div className="flex gap-2">
            <div><LockClosedIcon className="size-3.5 inline" /></div>
            This articles is restricted from the public. <br />
            It's only viewable to:
          </div>
          <div className="pl-2">
            <div className="flex gap-2 items-center">
              <div><CheckIcon className="size-4" /></div>
              <div>Editors and Admins</div>
            </div>
            <div className="flex gap-2 items-center">
              <div><CheckIcon className="size-4" /></div>
              <div>Users on the readers list</div>
            </div>
            <div className="flex gap-2 items-center">
              <div><CheckIcon className="size-4" /></div>
              <div>Paid users</div>
            </div>            
          </div>
        </div>
      )}

      <div className="text-right">
        {isRestricted && (
          <Button
            className="flex items-center gap-1 ml-auto outline-none"
            onPress={() => controller.popups.setIsRestricted(false)}>
            <LockOpenIcon className="size-3.5 inline" />&nbsp; Make it Public
          </Button>
        ) }

        {!isRestricted && (
          <Button
            className="flex items-center gap-1 ml-auto outline-none"
            onPress={() => controller.popups.setIsRestricted(true)}>
            <LockClosedIcon className="size-3.5 inline" />&nbsp; Restrict Access
          </Button>
        ) }
      </div>
    </div>
  )
}