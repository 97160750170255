import { ListBox, ListBoxItem } from 'react-aria-components';
import { Buttons, Button } from 'lib/inputs/buttons';
import { ArrowDownIcon, UploadIcon } from '@radix-ui/react-icons';
import Loader from 'lib/staticPages/Loader';
import clsx from 'clsx';
import isAccepted from './isAccepted';


function FileIndex({ popup }) {
  const
    { accept } = popup.args,
    selectedId = popup.state.get('selectedId'),
    uploads = popup.articleController.state.get('uploads');

  const disabledIds = !accept ? [] : (
    uploads
      .filter(item => !isAccepted(
        item.get('filename'),
        item.get('contentType'),
        accept
      ))
      .map(item => item.get('id'))
  );

  return (
    <div className="flex flex-col w-full h-full bg-wax border-r border-rim p-2">
      <div className="text-sm uppercase p-2 font-medium text-pencil2">
        Uploaded Files
      </div>

      <div className="border border-rim bg-paper flex-grow overflow-auto">
        {(!uploads || (popup.state.get('oldUploads') === uploads)) ?
          (<div className="text-center"><Loader /></div>) :
          uploads.size === 0 ?
            (<div className="p-2 px-4 text-pencil3 text-sm">Empty</div>) :
            (<Entries
                items={uploads}
                selectedId={selectedId}
                disabledIds={disabledIds}
                setSelectedId={(selectedId) => { popup.setState({ selectedId }); }} />)}
      </div>

      <Buttons className="text-center">
        { (selectedId && popup.args.onSelect && uploads.find(u => u.get('id') === selectedId)) ? (
          <Buttons className="text-center">
            <Button onPress={() => popup.selectCurrentUploadFile()}>
              <ArrowDownIcon className="inline mr-2" />
              Use This File
            </Button>
          </Buttons>
        ) : (
          <Button
            onPress={() => {
              popup.articleController.popups.uploadFilePopup({
                accept: popup.args.accept,
                onDone: filename => {
                  if (filename)
                    popup.setState({ oldUploads: uploads });
                } })
            }}>
            <UploadIcon className="inline" /> Upload
          </Button>
        ) }
      </Buttons>
    </div>
  )
}

function Entries({ items, selectedId, setSelectedId, disabledIds }) {
  return (
    <ListBox
      items={items}
      aria-label="uploads"
      selectionMode="single"
      selectedKeys={[selectedId]}
      disabledKeys={disabledIds}
      onSelectionChange={s => setSelectedId(Array.from(s)[0])}
      className="flex-grow p-1 py-2 h-full overflow-auto">
      { (item) => (
        <ListBoxItem
          id={item.get('id')}
          key={item.get('id')}
          className={({ isSelected, isDisabled }) => clsx(
            'font-mono outline-none text-sm px-2 py-1 rounded-sm mb-1 single-line-ellipsis',
            item.get('isDeleted') && 'opacity-50',
            isSelected && 'bg-blue-600 text-white',
            isDisabled ? 'opacity-50 cursor-default' : 'cursor-pointer'
            )}>
          { item.get('filename') }
        </ListBoxItem>
      ) }
    </ListBox>
  );
}

export default FileIndex;