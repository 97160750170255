import BaseController from 'lib/BaseController';
import { Set } from 'immutable';


class Selection extends BaseController {
  constructor(article) {
    super();
    this.article = article;
    this.state = this.state.merge({
      isPageFocused: false,
      activeElementId: null,
      selectedElements: new Set([]),
    });
  }

  get layout() { return this.article.layout; }
  get activeElementId () { return this.state.get('activeElementId'); }
  get selectedElements () { return this.state.get('selectedElements'); }
  get selectedElement() {
    if (this.selectedElements.size === 0)
      return null;
    const id = this.selectedElements.first();
    if (id === this.article.titleElement.id)
      return this.article.titleElement;
    return this.article.elements[id] || null;
  }

  // ** Page Functions
  setFocusPageFn = fn => this._focusPage = fn; // imperative
  setBlurPageFn = fn => this._blurPage = fn; // imperative
  focusPage = () => { if (this._focusPage) this._focusPage(); }
  blurPage = () => { if (this._blurPage) this._blurPage(); }
  selectNone() { this.focusPage(); this.blurPage(); }
  toggleSelect() {
    if (this.state.get('isPageFocused')) { this.blurPage(); }
    else { this.focusPage(); }
  }

  handlePageFocus = () => this.setState({ isPageFocused: true, activeElementId: null });
  handlePageBlur = () => this.setState({ isPageFocused: false });
  get isPageFocused() { return this.state.get('isPageFocused'); }

  // Active Id
  setActiveElementId = (id) => { this.setState({ activeElementId: id, selectedElements: new Set(id ? [id] : []) }); }
  setSelectedElementId = (id) => this.setState({ selectedElements: new Set([id]) });
  setSelectedElements = (s) => this.setState({ selectedElements: s });

  activateCurrentElement = () => {
    if (this.selectedElement)
      this.selectedElement.focus();
  }

  runElement = () => {
    const e = this.selectedElement;
    if (e && e.controller && e.controller.run)
      e.controller.run();
  }
  
  moveSelectedElement = (d) => {
    const { selectedElement } = this;
    if (selectedElement) {
      const index = this.layout.getElementIndex(selectedElement.id);
      this.layout.moveElementIndex(index, index + d);
    }
  }
  
  // Selection
  moveSelection = (d) => {
    const { selectedElement } = this;
    if (!selectedElement)
      return;
    const
      index = this.layout.getElementIndex(selectedElement.id),
      newIndex = Math.max(Math.min(index + d, this.layout.order.size - 1), -1),
      id = newIndex === -1 ? this.article.titleElement.id : this.layout.getElementIdAt(newIndex);
    this.setSelectedElementId(id);
    this.scrollIntoSelectedElement();
  }

  deleteSelectedElementPopup = () => {
    var { selectedElement } = this;
    if (selectedElement) {
      this.article.popups.deleteElementPopup(selectedElement.id);
    }
  }

  scrollIntoSelectedElement = () => {
    if (this.selectedElement)
      this.selectedElement.scrollIntoView();
  }
  
  selectAll = () => {
    this.setState({ selectedElements: new Set(this.layout.order) });
  }
}

export default Selection