import { useControllerState } from 'lib/BaseController';
import { UpdateIcon, ReloadIcon } from '@radix-ui/react-icons';
import { Button } from 'react-aria-components';


export function BottomTargetBar({ controller }) {
  const gsv = controller.getSettingsValue;

  return (gsv('showTarget') && !gsv('floatTarget')) && (
    <div className="flex">
      <div className="flex-grow"></div>

      <div className="relative flex items-center gap-4 px-2 py-1">
        <TargetButton controller={controller} />
        <RefreshButton controller={controller} />
      </div>
    </div>
  )
}


export function TargetButton({ controller }) {
  const
    { getSettingsValue, isReadOnly } = controller,
    target = getSettingsValue('target'),
    renderOption = getSettingsValue('renderOption'),
    classToRender = getSettingsValue('classToRender');

  return (
    <Button
      isDisabled={isReadOnly}
      className="font-mono text-sm"
      onPress={() => controller.editTarget()}>
      { target }
      { renderOption === 'export' && classToRender && (
        <span>&nbsp;{classToRender}</span>
      ) }
    </Button>
  );
}


export function RefreshButton({ controller }) {
  const
    state = useControllerState(controller.webFrame),
    build = state.get('build');

  if (controller.state.get('loading'))
    return (<UpdateIcon className="inline-block animate-spin opacity-60" />);

  return build && (
    <Button
      className="py-1 outline-none"
      onPress={() => {
        controller.webFrame.setState({ showConsole: false });
        controller.run();
        controller.activate();
      }}>
      <ReloadIcon />
    </Button>
  );
}