import { useEffect } from 'react';
import { useControllerState } from 'lib/BaseController';
import { useMatch } from 'react-router-dom';
import Gen from './Gen';
import Home from './Home';


export default function Page({ path, app }) {
  const
    { genai } = app,
    state = useControllerState(genai)

  const
    match = useMatch({ path: '/genai/*' }),
    genId = match.params['*'],
    versionId = state.get('versionId');

  useEffect(() => {
    genai.loadPage({ genId, versionId });
  }, [genId, versionId, genai]);

  useEffect(() => genai.destroy, [genai])

  const C = genId ? Gen : Home;
  return (<C app={app} />);
}