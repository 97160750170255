import { memo } from 'react';
import { useControllerState } from 'lib/BaseController';


export default function RightPanel({ viewer }) {
  const
    panelRight = viewer.state.get('panelRight'),
    Panel = (
      panelRight === 'detail' ? DetailPanel :
        null
    );

  return Panel && (<Panel viewer={viewer} />)
}

function DetailPanel({ viewer }) {
  const
    detailId = viewer.state.get('detailId'),
    a = viewer.currentArticle,
    element = a && a.elementControllers[detailId];

  return element && (
    <div className="fixed md:top-4 md:right-6 md:bottom-6 w-[39rem] max-w-[calc(100%-2rem)] top-2 right-4 bottom-2">
      <MemoizedElement viewer={viewer} element={element} />
    </div>
  )
}

export function Element({ editor, element }) {
  useControllerState(element);
  return (
    <element.lib.Editor
      controller={element.controller}
      isActive={false}
      isGrid={false}
      isDetail={true} />)
}
const MemoizedElement = memo(Element);